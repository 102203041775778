import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  useMediaQuery,
  Stack,
  TextField,
  Typography,
  Divider,
  Box,
} from "@mui/material";
import { theme } from "theme";
import { queryClient, useAuthMutation } from "providers/auth";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { MobileTimePicker } from "../../../components/date-picker.component";
import { DateTime } from "luxon";
import { createEvent, EventsInput } from "store/events/events.query";
import { toast } from "react-toastify";
import { NavigationContext } from "providers/navigation.provider";
import { TeamMemberRole } from "store/team-members/team-members.model";
import CloseIcon from "@mui/icons-material/Close";
import { Trans } from "@lingui/macro";
import ToggleButtonGroupCustom from "components/toggleButton/toggle-button-group.component";
import AddIcon from "@mui/icons-material/Add";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {
  createIcsReoccuringEvent,
  getCombinedDateTime,
} from "components/utils/utils";
import {
  ReoccuranceProps,
  EventRepeating,
  RepeatFrequency,
} from "../components/event-repeating";
import "./event-timeline.css";
import { EventType } from "@gamer/common/lib/models/events";

interface TeamModalProps {
  teamId: string;
  open: boolean;
  onClose: (eventCreated: boolean) => void;
}

export const CreateTimelineEventModal = (props: TeamModalProps) => {
  const initialReoccurance: ReoccuranceProps = {
    isRepeating: false,
    repeatFreq: RepeatFrequency.EVERY_WEEK,
    repeatDays: [],
    dtStart: DateTime.now(),
    dtEnd: DateTime.now().plus({ year: 1 }),
  };

  const { currentTeam } = React.useContext(NavigationContext);
  const mutation = useAuthMutation("createEvent", createEvent, { retry: 0 });
  const { open, onClose: handleClose } = props;
  const [eventName, setEventName] = useState("");
  const [type, setType] = useState(EventType.TRAINING);
  const [selectedDate, setSelectedDate] = useState(DateTime.now());
  const [startTime, setStartTime] = useState<DateTime>();
  const [endTime, setEndTime] = useState<DateTime>();
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [opponentTeamName, setOpponentTeamName] = useState("");
  const [opponentTeamScore, setOpponentTeamScore] = useState(0);
  const [myTeamScore, setMyTeamScore] = useState(0);
  const isSmallScreen = useMediaQuery("(max-width: 480px)");
  const [eventNameError, setEventNameError] = useState("");
  const [opponentTeamNameError, setOpponentTeamNameError] = useState("");
  const [locationError, setlocationError] = useState("");
  const [startTimeError, setstartTimeError] = useState(false);
  const [endTimeError, setendTimeError] = useState(false);
  const [addScoreClicked, setAddScoreClicked] = useState(false);

  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [reoccurance, setReoccurance] =
    useState<ReoccuranceProps>(initialReoccurance);

  const eventTypes = Object.values(EventType);

  const validateInput = (input: string) => {
    const MAX_NAME_LENGTH = 64;
    const MIN_NAME_LENGTH = 2;
    const reg = /^([a-zA-Z0-9 _.-]+)$/;
    if (input.length < MIN_NAME_LENGTH) {
      return `Should be at least length of ${MIN_NAME_LENGTH}`;
    }

    if (input.length > MAX_NAME_LENGTH) {
      return `Should not be more then ${MAX_NAME_LENGTH}`;
    }

    if (!input.match(reg)) {
      return "Should include only letters of alphabet";
    }

    return "";
  };

  useEffect(() => {
    eventName && setEventNameError(validateInput(eventName));
    opponentTeamName &&
      setOpponentTeamNameError(validateInput(opponentTeamName));
    location && setlocationError(validateInput(location));

    setSubmitEnabled(
      Boolean(
        (type !== EventType.MATCH && eventName && location) ||
          (type === EventType.MATCH && opponentTeamName && location),
      ),
    );
  }, [eventName, location, opponentTeamName, type]);

  useEffect(() => {
    if (mutation.isSuccess) {
      queryClient.invalidateQueries(["events", props.teamId]);
      handleClose(true);
    }

    if (mutation.isError) {
      toast.error(mutation.error.data.detail);
      handleClose(false);
    }
  }, [mutation.isSuccess, mutation.isError, props.teamId]);

  const checkReoccuranceDaysFilled = (): boolean => {
    if (!reoccurance.repeatDays.length) {
      return false;
    }
    return reoccurance.repeatDays.every((day) => {
      return Boolean(
        day.startTime &&
          day.endTime &&
          day.endTime >= day.startTime.plus({ minutes: 15 }),
      );
    });
  };

  const isInputTimeCorrect: boolean =
    !startTimeError && !endTimeError && Boolean(startTime && endTime);

  const isReoccuranceSelected: boolean =
    type === EventType.TRAINING
      ? (reoccurance.isRepeating && checkReoccuranceDaysFilled()) ||
        (!reoccurance.isRepeating && isInputTimeCorrect)
      : isInputTimeCorrect;

  const isSubmitButtonDisabled: boolean = Boolean(
    !submitEnabled ||
      locationError ||
      mutation.isLoading ||
      !isReoccuranceSelected,
  );

  const isSubmitButtonDisabledForEvent: boolean =
    type === EventType.MATCH
      ? Boolean(isSubmitButtonDisabled || opponentTeamNameError)
      : Boolean(isSubmitButtonDisabled || eventNameError);

  const onSubmit = () => {
    const startTimestamp: DateTime = getCombinedDateTime(
      selectedDate,
      startTime || DateTime.now(),
    );
    const endTimestamp: DateTime = getCombinedDateTime(
      selectedDate,
      endTime || DateTime.now(),
    );
    let eventTitle: string = eventName;

    if (type === EventType.MATCH) {
      eventTitle = `MATCH VS. ${opponentTeamName}`;
    }

    const autoEventId =
      currentTeam.role === TeamMemberRole.PLAYER &&
      startTimestamp.toMillis() < DateTime.now().toMillis()
        ? props.teamId
        : undefined;
    const data: EventsInput = {
      teamId: props.teamId,
      title: eventTitle,
      type,
      startTime: startTimestamp.toMillis(),
      endTime: endTimestamp.toMillis(),
      locationName: location,
      description,
      autoEventId,
    };

    if (data.type === EventType.MATCH) {
      data.matchDetails = {
        teamScore: myTeamScore,
        opponentScore: opponentTeamScore,
        opponentTeamName,
        startTime: startTimestamp.toMillis(),
      };
    }

    if (reoccurance.isRepeating) {
      data.reoccurrence = createIcsReoccuringEvent(reoccurance, eventTitle);
    }

    mutation.mutate(data);
  };

  return (
    <Dialog
      fullWidth
      maxWidth={"sm"}
      open={open}
      onClose={handleClose}
      scroll="body"
      keepMounted={open}
    >
      <Paper style={{ background: theme.background.actions }}>
        <DialogTitle id="form-dialog-title">
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid item>
                <Typography variant="h5">
                  <Trans id="create_event_label">CREATE EVENT</Trans>
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <IconButton onClick={() => handleClose(false)}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ height: "fit-content" }}>
          <DialogContentText>
            <Stack direction="column" spacing={2}>
              <Grid item>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <Typography variant="body2">
                      <Trans id="event_type_label">Event type</Trans>:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <ToggleButtonGroupCustom
                      buttons={eventTypes}
                      value={type}
                      buttonAction={(name) => setType(name as EventType)}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                {type === EventType.MATCH ? (
                  <TextField
                    variant="filled"
                    label={
                      <Trans id="opponent_name_label">Opponent Name</Trans>
                    }
                    sx={{ width: "100%" }}
                    onChange={(e) => setOpponentTeamName(e.target.value)}
                    value={opponentTeamName}
                    helperText={opponentTeamNameError}
                    error={Boolean(opponentTeamNameError)}
                  />
                ) : (
                  <TextField
                    variant="filled"
                    label={<Trans id="event_title_label">Event Title</Trans>}
                    sx={{ width: "100%" }}
                    onChange={(e) => setEventName(e.target.value)}
                    value={eventName}
                    helperText={eventNameError}
                    error={Boolean(eventNameError)}
                  />
                )}
              </Grid>

              <TextField
                variant="filled"
                label={<Trans id="event_location_label">Location</Trans>}
                sx={{ width: "100%" }}
                minRows={5}
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                helperText={locationError}
                error={Boolean(locationError)}
              />

              {type === EventType.TRAINING && (
                <Grid item>
                  <EventRepeating
                    isUpdatingEvent={false}
                    props={reoccurance}
                    selectedRepeatingEvent={false}
                    handleChange={(recurr) =>
                      setReoccurance((prev) => ({ ...prev, ...recurr }))
                    }
                  />
                </Grid>
              )}

              {!(reoccurance.isRepeating && type === EventType.TRAINING) && (
                <Stack direction="column" spacing={2}>
                  <Grid item>
                    <MobileDatePicker
                      value={selectedDate}
                      onChange={(v) => setSelectedDate(v || DateTime.now())}
                      minDate={DateTime.now().minus({ days: 30 })}
                      format={"dd MMMM yyyy"}
                      slots={{
                        textField: (params) => (
                          <TextField variant="filled" {...params} />
                        ),
                      }}
                      slotProps={{
                        toolbar: {
                          className: "leaderBoard-datepicker",
                        },
                        layout: {
                          className: "leaderBoard-datepicker__layout",
                        },
                        textField: {
                          style: {
                            width: "100%",
                          },
                        },
                      }}
                      label={<Trans id="event_time_date_label">Date</Trans>}
                    />
                  </Grid>

                  <Grid item>
                    <Stack
                      direction={isSmallScreen ? "column" : "row"}
                      spacing={2}
                      justifyContent="space-around"
                      alignItems="center"
                    >
                      <MobileTimePicker
                        value={startTime}
                        onChange={(v) => {
                          setStartTime(v || DateTime.now());
                          setEndTime(
                            (v || DateTime.now()).plus({ minutes: 15 }),
                          );
                        }}
                        maxTime={selectedDate
                          .endOf("day")
                          .minus({ minutes: 15 })}
                        ampm={false}
                        format={"HH:mm"}
                        slots={{
                          textField: (params) => (
                            <TextField variant="filled" {...params} />
                          ),
                        }}
                        slotProps={{
                          toolbar: {
                            className: "leaderBoard-datepicker",
                          },
                          layout: {
                            className: "leaderBoard-datepicker__layout",
                          },
                          textField: {
                            style: {
                              width: "100%",
                            },
                          },
                        }}
                        label={
                          <Trans id="event_time_begins_at_label">
                            Begins At
                          </Trans>
                        }
                        onError={(e) => setstartTimeError(Boolean(e))}
                      />
                      <MobileTimePicker
                        value={endTime}
                        onChange={(v) => setEndTime(v || DateTime.now())}
                        minTime={(
                          startTime || DateTime.now().startOf("day")
                        )?.plus({ minutes: 15 })}
                        maxTime={selectedDate.endOf("day")}
                        ampm={false}
                        format={"HH:mm"}
                        slots={{
                          textField: (params) => (
                            <TextField variant="filled" {...params} />
                          ),
                        }}
                        slotProps={{
                          toolbar: {
                            className: "leaderBoard-datepicker",
                          },
                          layout: {
                            className: "leaderBoard-datepicker__layout",
                          },
                          textField: {
                            style: {
                              width: "100%",
                            },
                          },
                        }}
                        label={
                          <Trans id="event_time_ends_at_label">Ends At</Trans>
                        }
                        onError={(e) => setendTimeError(Boolean(e))}
                      />
                    </Stack>
                  </Grid>
                </Stack>
              )}

              <TextField
                id="filled-multiline-flexible"
                label={
                  <Trans id="event_description_label">Event description</Trans>
                }
                multiline
                sx={{ width: "100%" }}
                minRows={5}
                variant="filled"
                value={description}
                inputProps={{ maxLength: 750 }}
                onChange={(e) => setDescription(e.target.value)}
              />
              <Grid key="opponent" hidden={type !== EventType.MATCH}>
                <Button
                  className="button-action__common"
                  sx={{
                    letterSpacing: "2px",
                  }}
                  variant="text"
                  endIcon={<AddIcon />}
                  hidden={addScoreClicked}
                  onClick={() => setAddScoreClicked(!addScoreClicked)}
                >
                  <Trans id="event_match_add_score_text">ADD SCORE</Trans>
                </Button>
                <Stack
                  direction="column"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={1}
                  hidden={!addScoreClicked}
                >
                  <Box width={"100%"} id="myScore">
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="body1" color="white">
                        {currentTeam.teamName}
                      </Typography>
                      <Stack direction="row">
                        <IconButton
                          sx={{ color: "gray" }}
                          disabled={myTeamScore <= 0}
                          onClick={() =>
                            myTeamScore > 0 && setMyTeamScore(myTeamScore - 1)
                          }
                        >
                          <RemoveCircleIcon style={{ fontSize: "24px" }} />
                        </IconButton>

                        <Typography variant="body1" className="score-label">
                          {myTeamScore}
                        </Typography>

                        <IconButton
                          onClick={() => setMyTeamScore(myTeamScore + 1)}
                        >
                          <AddCircleIcon style={{ fontSize: "24px" }} />
                        </IconButton>
                      </Stack>
                    </Stack>
                  </Box>

                  <Divider flexItem />

                  <Box width={"100%"} id="opponentScore">
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="body1" color="white">
                        {opponentTeamName || (
                          <Trans id="event_match_opponent_placeholder">
                            Opponent
                          </Trans>
                        )}
                      </Typography>
                      <Stack direction="row">
                        <IconButton
                          sx={{ color: "gray" }}
                          disabled={opponentTeamScore <= 0}
                          onClick={() =>
                            opponentTeamScore > 0 &&
                            setOpponentTeamScore(opponentTeamScore - 1)
                          }
                        >
                          <RemoveCircleIcon style={{ fontSize: "24px" }} />
                        </IconButton>

                        <Typography variant="body1" className="score-label">
                          {opponentTeamScore}
                        </Typography>

                        <IconButton
                          onClick={() =>
                            setOpponentTeamScore(opponentTeamScore + 1)
                          }
                        >
                          <AddCircleIcon style={{ fontSize: "24px" }} />
                        </IconButton>
                      </Stack>
                    </Stack>
                  </Box>
                </Stack>
              </Grid>
            </Stack>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="flex-end" spacing={1} margin={1}>
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => handleClose(false)}
              >
                <Trans id="cancel_cta">CANCEL</Trans>
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                disabled={isSubmitButtonDisabledForEvent}
                onClick={onSubmit}
              >
                {mutation.isLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  <Trans id="create_cta">Create</Trans>
                )}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Paper>
    </Dialog>
  );
};
