import { Button, Grid, IconButton, Paper, Typography } from "@mui/material";
import * as React from "react";
import {
  TeamEvent,
  TeamEventUserSpecific,
} from "store/events/events.model";
import theme from "../../theme";
import { DateTime } from "luxon";
import { TeamMemberRole } from "store/team-members/team-members.model";
import { TeamAvatar } from "components/avatar.component";
import { NavigationContext } from "providers/navigation.provider";
import { EventCoachReviewModal } from "./modals/event-coach-review.modal";
import EditIcon from "@mui/icons-material/Edit";
import { Trans } from "@lingui/macro";
import { IUserTeamInfo } from "store/user-teams/user-teams.model";
import { EventType } from "@gamer/common/lib/models/events";

export const CoachReviewToCallForAction = {
  [EventType.MATCH]: (
    <Trans id="match_coach_review_detail">
      What was a great game! We'd love to hear your breakdown of it in a match
      review. Your analysis gives your team that winning edge.
    </Trans>
  ),
  [EventType.TRAINING]: (
    <Trans id="training_coach_review_detail">
      How was a training? Mind sharing a review to help us amp up our
      performance even more.
    </Trans>
  ),
  [EventType.OTHER]: (
    <Trans id="other_coach_review_detail">
      Your insights would be incredibly valuable as we prepare for the next
      event. Go ahead!
    </Trans>
  ),
};

export const MatchScore = ({
  event,
  currentTeam,
}: {
  event: TeamEvent;
  currentTeam: IUserTeamInfo;
}) => (
  <Paper>
    <Grid
      container
      direction="row"
      style={{ width: 370, height: 240 }}
      justifyContent="space-around"
      alignItems="center"
    >
      <Grid item id="team">
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <TeamAvatar
              teamInfo={currentTeam}
              style={{ width: 72, height: 72 }}
            />
          </Grid>
          <Grid item width={100}>
            <Typography variant="subtitle2">{currentTeam.teamName}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item id="score">
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <Typography variant="h3">
                  {event.matchDetails?.teamScore}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h3">-</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h3">
                  {event.matchDetails?.opponentScore}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2">90’</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item id="opponent">
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <TeamAvatar default style={{ width: 72, height: 72 }} />
          </Grid>
          <Grid item width={100}>
            <Typography variant="subtitle2">
              {event.matchDetails?.opponentTeamName}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Paper>
);

export const EventDescriptionTab = ({
  setUpdateConfirmModalOpen,
  setUpdateModalOpen,
  role,
  ...event
}: TeamEventUserSpecific & {
  role?: TeamMemberRole;
  setUpdateModalOpen: (v: boolean) => void;
  setUpdateConfirmModalOpen: (v: boolean) => void;
}) => {
  const { currentTeam } = React.useContext(NavigationContext);
  const [reviewModal, setReviewModal] = React.useState(false);
  const NeedUpdate = () => (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={1}
      style={{ padding: "15%" }}
    >
      <Grid item>
        <Typography variant="subtitle2">
          <Trans id="timeline_edit_details_title">Be more specific.</Trans>
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          variant="body2"
          style={{
            fontSize: 13,
            lineHeight: "15px",
            color: theme.text.secondary,
            textAlign: "center",
          }}
        >
          Event is planed for {DateTime.fromMillis(event.startTime).monthLong}{" "}
          {DateTime.fromMillis(event.startTime).day}. <br /> Add more
          information about this session for your players.
        </Typography>
      </Grid>
      <Grid item>
        <Button
          variant="outlined"
          onClick={() =>
            event.seriesId
              ? setUpdateConfirmModalOpen(true)
              : setUpdateModalOpen(true)
          }
          style={{
            height: "fit-content",
            width: "fit-content",
            border: `1px solid ${theme.actions.primary}`,
            color: theme.actions.primary,
          }}
        >
          <Typography
            variant="h6"
            style={{
              fontSize: 18,
              lineHeight: "24px",
            }}
          >
            <Trans id="timeline_edit_event_cta">Edit Session details</Trans>
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
  if (
    event.description ||
    event.coachReview ||
    event.trainingDetails ||
    (!event.coachReview && TeamMemberRole.PLAYER !== role) ||
    event.matchDetails
  ) {
    return (
      <Grid container spacing={5} direction="column">
        {event && (
          <EventCoachReviewModal
            event={event}
            open={reviewModal}
            onClose={() => setReviewModal(false)}
          />
        )}
        <Grid
          item
          hidden={
            event.endTime > Date.now() ||
            Boolean(event.coachReview) ||
            TeamMemberRole.PLAYER === role
          }
        >
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
          >
            <Grid item>
              <Typography variant="body2">
                {CoachReviewToCallForAction[event.type]}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setReviewModal(true)}
              >
                <Trans id="timeline_provide_review_cta" context="for the match">
                  Provide review
                </Trans>
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item hidden={!event.coachReview}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Grid container>
                <Grid item>
                  <Typography variant="h5">
                    <Trans id="coach_review_title">COACH REVIEW</Trans>
                  </Typography>
                </Grid>
                <Grid item hidden={role === TeamMemberRole.PLAYER}>
                  <IconButton onClick={() => setReviewModal(true)}>
                    <EditIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="body2">{event.coachReview}</Typography>
            </Grid>
          </Grid>
        </Grid>
        {!event.description &&
          !event.trainingDetails &&
          !event.matchDetails && <NeedUpdate />}
        <Grid item>
          <Grid container justifyContent="space-between">
            <Grid item hidden={!event.description}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Typography variant="h5">
                    <Trans id="trainging_description_title">
                      TRAINING DESCRIPTION
                    </Trans>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">{event.description}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item hidden={!event.matchDetails}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Typography variant="h5">
                    <Trans
                      id="timeline_score_title"
                      context="match final score"
                    >
                      SCORE
                    </Trans>
                  </Typography>
                </Grid>
                <Grid item>
                  <MatchScore event={event} currentTeam={currentTeam} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  if (role === TeamMemberRole.PLAYER) {
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        style={{ padding: "15%" }}
      >
        <Grid item>
          <Typography variant="subtitle2">
            <Trans id="timeline_no_details_title">Not much details here.</Trans>
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="body2"
            style={{
              fontSize: 13,
              lineHeight: "15px",
              color: theme.text.secondary,
              textAlign: "center",
            }}
          >
            <Trans id="timeline_no_details_description">
              Your coach did not supply details about this event. <br /> Please,
              reach you senior for more information.
            </Trans>
          </Typography>
        </Grid>
      </Grid>
    );
  }

  if (event.autoEventId) {
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        style={{ padding: "15%" }}
      >
        <Grid item>
          <Typography variant="subtitle2">
            <Trans
              id="timeline_auto_session_detected_title"
              context="session automatically created"
            >
              Auto session detected.
            </Trans>
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="body2"
            style={{
              fontSize: 13,
              lineHeight: "15px",
              color: theme.text.secondary,
              textAlign: "center",
            }}
          >
            <Trans id="timeline_auto_session_detected_description">
              New training session added on{" "}
              {DateTime.fromMillis(event.startTime).monthLong}{" "}
              {DateTime.fromMillis(event.startTime).day}. <br /> Add general
              information about this session.
            </Trans>
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            onClick={() =>
              event.seriesId
                ? setUpdateConfirmModalOpen(true)
                : setUpdateModalOpen(true)
            }
            style={{
              height: "fit-content",
              width: "fit-content",
              border: `1px solid ${theme.actions.secondary}`,
              color: theme.actions.secondary,
            }}
          >
            <Typography
              variant="h6"
              style={{
                fontSize: 18,
                lineHeight: "24px",
              }}
            >
              <Trans id="timeline_edit_event_cta">Edit Session details</Trans>
            </Typography>
          </Button>
        </Grid>
      </Grid>
    );
  }

  return <NeedUpdate />;
};
