import { Box, Container, Grid, ThemeProvider } from "@mui/material";
import { landingTheme } from "landing-theme";
import { useState } from "react";
import PageHeader from "./components/PageHeader";
import { LandingTabs, useStyles } from "./constants";
import TopSection from "./components/TopSection";
import BottomSection from "./components/BottomSection";
import DownloadSection from "./components/DownloadSection";
import { useHistory } from "react-router-dom";

const LandingPage = () => {
  const [tab, setTab] = useState(LandingTabs.COACH);
  const classes = useStyles(landingTheme);
  const query = new URLSearchParams(window.location.search);
  const code = query.get("code");
  const history = useHistory();

  if (code) {
    history.push(`/login?${query.toString()}`);
  }

  return (
    <ThemeProvider theme={landingTheme}>
      <Box style={{ background: "#000000" }}>
        <PageHeader setTab={setTab} />
        <Container className={classes.mainContainer}>
          <Grid
            container
            id="mainGrid"
            direction="column"
            className={classes.mainGrid}
          >
            <TopSection />
            <BottomSection tab={tab} setTab={setTab} />
            <DownloadSection />
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default LandingPage;
