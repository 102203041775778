import React from "react";
import AdidasLogoIcon from "components/icons/AdidasLogoIcon";
import { Typography, Grid } from "@mui/material";
import { Trans } from "@lingui/macro";
import LinearProgress from "@mui/material/LinearProgress";
import { OnboardingLeftContent } from "./components/OnboardingLeftContent";

export const OnboardingLoading = ({
  message = "Loading...",
}: {
  message: string;
}) => {
  return (
    <div className={"login"}>
      <div className="login-leftSide login-part">
        <OnboardingLeftContent />
      </div>
      <div className="login-rightSide login-part">
        <div className={"login-content"}>
          <div className="topHeader">
            <div className={"login-logo__container"}>
              <AdidasLogoIcon
                onClick={() =>
                  (window.location.href = "https://www.adidas.com/us")
                }
                height={60}
              />
            </div>
            <Typography variant="h1">TEAM FX</Typography>
          </div>
          <Grid container direction="column" spacing={4}>
            <Grid item>
              <LinearProgress
                color="primary"
                sx={{
                  backgroundColor: "#25272a",
                }}
              />
            </Grid>
            <Grid item width='fit-content'>
              <Typography variant="h3">
                <Trans>Loading</Trans>
              </Typography>
            </Grid>
            <Grid item>
              <Typography>{message}</Typography>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
