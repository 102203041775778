import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Typography,
} from "@mui/material";
import { font } from "theme";
import { useAuthQuery } from "providers/auth";
import { DateTime } from "luxon";
import { NavigationContext } from "providers/navigation.provider";
import { getTeamEvents } from "store/events/events.query";
import { TeamEvent } from "store/events/events.model";
import { IconByEventType } from "containers/timeline/components/event-type.icon";
import { Trans } from "@lingui/macro";

export type EventSelectorProps = {
  to: number;
  selectedEvent?: string;
  onEventSelect?: (event: string) => void;
  onRangeSelect?: (from: number, to: number) => void;
  setPopupOpened?: (v: boolean) => void;
};

export const EventSelectorItem = ({ event }: { event: TeamEvent }) => (
  <Grid container direction="row" alignItems="center" spacing={1}>
    <Grid
      item
      id="icon"
      style={{ width: "fit-content", height: "fit-content" }}
    >
      <IconByEventType type={event.type} size={20} />
    </Grid>
    <Grid item id="text" maxWidth={100}>
      <Grid container direction="column">
        <Grid item>
          <Typography
            style={{
              fontFamily: font.adihaus.regular,
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "24px",
              letterSpacing: "0.5px",
              textAlign: "left",
            }}
          >
            {event.title}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            style={{
              fontFamily: font.adihaus.regular,
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "14px",
              textAlign: "left",
              color: "#999999",
            }}
          >
            {DateTime.fromMillis(event.startTime).toFormat("dd.MM.yyyy")}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export const EventSelector = (props: EventSelectorProps) => {
  const {
    currentTeam: { teamId },
  } = React.useContext(NavigationContext);
  const { to } = props;
  const [from, setFrom] = useState(
    DateTime.fromMillis(to).minus({ weeks: 2 }).toMillis(),
  );
  const eventsQuery = useAuthQuery(
    ["events", from, to],
    getTeamEvents({ teamId, from, to }),
  );
  const [eventId, setEventId] = React.useState<string>(
    props.selectedEvent || "",
  );
  const [events, setEvents] = useState<TeamEvent[]>([]);

  useEffect(() => {
    if (eventsQuery.data) {
      setEvents(
        eventsQuery.data.data.sort((a, b) => b.startTime - a.startTime),
      );
    }
  }, [eventsQuery.data]);

  useEffect(() => {
    setEventId(props.selectedEvent || "");
  }, [props.selectedEvent]);

  useEffect(() => {
    if (eventId) {
      const event = eventsQuery.data?.data?.find((e) => e.eventId === eventId);
      props.onEventSelect?.(eventId);
      if (event) {
        props.onRangeSelect?.(event.startTime, event.endTime);
      }
    }
  }, [eventId, props.selectedEvent]);

  useEffect(() => {
    if (
      !eventId ||
      !eventsQuery.data?.data?.find((e) => e.eventId === eventId)
    ) {
      const event = eventsQuery.data?.data[0];
      if (event) {
        setEventId(event.eventId);
      }
    }
  }, [eventsQuery.data, eventId]);

  return (
    <FormControl variant="outlined" sx={{ minWidth: 250 }}>
      <>
        <InputLabel>Select Event</InputLabel>
        <Select
          labelId="selectEvent-label"
          value={eventId}
          sx={{
            height: 56,
            borderRadius: "4px 4px 0px 0px",
            borderColor: "#E0E0E0",
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: "top", // Anchors the menu to the top of the Select
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top", // Positions the menu above the Select
              horizontal: "left",
            },
          }}
          variant="filled"
          renderValue={(value) => {
            const event = eventsQuery.data?.data?.find(
              (e) => e.eventId === value,
            );
            if (event) {
              return (
                <Grid item style={{ paddingBottom: 10 }} key={event.eventId}>
                  <EventSelectorItem event={event} />
                </Grid>
              );
            } else {
              return <Typography><Trans>Select an event</Trans></Typography>;
            }
          }}
          onChange={(_event, option: any) => setEventId(option.props.value)}
        >
          {events.map((event: TeamEvent) => (
            <MenuItem
              style={{ width: 250 }}
              selected={eventId === event.eventId}
              value={event.eventId}
              key={event.eventId}
            >
              <EventSelectorItem event={event} />
            </MenuItem>
          ))}

          {eventsQuery.isLoading ? (
            <>
              <MenuItem style={{ width: 250 }} value={""} key={"show-more-l1"}>
                <Skeleton width="100%" height={50} />
              </MenuItem>
              <MenuItem style={{ width: 250 }} value={""} key={"show-more-l2"}>
                <Skeleton width="100%" height={50} />
              </MenuItem>
              <MenuItem style={{ width: 250 }} value={""} key={"show-more-l3"}>
                <Skeleton width="100%" height={50} />
              </MenuItem>
            </>
          ) : (
            <MenuItem
              style={{ width: 250 }}
              value={""}
              key={"show-more-events"}
            >
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  setFrom(
                    DateTime.fromMillis(from)
                      .minus({ millisecond: Date.now() - from })
                      .toMillis(),
                  );
                }}
              >
                Show More Events
              </Button>
            </MenuItem>
          )}
        </Select>
      </>
    </FormControl>
  );
};
