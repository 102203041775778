import { PlayerPosition } from "../team-members/team-members.model";

export interface ILoginUserData {
  access_token: string;
  refresh_token: string;
  error?: string;
  error_description?: string;
}

export type User = {
  aicId: string;
  playerId: string;
  firstName?: string;
  lastName?: string;
  avatar?: string;
  socialToken?: string;
  position?: PlayerPosition;
  country: string;
};

export type UserTokens = {
  aicId: string;
  playerId: string;
  socialId?: string;
  socialToken?: string;
  socialJWTToken?: string;
};

export const validateName = (input: string) => {
  const MAX_NAME_LENGTH = 20;
  const MIN_NAME_LENGTH = 2;
  const reg = /^[\s\u00C0-\u017FA-Za-z-]+$/;
  if (input.length < MIN_NAME_LENGTH) {
    return `Should be at least length of ${MIN_NAME_LENGTH}`;
  }

  if (input.length > MAX_NAME_LENGTH) {
    return `Should not be more then ${MAX_NAME_LENGTH}`;
  }

  if (!input.match(reg)) {
    return "Should include only letters of alphabet";
  }

  return "";
};
