import { Trans } from "@lingui/macro";
import { Button, ButtonProps, Grid, alpha } from "@mui/material";
import { PropsWithChildren, useEffect, useState } from "react";
import theme, { font } from "theme";

const ButtonSwitchSingle = ({
  active,
  children,
  style,
  ...props
}: PropsWithChildren<{ active: boolean; onClick: () => void }> &
  ButtonProps) =>
  active ? (
    <Button
      variant="contained"
      style={{
        height: 56,
        fontFamily: font.adihaus.medium,
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "20px",
        letterSpacing: "0.5px",
        textAlign: "center",
        backgroundColor: alpha(theme.actions.primary, 0.25),
        border: `1px solid ${theme.actions.primary}`,
        color: theme.actions.primary,
        borderRadius: 0,
        ...style,
      }}
      {...props}
    >
      {children}
    </Button>
  ) : (
    <Button
      variant="outlined"
      style={{
        height: 56,
        fontFamily: font.adihaus.medium,
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0.5px",
        textAlign: "center",
        borderRadius: 0,
        border: `1px solid ${theme.actions.primary}`,
        color: theme.actions.primary,
        ...style,
      }}
      {...props}
    >
      {children}
    </Button>
  );

export type ButtonSwitchProps<T> = {
  options: T[];
  initial?: string;
  onOptionChange?: (option: T) => void;
} & ButtonProps;

export const ButtonSwitch = <T extends string>({
  options,
  initial,
  onOptionChange,
  ...props
}: ButtonSwitchProps<T>) => {
  const [current, setCurrent] = useState(initial || options[0]);

  const getBordedsRadiusByIndex = (index: number) => {
    if (index === 0) {
      return "5px 0px 0px 5px";
    } else if (index === options.length - 1) {
      return "0px 5px 5px 0px";
    } else {
      return "0px";
    }
  };

  const getBorderSidesByIndex = (index: number) => {
    if (index === options.length - 1) {
      return {
        borderLeft: "none",
      };
    } else {
      return {};
    }
  };

  useEffect(() => {
    onOptionChange?.(current as T);
  }, [current]);

  return (
    <Grid container direction="row">
      {options.map((option, index) => (
        <Grid item>
          <ButtonSwitchSingle
            active={option === current}
            onClick={() => setCurrent(option)}
            style={{
              borderRadius: getBordedsRadiusByIndex(index),
              ...getBorderSidesByIndex(index),
            }}
          >
            <Trans>{option}</Trans>
          </ButtonSwitchSingle>
        </Grid>
      ))}
    </Grid>
  );
};
