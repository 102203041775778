import * as core from "@mui/material";
import { withStyles } from "@mui/styles";

export const LargeButton = withStyles(() => ({
  root: {
    "font-size": "20px",
    width: "fit-content",
    color: "#51F69D80",
  },
  outlined: {
    borderColor: "#51F69D80",
    borderWidth: "1px",
  },
  contained: {
    backgroundColor: "#51F69D",
    color: "#000000",
    textTransform: "uppercase",
  },
  label: {
    filter: "drop-shadow(1px 1px 1px)",
  },
}))(core.Button);

export const SmallButton = withStyles((theme) => ({
  root: {
    "font-size": "14px",
    width: "fit-content",
    color: "#51F69D80",
  },
  contained: {
    backgroundColor: "#51F69D",
    color: "#000000",
    textTransform: "uppercase",
  },
  outlined: {
    borderColor: "#51F69D80",
    borderWidth: "1px",
  },
}))(core.Button);
