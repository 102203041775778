import { styled } from "@mui/material/styles";
import { useDrawingArea, useXScale, useYScale } from "@mui/x-charts/hooks";
import { ScaleLinear } from "d3-scale";
import React from "react";

export const EXT_BARS_OPACITY = [
  1,
  0.7,
  0.55,
  0.45,
  0.3
];

export const StyledPath = styled("path")(
  ({ width, opacity }) => ({
    fill: "none",
    stroke: "#5D8EFB",
    shapeRendering: "crispEdges",
    strokeWidth: width,
    strokeOpacity: opacity,
    pointerEvents: "none"
  }),
);

export type ExtMetricsBarsProps = {
  extBarsData: Array<{
    xLabel: any,
    vals: Array<number>
  }>,
  xLabelsCount: number
};

export const ExtMetricsBars = ({ extBarsData, xLabelsCount }: ExtMetricsBarsProps) => {
  const { height, width } = useDrawingArea();
  const xAxisScale = useXScale() as ScaleLinear<any, any>;
  const yAxisScale = useYScale() as ScaleLinear<any, any>;

  const bWidth = (width - xLabelsCount * 4) / xLabelsCount;  // 4px between bars
  const barWidth = bWidth * 0.2; // single bar should be not more than 20 % of the bar
  return (
    <React.Fragment>
      {extBarsData.map((extBar) => extBar.vals.map((val, index) => (
        <StyledPath
          key={val}
          d={`M ${xAxisScale(extBar.xLabel) + (barWidth + 1) * index} ${yAxisScale(val)} L ${xAxisScale(extBar.xLabel) + (barWidth + 1) * index} ${yAxisScale(0)}`}
          color="secondary"
          width={barWidth}
          opacity={EXT_BARS_OPACITY[index]}
        />
      )))}

    </React.Fragment>
  );
};