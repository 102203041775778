import { getHeaders, isError, send } from "services/commons.api";
import { ITeamInfo } from "./team.model";
import config from "config";

export const getTeamById = (token: any) => (data: { teamId: string }) =>
  send<ITeamInfo>({
    apiEndpoint: config.API.GENERIC_TEAMS.GET_BY_ID,
    headers: getHeaders(token),
    urlParams: {
      teamId: data.teamId,
    },
  }).then((v) => {
    if (isError(v)) {
      throw v;
    }

    return v.data;
  });

export const getGenericTeams = (teamName: string) => (token: any) => () =>
  send<{ teams: ITeamInfo[] }>({
    apiEndpoint: config.API.GENERIC_TEAMS.GET,
    headers: getHeaders(token),
    urlParams: {
      teamName,
      includeMembership: true,
    },
  }).then((v) => {
    if (isError(v)) {
      throw v;
    }

    return v.data;
  });
