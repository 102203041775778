import { PlayerPosition } from "@gamer/common/lib/models/user";
export {
  PlayerPositionFullTitle,
  PlayerPosition,
} from "@gamer/common/lib/models/user";
export interface IMemberInfo {
  firstName?: string;
  lastName?: string;
  tagId?: string;
  fullYearsAge?: number;
  position?: PlayerPosition;
  avatar?: string;
}

export interface ITeamMemberInfo {
  id?: string;
  teamId?: string;
  userTeamId: string;
  playerId: string;
  status?: string;
  role?: string;
  tagId?: string;
  memberInfo?: IMemberInfo;
  position?: PlayerPosition;
}

export type IGetTeamMembersData = {
  teamMembers: ITeamMemberInfo[];
  totalCount: number;
};

export enum TeamMemberRole {
  OWNER = "OWNER",
  COACH = "COACH",
  PLAYER = "PLAYER",
}

export const TeamMemberRoleTitle = {
  [TeamMemberRole.OWNER]: "Manager",
  [TeamMemberRole.COACH]: "Coach",
  [TeamMemberRole.PLAYER]: "Player",
};

export enum TeamMemberStatus {
  INVITATION_PENDING = "INVITATION_PENDING",
  APPROVAL_PENDING = "APPROVAL_PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}

export type IPatchTeamMembershipData =
  | {
      role: TeamMemberRole;
    }
  | {
      status: TeamMemberStatus;
    }
  | {
      position: PlayerPosition;
    };
