import { PlayerPosition } from "@gamer/common/lib/models/user";
import { Button, FormControlLabel, Grid, Radio } from "@mui/material";
import React from "react";
import TooltipCustom from "components/tooltip.component";
import { PositionFilterItem } from "./position-text";

export type PositionChipProps = {
  position: PlayerPosition;
  value?: PlayerPosition;
  playerName?: string;
  preferred?: boolean;
  control: React.ReactElement<any, any>;
};

export const PositionChip = (props: PositionChipProps) => {
  return (
    <TooltipCustom
      arrow
      placement="top"
      title={[props.playerName, "preferred", "position"]
        .filter(Boolean)
        .join(" ")}
      style={{ textTransform: "capitalize" }}
      open={props.preferred ? undefined : false}
    >
      <Button
        variant="text"
        style={{ width: 240, textAlign: "left", borderRadius: 4 }}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
        >
          <Grid item>
            <FormControlLabel
              control={props.control || <Radio />}
              value={props.position}
              label={
                <PositionFilterItem
                  position={props.position}
                  selected={props.position === props.value}
                  preferred={props.preferred}
                />
              }
            />
          </Grid>
          <Grid item></Grid>
        </Grid>
      </Button>
    </TooltipCustom>
  );
};
