import { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  IconButton,
  Paper,
  Typography,
  LinearProgress,
} from "@mui/material";
import theme from "theme";
import CloseIcon from "@mui/icons-material/Close";
import UpdateIcon from "@mui/icons-material/Update";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { Trans } from "@lingui/macro";
import "./event-timeline.css";
import { TeamEventUserSpecificWithMembers } from "store/events/events.model";
import { queryClient, useAuthMutation } from "providers/auth";
import { removeEvent } from "store/events/events.query";

type EventRemoveProps = {
  open: boolean;
  onClose: (eventUpdated: boolean) => void;
  event: TeamEventUserSpecificWithMembers;
};

export const EventRemove = (props: EventRemoveProps) => {
  const { open, onClose, event } = props;
  const [idToRemove, setIdToRemove] = useState<string>("");
  const removeEventMutation = useAuthMutation(
    "removeEvent",
    removeEvent(idToRemove),
    {},
  );
  const loading =
    removeEventMutation.isLoading || removeEventMutation.isSuccess;

  const onSubmit = (id: string) => () => {
    setIdToRemove(id);
  };
  useEffect(() => {
    if (idToRemove) {
      removeEventMutation.mutate();
    }
  }, [idToRemove]);

  useEffect(() => {
    if (removeEventMutation.isSuccess) {
      setTimeout(
        () => {
          queryClient.invalidateQueries("events");
          onClose(true);
        },
        idToRemove === event.eventId ? 1000 : 5000,
      );
    }
  }, [removeEventMutation.isSuccess]);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      scroll="body"
      maxWidth={"sm"}
      keepMounted={open}
    >
      <Paper style={{ background: theme.background.actions }}>
        <DialogTitle id="form-dialog-title">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5">
              <Trans>
                {event.seriesId
                  ? "Remove Options: This Event or Whole Series?"
                  : "Are you sure you want to remove this event?"}
              </Trans>
            </Typography>
            <IconButton onClick={() => onClose(false)}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            <Typography variant="body1" color="white">
              <Trans>
                {event.seriesId
                  ? "You're about to remove an event in a recurring series. What would you like to do?"
                  : "Upon the event removal it would be removed for whole team. Would you like to do it?"}
              </Trans>
            </Typography>

            {loading && <LinearProgress />}
          </DialogContentText>
        </DialogContent>

        <DialogActions sx={{ justifyContent: "space-around" }}>
          <Stack
            direction="column"
            spacing={2}
            paddingTop={3}
            paddingBottom={2}
          >
            <Button
              variant="contained"
              startIcon={<CalendarMonthOutlinedIcon />}
              onClick={onSubmit(event.eventId)}
              disabled={loading}
            >
              <Trans>
                {event.seriesId
                  ? "Remove Only This Event"
                  : "Remove This Event"}
              </Trans>
            </Button>
            <Button
              variant="contained"
              startIcon={<UpdateIcon />}
              onClick={onSubmit(event.seriesId || "")}
              hidden={!event.seriesId}
              disabled={loading}
            >
              <Trans>Remove the Whole Series</Trans>
            </Button>
            <Button
              variant="text"
              onClick={() => onClose(false)}
              disabled={loading}
            >
              <Trans id="go_back_cta">Go Back</Trans>
            </Button>
          </Stack>
        </DialogActions>
      </Paper>
    </Dialog>
  );
};
