import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import TopSectionImage from "images/landing/landing-top-11.png";
import SolutionSectionImage from "images/landing/landing-solution-2.png";
import SolutionSectionImageMobile from "images/landing/landing-solution-mobile.png";
import {
  FeatureGridItemProps,
  LandingFeatures,
} from "components/landing/features.component";
import eventsFeatureImage from "images/landing/events-feature.png";
import eventsFeatureMobileImage from "images/landing/events-feature-mobile.png";
import feedbackFeatureImage from "images/landing/feedback-feature.png";
import feedbackFeatureMobileImage from "images/landing/feedback-feature-mobile.png";
import compareFeatureImage from "images/landing/compare-feature.png";
import compareFeatureMobileImage from "images/landing/compare-feature-mobile.png";
import reviewFeatureImage from "images/landing/review-feature.png";
import reviewFeatureMobileImage from "images/landing/review-feature-mobile.png";
import trackFeatureImage from "images/landing/track-feature.png";
import trackFeatureMobileImage from "images/landing/track-feature-mobile.png";
import { font } from "theme";
import { STAGE } from "config";
import { t } from "@lingui/macro";
import { getLangCountry } from "utils/i18n";

export const LEFT_MARGIN = "5%";

export enum LandingTabs {
  COACH,
  PLAYER,
};

export const getLandingTabLabels = () => ({
  [LandingTabs.COACH]: t`COACH`,
  [LandingTabs.PLAYER]: t`PLAYER`
});

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      margin: "0 auto",
      maxWidth: "1440px",
      width: "100%",
      padding: 0,
    },
    appBar: {
      flexGrow: 1,
      background: "#000000",
      transition: "all 1s",
    },
    hideHeader: {
      top: "-108px",
    },
    homeIcon: {
      marginLeft: LEFT_MARGIN,
      paddingLeft: 0,
      paddingTop: "16px",
      paddingBottom: "16px",
    },
    loginButton: {
      marginRight: LEFT_MARGIN,
      whiteSpace: "nowrap",
      fontFamily: font.adineue.cond,
    },
    topLinksContainer: {
      "& > * + *": {
        marginLeft: theme.spacing(6),
      },
      flexGrow: 1,
      alignContent: "center",
    },
    mainGrid: {
      maxWidth: "100%",
    },
    topSection: {
      background: `url(${TopSectionImage}) top no-repeat`,
      backgroundSize: "auto",
      width: "100%",
    },
    solutionSection: {
      background: `url(${SolutionSectionImage}) no-repeat`,
      backgroundSize: "auto",
      width: "100%",
      minHeight: "890px",
      backgroundPosition: "top left",
    },
    solutionSectionMobile: {
      background: `url(${SolutionSectionImageMobile}) no-repeat`,
      backgroundPositionX: "center",
      backgroundPositionY: "75%",
      backgroundSize: "100%",
      width: "100%",
      minHeight: "770px",
    },
    solutionSectionMobileDownload: {
      ":last-child": {
        "align-self": "end",
      },
    },
    coachPlayerFeatureSection: {
      width: "100%",
      backgroundColor: "#111111",
      borderRadius: "20px",
    },
    playerCoachImage: {
      maxWidth: "100%",
      width: "auto",
      height: "auto",
    },
  }),
);

const langCountry = getLangCountry();
export const AN_URL =
  "https://play.google.com/store/apps/details?id=com.adidas.gmr";
export const IOS_URL = "https://apps.apple.com/de/app/adidas-gmr/id1481945303";
export const FAQ_URL =
  STAGE === "prod"
    ? `https://faq.gmr.api.3stripes.io/${langCountry}/faq.html?web=true`
    : `https://faq.stg.gmr.api.3stripes.io/${langCountry}/faq.html?web=true`;
export const STARTER_GUIDE =
  "https://config-gamer.stg.gmr.api.3stripes.io/230710_TeamFX_Quickstarter-Flyer.pdf";
export const USER_MANUAL =
  "https://config-gamer.stg.gmr.api.3stripes.io/230703_TeamFX-Booklet_final.pdf";
export const ORDER_NOW = "https://www.adidasteam.com/en-en/join-us/";
export const getFeatures = () => ([
  {
    key: "Events",
    title: t`Events`,
    desc: t`Create events like trainings or matches and players will get a notification before the event.`,
    img: eventsFeatureImage,
    mobileImg: eventsFeatureMobileImage,
    val: LandingFeatures.EVENT
  },
  {
    key: "Feedback",
    title: t`Feedback`,
    desc: t`Receive feedback from your players on the events you have created, enhancing the training experience.`,
    img: feedbackFeatureImage,
    mobileImg: feedbackFeatureMobileImage,
    val: LandingFeatures.FEEDBACK
  },
  {
    key: "Compare",
    title: t`Compare`,
    desc: t`Compare players performance between team members and previous events.`,
    img: compareFeatureImage,
    mobileImg: compareFeatureMobileImage,
    val: LandingFeatures.COMPARE
  },
  {
    key: "Review",
    title: t`Review`,
    desc: t`Review team players' performance on digital leaderboards.`,
    img: reviewFeatureImage,
    mobileImg: reviewFeatureMobileImage,
    val: LandingFeatures.REVIEW
  },
  {
    key: "Track and Analyse",
    title: t`Track and Analyse`,
    desc: t`Track five football metrics for all players on your team with adidas TEAM FX and analyse them over time period.`,
    img: trackFeatureImage,
    mobileImg: trackFeatureMobileImage,
    val: LandingFeatures.TRACK
  },
] as Array<FeatureGridItemProps>);
