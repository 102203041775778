import React from "react";
import { Redirect } from "react-router-dom";

const MobileJoinRedirect = () => {
  const query = new URLSearchParams(window.location.search);
  const teamName = query.get("teamName") || "No Name Available";
  const teamId = query.get("teamId");

  if (teamId) {
    return (
      <Redirect to={`/clubs/gamer/teams/${teamId}/join?teamName=${teamName}`} />
    );
  } else {
    return <Redirect to={"/logout"} />;
  }
};

export default MobileJoinRedirect;
