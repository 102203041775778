import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
} from "chart.js";
import { muiTheme } from "../../theme";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export const FeedbackBars = ({
  input,
  color,
  labels,
}: {
  input: number[];
  color: "primary" | "secondary";
  labels?: string[];
}) => {
  const scales = {
    x: {
      ticks: {
        color: muiTheme.palette.text.primary,
      },
    },
    statOneAxis: {
      position: "right" as const,
      title: {
        align: "end" as const,
        display: false,
        color: muiTheme.palette.text.primary,
      },
      grid: {
        color: muiTheme.palette.text.disabled,
      },
      ticks: {
        color: muiTheme.palette.text.primary,
        stepSize: 1,
      },
    },
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom" as const,
        display: false,
        labels: {
          color: muiTheme.palette.text.primary,
          usePointStyle: true,
        },
      },
      title: {
        display: false,
        color: muiTheme.palette.text.primary,
        font: { size: 16 },
      },
    },
    scales,
    elements: {
      bar: {
        borderRadius: 0,
      },
    },
  };
  const chartLabels = labels || ["1", "2", "3", "4", "5"];

  const counts = input.reduce(
    (acc, v) => {
      acc[v - 1] += 1;
      return acc;
    },
    [0, 0, 0, 0, 0],
  );

  const data: ChartData<"bar", number[], string> = {
    labels: chartLabels,
    datasets: [
      {
        label: "Count",
        yAxisID: "statOneAxis",
        data: counts,
        backgroundColor: muiTheme.palette[color].main,
        barThickness: 10,
        borderRadius: 0,
      },
    ],
  };
  return <Bar options={options} data={data} />;
};
