import { LinePlot, MarkPlot } from "@mui/x-charts/LineChart";
import { ActiveChartColors, axisXTickFormat, showMinuteTick } from "./style-constants";
import { AxisConfig, ChartsAxisHighlight, ChartsGrid, ChartsLegend,
  ChartsTooltip, ChartsXAxis, ChartsXAxisProps, ChartsYAxis,
  LineSeriesType, ResponsiveChartContainer } from "@mui/x-charts";
import { ChartInterval, StatDef } from "./chart-types";
import { BackgroundBars } from "./background-bars";

export interface MotionSpeedLinesProps {
  dataSet: Array<any>;
  dataKeys: Array<StatDef>;
  chartInterval?: ChartInterval;
  measure?: string;
  isLoading?: boolean;
}

export const MotionSpeedLines = (props: MotionSpeedLinesProps) => {
  
  const series = new Array<LineSeriesType>();
  props.dataKeys.forEach( stat => {
    series.push({
      type: "line",
      dataKey: stat.dataKey,
      label: stat.label,
      curve: "linear",
      valueFormatter: (val) => val ? val.toString() : "0",
      color: ActiveChartColors[props.dataKeys.indexOf(stat)]
    });
  });

  return <ResponsiveChartContainer
    //margin={{ top: 5, left: 50, right: 5, bottom: 20 }}
    height = {300}
    className="MuiResponsiveChart-container"
    series = {series}
    dataset={props.dataSet}
    xAxis = {[
      { dataKey: "xLabel", scaleType: "band", id: "x-axis-id", disableTicks: true,
        categoryGapRatio: 0.75,
        valueFormatter: axisXTickFormat,
        tickLabelInterval: (value) => showMinuteTick(value as string, props.chartInterval)
      } as AxisConfig<"band", unknown, ChartsXAxisProps>
    ]}
    yAxis={[
      { id: "meters", position: "left", disableLine: true, disableTicks: true }
    ]}
    >
      <BackgroundBars
      x={props.dataSet.map((el) => el["xLabel"])} />
      <ChartsGrid horizontal />
      <ChartsAxisHighlight x="band" />
      <ChartsTooltip trigger="axis"  />
      <ChartsXAxis position="bottom" axisId="x-axis-id" />
      <ChartsYAxis label={props.measure ?? "KM/H"} position="left" labelStyle={{verticalAlign: "bottom"}} axisId="meters" />
      <LinePlot/>
      <MarkPlot />
      <ChartsLegend
      position={{vertical:"bottom", horizontal:"middle" }}
      slotProps={{legend: { direction: "row", padding: 10 }}}
    />
  </ResponsiveChartContainer>;
};
