import { Grid } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import {
  FeatureDesc,
  FeatureTitle,
  MobileFeatureDesc,
} from "./typography.component";

const useStyles = makeStyles(() =>
  createStyles({
    featureImage: {
      maxWidth: "750px",
      width: "100%",
      height: "auto",
    },
  }),
);

const useMobileStyles = makeStyles(() =>
  createStyles({
    featureImage: {
      maxWidth: "350px",
      width: "100%",
      height: "auto",
      maxHeight: "370px",
    },
  }),
);

export enum LandingFeatures {
  EVENT = "EVENT",
  FEEDBACK = "FEEDBACK",
  REVIEW = "REVIEW",
  COMPARE = "COMPARE",
  TRACK = "TRACK",
}

export interface FeatureGridItemProps {
  key: string;
  title: string;
  desc: string;
  img: string;
  mobileImg: string;
  val: LandingFeatures;
}

export const FeatureGridItem = (props: FeatureGridItemProps) => {
  const styles = useStyles();

  return (
    <Grid
      id={props.val.toString()}
      container
      wrap="nowrap"
      direction="row"
      justifyContent="space-between"
      alignItems="stretch"
      style={{ maxHeight: "460px", overflow: "hidden" }}
    >
      <Grid item>
        <Grid
          container
          style={{ maxWidth: "310px", minWidth: "250px" }}
          direction="column"
          alignItems="flex-start"
          justifyContent="center"
        >
          <Grid item style={{ paddingTop: 80 }}>
            <FeatureTitle>{props.title}</FeatureTitle>
          </Grid>
          <Grid item style={{ paddingTop: 20 }}>
            <FeatureDesc>{props.desc}</FeatureDesc>
          </Grid>
        </Grid>
      </Grid>
      <Grid item style={{ width: "auto", paddingRight: "30px" }}>
        <img
          src={props.img}
          className={styles.featureImage}
          alt={props.title}
        ></img>
      </Grid>
      <Grid style={{ maxWidth: "310px", minWidth: "250px" }} />
    </Grid>
  );
};

export const MobileFeatureGridItem = (props: FeatureGridItemProps) => {
  const styles = useMobileStyles();

  return (
    <Grid
      id={props.val.toString()}
      container
      wrap="nowrap"
      direction="column"
      justifyContent="space-between"
    >
      <Grid item>
        <Grid
          container
          style={{ padding: "0 5%", gap: "20px" }}
          direction="column"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item>
            <FeatureTitle>{props.title}</FeatureTitle>
          </Grid>
          <Grid item>
            <MobileFeatureDesc>{props.desc}</MobileFeatureDesc>
          </Grid>
        </Grid>
      </Grid>
      <Grid item style={{ width: "auto", textAlign: "right" }}>
        <img
          src={props.mobileImg}
          className={styles.featureImage}
          alt={props.title}
        ></img>
      </Grid>
    </Grid>
  );
};
