// tslint:disable: no-magic-numbers
import * as React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import Logout from "./pages/logout.page";
import LoginContainer from "./pages/login/login-manual.page";
import { Settings } from "./pages/settings.page";
import JoinTeamView from "./pages/onboarding/join-team.page";
import OnboardingProfile from "./pages/onboarding/enhance-profile.page";
import OnboardingEntry from "./pages/onboarding";
import OnboardingTeam from "./pages/onboarding/create-team.page";
import OnboardingMembers from "./pages/onboarding/add-members.page";
import LoginSelect from "./pages/login/login-select.page";
import OnboardingSelectTeam from "./pages/onboarding/select-team.page";
import OnboardingLogin from "./pages/onboarding/initial-login.page";
import { Profile } from "./pages/profile.page";
import { Dashboard } from "./pages/dashboard.page";
import Leaderboard from "./pages/leaderboard.page";
import Analytics from "./pages/analytic/analytic.page";
import Timeline from "./pages/timeline.page";
import LandingPage from "./pages/landing/landing.page";
import { NavigationProvider } from "providers/navigation.provider";
import { OnboardingInvite } from "pages/onboarding/team-invite.page";
import MobileJoinRedirect from "pages/onboarding/mobile-join-team.page";
import MobileAppDownload from "pages/onboarding/download-app.page";
import TeamFeedbackPage from "pages/feedback.page";

export const Root = () => (
  <Switch>
    <Route exact path="/index.html" component={LandingPage} />
    <Route exact path="/" component={LandingPage} />
    <Route exact path="/landing" component={LandingPage} />
    <Route exact path="/login" component={LoginSelect} />
    <Route exact path="/login/manual" component={LoginContainer} />
    <Route exact path="/login/onboarding" component={OnboardingLogin} />
    <Route path="/logout" component={Logout} />
    <Route path="/onboarding/landing/:teamId?" component={OnboardingEntry} />
    <Route path="/onboarding/profile" component={OnboardingProfile} />
    <Route path="/onboarding/team" component={OnboardingTeam} />
    <Route path="/onboarding/select" component={OnboardingSelectTeam} />
    <Route path="/onboarding/invite" component={OnboardingInvite} />
    <Route path="/onboarding/members/:teamId" component={OnboardingMembers} />
    <Route
      path="/clubs/:clubId?/teams/:teamId?/join"
      component={JoinTeamView}
    />
    <Route path="/join_team" component={MobileJoinRedirect} />
    <Route path="/download" component={MobileAppDownload} />
    <NavigationProvider>
      <Route path="/teams/:teamId/dashboard" component={Dashboard} />
      <Route path="/teams/:teamId/leaderboard" component={Leaderboard} />
      <Route path="/teams/:teamId/timeline" component={Timeline} />
      <Route path="/teams/:teamId/analytics" component={Analytics} />
      <Route path="/teams/:teamId/feedback" component={TeamFeedbackPage} />
      <Route path="/teams/:teamId/settings/:tab?" component={Settings} />
      <Route path="/teams/:teamId/profile" component={Profile} />
    </NavigationProvider>
  </Switch>
);

export default withRouter(Root);
