import * as React from "react";
import {
  Button,
  Divider,
  Grid,
  SvgIcon,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useAuthQuery } from "providers/auth";
import { getTeamEventById, getTeamEvents } from "store/events/events.query";
import { DateTime } from "luxon";
import { EventRecord } from "../containers/timeline/components/event-record";
import { EventView } from "../containers/timeline/timeline-event-view";
import { CreateTimelineEventModal } from "../containers/timeline/modals/event-create.modal";
import {
  TeamEventUserSpecific,
  TeamEventUserSpecificWithMembers,
} from "store/events/events.model";
import { NavigationContext } from "providers/navigation.provider";
import { TeamMemberRole } from "store/team-members/team-members.model";
import { EventTimeRange } from "containers/timeline/components/time-range";
import { Skeleton } from "@mui/material";
import { Plural, Trans } from "@lingui/macro";
import { useLocation } from "react-router-dom";
import { useAnalytics } from "use-analytics";

export const Timeline = () => {
  const analytics = useAnalytics();
  const { currentTeam } = React.useContext(NavigationContext);
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const isBig = useMediaQuery("(min-width:769px)");
  const { teamId } = currentTeam;
  const [from, setFrom] = React.useState(DateTime.now());
  const [selectedEvent, setSelectedEvent] =
    React.useState<TeamEventUserSpecificWithMembers | null>();
  const [to, setTo] = React.useState(DateTime.now().plus({ days: 7 }));
  const [eventId, setEventId] = React.useState<string>(
    search.get("eventId") || "",
  );
  const [createModalOpen, setCreateModalOpen] = React.useState(false);
  const [userEvents, setUserEvents] = React.useState<TeamEventUserSpecific[]>(
    [],
  );
  const [listUpdated, setListUpdated] = React.useState(false);
  const [inFuture, setInFuture] = React.useState(true);

  const eventsQuery = useAuthQuery(
    ["events", teamId, from, to],
    getTeamEvents({ teamId, from: from.toMillis(), to: to.toMillis() }),
    { enabled: Boolean(teamId) },
  );

  const eventQuery = useAuthQuery(
    ["event", eventId],
    getTeamEventById(eventId),
    { enabled: Boolean(eventId) },
  );

  React.useEffect(() => {
    const search = new URLSearchParams(location.search);
    const eventId = search.get("eventId");
    if (eventId) {
      setEventId(eventId);
    }
  }, [location]);

  React.useEffect(() => {
    if (eventQuery.isSuccess) {
      setSelectedEvent(eventQuery.data);
      new URLSearchParams(window.location.search).delete("eventId");
    }
  }, [eventQuery.isSuccess]);

  React.useEffect(() => {
    if (eventsQuery.data) {
      const events = eventsQuery.data.data;
      const time = Date.now();
      const playerEvents = events.filter((evt) => {
        if (inFuture) {
          return evt.participation && evt.endTime >= time;
        }

        return evt.participation;
      });
      const closestEventId = playerEvents.reduce(
        (acc, event) => {
          const currentDiff = Math.abs(event.startTime - time);
          if (!acc.id) {
            return {
              id: event.eventId,
              diff: currentDiff,
            };
          }

          if (currentDiff < acc.diff) {
            return {
              id: event.eventId,
              diff: currentDiff,
            };
          }

          return acc;
        },
        { id: "", diff: 0 } as { id: string; diff: number },
      ).id;
      if (!eventId) {
        setEventId(closestEventId);
      }
      setUserEvents(playerEvents.reverse());
    }
  }, [eventsQuery.data, eventId]);

  React.useEffect(() => {
    eventsQuery.refetch();
  }, [from, to]);

  React.useEffect(() => {
    if (listUpdated) {
      const timer = setTimeout(() => {
        eventsQuery.refetch();
        setListUpdated(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [listUpdated]);

  React.useEffect(() => {
    analytics.page("timeline");
  }, []);

  return (
    <Grid
      sx={{ px: "40px", paddingTop: "16px" }}
      container
      direction="column"
      spacing={3}
    >
      <Grid item id="header">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h4">
              <Trans id="timeline_events_title">Timeline Events</Trans>
            </Typography>
          </Grid>
          <Grid item hidden={currentTeam.role === TeamMemberRole.PLAYER}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setCreateModalOpen(true)}
            >
              <Trans id="create_event_label">CREATE EVENT</Trans>
            </Button>
            {createModalOpen && (
              <CreateTimelineEventModal
                teamId={teamId}
                open={createModalOpen}
                onClose={(eventCreated) => {
                  setCreateModalOpen(false);
                  setListUpdated(eventCreated);
                }}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item id="content">
        <Grid
          container
          direction="row"
          spacing={3}
          justifyContent="flex-start"
          flexWrap={isBig ? "nowrap" : "wrap"}
        >
          <Grid item id="browse">
            <Grid container direction="column" spacing={2}>
              <Grid item style={{ paddingLeft: 15 }}>
                <EventTimeRange
                  onChange={(from, to, inFuture) => {
                    setFrom(from);
                    setTo(to);
                    setEventId("");
                    setSelectedEvent(null);
                    setInFuture(inFuture);
                  }}
                />
              </Grid>
              <Grid item style={{ paddingLeft: 15 }}>
                {eventsQuery.isLoading ? (
                  <Skeleton variant="rectangular" width={70} />
                ) : (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ height: 13 }}
                  >
                    <Plural
                      value={userEvents.length}
                      zero="No results"
                      other="# results"
                      id="timeline_number_of_events"
                    ></Plural>
                  </Typography>
                )}
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="column"
                  sx={{
                    overflow: "auto",
                    height: "calc(100vh - 252px)",
                    flexWrap: "nowrap",
                  }}
                >
                  {!eventsQuery.data ? (
                    <Grid item>
                      <Grid container direction="column" spacing={2}>
                        <Grid item>
                          <Skeleton
                            variant="rectangular"
                            width={380}
                            height={80}
                          />
                        </Grid>
                        <Grid item>
                          <Skeleton
                            variant="rectangular"
                            width={380}
                            height={80}
                          />
                        </Grid>
                        <Grid item>
                          <Skeleton
                            variant="rectangular"
                            width={380}
                            height={80}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    userEvents.map((event) => (
                      <Grid item key={event.eventId}>
                        <Button
                          sx={{ p: 0, mb: "2rem" }}
                          onClick={() => {
                            setSelectedEvent(null);
                            setEventId(event.eventId);
                          }}
                        >
                          <EventRecord
                            active={event.eventId === eventId}
                            {...event}
                          />
                        </Button>
                      </Grid>
                    ))
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Divider
              orientation="vertical"
              variant="fullWidth"
              sx={{ minHeight: "calc(100vh - 235px)" }}
            />
          </Grid>

          {(eventsQuery.data && eventsQuery.data.data.length && eventId) ||
          (selectedEvent && eventId) ? (
            <Grid item id="details" sm xs>
              <EventView
                eventId={eventId}
                event={
                  selectedEvent! ||
                  eventsQuery?.data?.data?.find((v) => v.eventId === eventId) ||
                  eventsQuery?.data?.data[0]
                }
                didEventUpdate={(eventUpdated) => setListUpdated(eventUpdated)}
              />
            </Grid>
          ) : (
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <Grid item>
                <SvgIcon
                  style={{ width: 72, height: 72 }}
                  viewBox="0 0 72 72"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M39.375 19.6875H36.5625V11.25H39.375V14.0625H45V16.875H39.375V19.6875ZM28.125 19.6875H25.3125V11.25H28.125V14.0625H33.75V16.875H28.125V19.6875ZM59.0625 56.25C59.0625 57.8006 57.8006 59.0625 56.25 59.0625H16.875C15.3244 59.0625 14.0625 57.8006 14.0625 56.25V50.625H25.3125V56.25H26.7188V50.625H35.8594V56.25H37.2656V50.625H46.4062V56.25H47.8125V50.625H59.0625V56.25ZM26.7188 33.75H35.8594V27.4219H26.7188V33.75ZM37.2656 33.75H46.4062V27.4219H37.2656V33.75ZM49.2188 37.9687L53.4375 42.1875L47.4712 48.1538L46.4269 47.1094L46.4062 47.0887L45.4828 46.1653L46.4062 45.2409L48.0534 43.5938H19.6875V40.7812H48.0534L46.4062 39.1341L45.4828 38.2097L47.4712 36.2212L49.2188 37.9687ZM56.25 14.0625H50.625V11.25H47.8125V19.6875H50.625V16.875H56.25C57.8006 16.875 59.0625 18.1369 59.0625 19.6875V33.75H47.8125V27.4219H56.25V26.0156H16.875V27.4219H25.3125V33.75H14.0625V19.6875C14.0625 18.1369 15.3244 16.875 16.875 16.875H22.5V14.0625H16.875C13.7812 14.0625 11.25 16.5938 11.25 19.6875V56.25C11.25 57.2775 11.5491 58.2309 12.0356 59.0625C13.0144 60.7341 14.8087 61.875 16.875 61.875H56.25C59.3438 61.875 61.875 59.3438 61.875 56.25V19.6875C61.875 16.5938 59.3438 14.0625 56.25 14.0625Z"
                    fill="white"
                  />
                  <mask
                    id="mask0_610_17764"
                    maskUnits="userSpaceOnUse"
                    x="11"
                    y="11"
                    width="51"
                    height="51"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M39.375 19.6875H36.5625V11.25H39.375V14.0625H45V16.875H39.375V19.6875ZM28.125 19.6875H25.3125V11.25H28.125V14.0625H33.75V16.875H28.125V19.6875ZM59.0625 56.25C59.0625 57.8006 57.8006 59.0625 56.25 59.0625H16.875C15.3244 59.0625 14.0625 57.8006 14.0625 56.25V50.625H25.3125V56.25H26.7188V50.625H35.8594V56.25H37.2656V50.625H46.4062V56.25H47.8125V50.625H59.0625V56.25ZM26.7188 33.75H35.8594V27.4219H26.7188V33.75ZM37.2656 33.75H46.4062V27.4219H37.2656V33.75ZM49.2188 37.9687L53.4375 42.1875L47.4712 48.1538L46.4269 47.1094L46.4062 47.0887L45.4828 46.1653L46.4062 45.2409L48.0534 43.5938H19.6875V40.7812H48.0534L46.4062 39.1341L45.4828 38.2097L47.4712 36.2212L49.2188 37.9687ZM56.25 14.0625H50.625V11.25H47.8125V19.6875H50.625V16.875H56.25C57.8006 16.875 59.0625 18.1369 59.0625 19.6875V33.75H47.8125V27.4219H56.25V26.0156H16.875V27.4219H25.3125V33.75H14.0625V19.6875C14.0625 18.1369 15.3244 16.875 16.875 16.875H22.5V14.0625H16.875C13.7812 14.0625 11.25 16.5938 11.25 19.6875V56.25C11.25 57.2775 11.5491 58.2309 12.0356 59.0625C13.0144 60.7341 14.8087 61.875 16.875 61.875H56.25C59.3438 61.875 61.875 59.3438 61.875 56.25V19.6875C61.875 16.5938 59.3438 14.0625 56.25 14.0625Z"
                      fill="white"
                    />
                  </mask>
                  <g mask="url(#mask0_610_17764)"></g>
                </SvgIcon>
              </Grid>
              <Grid item>
                <Typography variant="body1" color="textSecondary">
                  <Trans id="timeline_no_event_selected">
                    No Event Selected
                  </Trans>
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Timeline;
