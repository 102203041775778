import { useEffect, Dispatch, RefObject, SetStateAction } from "react";

export const useSource = (
  player: RefObject<HTMLVideoElement>,
  source?: string,
  setLoader?: Dispatch<SetStateAction<boolean>>,
) => {
  useEffect(() => {
    if (!source) return;

    setLoader && setLoader(true);
    const videoPlayer = player.current;
    const ismp4 = source.endsWith("mp4");

    if (!videoPlayer) return;
    if (ismp4) {
      videoPlayer.src = source;
      return;
    }
  }, [source, player, setLoader]);
};
