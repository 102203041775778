import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { PlayerTwoStatsProps } from "../utils/utils";
import { font } from "theme";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const time = new Date().getTime();
const labels = [
  new Date(time - 300000).toLocaleTimeString(),
  new Date(time - 240000).toLocaleTimeString(),
  new Date(time - 180000).toLocaleTimeString(),
  new Date(time - 120000).toLocaleTimeString(),
  new Date(time - 60000).toLocaleTimeString(),
  new Date(time).toLocaleTimeString(),
];

export const PlayerTwoStatsBars = (props: PlayerTwoStatsProps) => {
  const secondScale = props.useSingleYAxis
    ? {}
    : {
        statTwoAxis: {
          position: "right" as const,
          title: {
            text: props.data?.statTwo?.statsMeasure || "KM",
            align: "end" as const,
            display: true,
            color: "rgba(255, 255, 255, 1)",
          },
          grid: {
            color: "rgba(114, 123, 132, 0.3)",
          },
          ticks: {
            color: "rgba(255, 255, 255, 1)",
          },
        },
      };
  const scales = {
    x: {
      ticks: {
        color: "rgba(255, 255, 255, 1)",
      },
    },
    statOneAxis: {
      position: "left" as const,
      title: {
        text: props.data?.statOne?.statsMeasure || "COUNTS",
        align: "end" as const,
        display: true,
        color: "rgba(255, 255, 255, 1)",
        font: { family: font.adihaus.regular },
      },
      grid: {
        color: "rgba(114, 123, 132, 0.3)",
      },
      ticks: {
        color: "rgba(255, 255, 255, 1)",
      },
    },
    ...secondScale,
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom" as const,
        labels: {
          color: "rgba(255, 255, 255, 1)",
          usePointStyle: true,
          font: { family: font.adihaus.regular },
        },
      },
      title: {
        display: true,
        text: props.title || "Two Stats comparison",
        color: "rgba(255, 255, 255, 1)",
        font: { size: 18, family: font.adihaus.regular },
      },
    },
    scales,
    elements: {
      bar: {
        borderRadius: 5,
        barThickness: 5
      },
    },
  };

  const data = {
    labels: props.labels || labels,
    datasets: [
      {
        label: props.data?.statOne?.statName || "Distance",
        yAxisID: "statOneAxis",
        data: props.data?.statOne?.items || [],
        backgroundColor:
          props.data?.statOne?.chartEntryColor || "rgba(239, 107, 67, 1)",
      },
      {
        label: props.data?.statTwo?.statName || "Kicks Count",
        yAxisID: props.useSingleYAxis ? "statOneAxis" : "statTwoAxis",
        data: props.data?.statTwo?.items || [],
        backgroundColor:
          props.data?.statTwo?.chartEntryColor || "rgba(252, 202, 62, 1)",
      },
    ],
  };
  return <Bar options={options} data={data} />;
};
