import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

function MetricsBallSpeed(props: SvgIconProps) {
  return (
    <SvgIcon
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      fontSize="large"
      {...props}
      style={{ fontSize: 48, ...props.style }}
    >
      <path
        fillRule="evenodd"
        d="M18.2042 23.3813L17.3215 20.665L18.8498 18.786H22.4832L24.0115 20.665L23.1288 23.3813C22.3518 23.63 21.5252 23.7666 20.6665 23.7666C19.8078 23.7666 18.9815 23.63 18.2042 23.3813ZM18.1275 7.98029L20.4415 9.66163V12.5263L17.5245 14.646L15.0278 13.6076L14.1435 10.885C15.1298 9.54296 16.5148 8.51429 18.1275 7.98029ZM27.1898 10.885L26.3052 13.6076L23.8085 14.646L20.8915 12.5263V9.66163L23.2055 7.98029C24.8182 8.51429 26.2032 9.54296 27.1898 10.885ZM27.2318 20.3923H24.3705L22.8422 18.5133L23.9602 15.0703L26.4568 14.032L28.7645 15.7086C28.7555 17.4566 28.1855 19.071 27.2318 20.3923ZM14.8762 14.032L17.3728 15.0703L18.4912 18.5133L16.9628 20.3923H14.1012C13.1475 19.071 12.5778 17.4566 12.5688 15.7086L14.8762 14.032ZM20.6665 6.66663C15.6958 6.66663 11.6665 10.6963 11.6665 15.6666C11.6665 20.6373 15.6958 24.6666 20.6665 24.6666C25.6372 24.6666 29.6665 20.6373 29.6665 15.6666C29.6665 10.6963 25.6372 6.66663 20.6665 6.66663Z"
        fill="white"
      />
      <rect
        x="5.6665"
        y="22"
        width="6"
        height="0.666666"
        rx="0.333333"
        fill="white"
      />
      <rect
        x="3"
        y="15.3333"
        width="6"
        height="0.666667"
        rx="0.333333"
        fill="white"
      />
      <rect
        x="5.6665"
        y="8.66663"
        width="6"
        height="0.666666"
        rx="0.333333"
        fill="white"
      />
    </SvgIcon>
  );
}

export default MetricsBallSpeed;
