import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import theme from "theme";
import { Button, FormControl, Grid, Popover, ButtonProps } from "@mui/material";

import { DateTime, Duration } from "luxon";

import { MenuItemSmallSelectCustom } from "components/select.component";
import { MobileDatePicker } from "components/date-picker.component";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Trans } from "@lingui/macro";
import { useAnalytics } from "use-analytics";
import { NavigationContext } from "providers/navigation.provider";

export type TimeRangeProps = {
  onChange: (from: DateTime, to: DateTime, inFuture: boolean) => void;
};

export const ButtonSwitch = ({
  active,
  children,
  style,
  ...props
}: PropsWithChildren<{ active: boolean; onClick: () => void }> &
  ButtonProps) =>
  active ? (
    <Button
      variant="contained"
      style={{
        fontSize: 14,
        lineHeight: "16px",
        height: 32,
        width: 105,
        color: theme.background.primary,
        borderRadius: 0,
        ...style,
      }}
      {...props}
    >
      {children}
    </Button>
  ) : (
    <Button
      variant="outlined"
      style={{
        fontSize: 14,
        lineHeight: "16px",
        height: 32,
        width: 105,
        borderRadius: 0,
        border: `1px solid ${theme.actions.primary}`,
        color: theme.actions.primary,
        ...style,
      }}
      {...props}
    >
      {children}
    </Button>
  );

export const EventTimeRange = ({ onChange }: TimeRangeProps) => {
  const { selectedLocale } = useContext(NavigationContext);
  const analytics = useAnalytics();
  const [inFuture, setInFuture] = useState(true);
  const [selectAnchorEl, setSelectAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const [customDateAnchorEl, setCustomDateAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const [duration, setDuration] = useState(Duration.fromObject({ days: 7 }));
  const durations = [
    Duration.fromObject({ days: 1 }),
    Duration.fromObject({ days: 7 }),
    Duration.fromObject({ days: 14 }),
    Duration.fromObject({ days: 30 }),
  ];

  useEffect(() => {
    if (inFuture) {
      const startOfDayTime = DateTime.now().startOf("day");
      onChange(startOfDayTime, startOfDayTime.plus(duration), inFuture);
    } else {
      const currentTime = DateTime.now();
      onChange(currentTime.minus(duration), currentTime, inFuture);
    }

    analytics.track("event-time-range-change", {
      inFuture,
      duration: duration.toObject(),
      durationText: durationToText(duration),
    });

    setCustomDateAnchorEl(null);
    setSelectAnchorEl(null);
  }, [inFuture, duration]);

  const durationToText = (duration: Duration) =>
    inFuture
      ? DateTime.now().plus(duration).toRelative({
          locale: selectedLocale,
        })
      : DateTime.now().minus(duration).toRelative({
          locale: selectedLocale,
        });

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      spacing={2}
      style={{
        width: 400,
      }}
    >
      <Grid item id="switch">
        <Grid container direction="row">
          <Grid item>
            <ButtonSwitch
              active={inFuture}
              onClick={() => setInFuture(true)}
              style={{ borderRadius: "5px 0px 0px 5px" }}
            >
              <Trans
                id="timeline_upcoming_events"
                context="events that would happen in future"
              >
                UPCOMING
              </Trans>
            </ButtonSwitch>
          </Grid>
          <Grid item>
            <ButtonSwitch
              active={!inFuture}
              onClick={() => setInFuture(false)}
              style={{ borderRadius: "0px 5px 5px 0px" }}
            >
              <Trans id="timeline_past_events" context="events that happened">
                PAST
              </Trans>
            </ButtonSwitch>
          </Grid>
        </Grid>
      </Grid>
      <Grid item id="selector">
        <FormControl>
          <Button
            variant="outlined"
            value={duration.toMillis()}
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              setSelectAnchorEl(event.currentTarget);
            }}
            style={{
              border: `1px solid ${theme.actions.primary}`,
              textTransform: "none",
              color: theme.actions.primary,
              fontSize: 12,
              padding: 15,
              lineHeight: "12px",
              height: 32,
            }}
          >
            <Grid container alignItems="center">
              <Grid item>{durationToText(duration)}</Grid>
              <Grid item>
                <ArrowDropDownIcon />
              </Grid>
            </Grid>
          </Button>
          <Popover
            anchorEl={selectAnchorEl}
            keepMounted
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            slotProps={{
              paper: {
                style: { backgroundColor: "#25272a", backgroundImage: "none" },
              },
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(selectAnchorEl)}
            onClose={() => setSelectAnchorEl(null)}
          >
            {durations.map((d) => (
              <MenuItemSmallSelectCustom
                style={{ width: 175 }}
                key={d.toString()}
                id={d.toString()}
                value={d.toMillis()}
                onClick={() => setDuration(d)}
                selected={d.toMillis() === duration.toMillis()}
              >
                {durationToText(d)}
              </MenuItemSmallSelectCustom>
            ))}

            <MobileDatePicker
              open={Boolean(customDateAnchorEl)}
              onClose={() => setCustomDateAnchorEl(null)}
              sx={{ width: 175, fontSize: 14, padding: 5 }}
              disableFuture={!inFuture}
              minDate={inFuture ? DateTime.now() : undefined}
              maxDate={!inFuture ? DateTime.now() : undefined}
              value={
                inFuture
                  ? DateTime.now().plus(duration)
                  : DateTime.now().minus(duration)
              }
              slotProps={{
                toolbar: {
                  className: "leaderBoard-datepicker",
                },
                layout: {
                  className: "leaderBoard-datepicker__layout",
                },
              }}
              slots={{
                field: () => (
                  <div>
                    <Button
                      size={"medium"}
                      variant="text"
                      fullWidth
                      sx={{
                        textTransform: "none",
                        fontSize: "12px",
                        justifyContent: "start",
                        padding: "14px 16px",
                        color: "white",
                      }}
                      onClick={() =>
                        setCustomDateAnchorEl((prev) => !prev as any)
                      }
                      endIcon={<ArrowRightIcon />}
                    >
                      <Trans context="date and time range with from and to">
                        Choose Range
                      </Trans>
                    </Button>
                  </div>
                ),
              }}
              onChange={(v) =>
                v &&
                setDuration(
                  inFuture ? v.diff(DateTime.now()) : DateTime.now().diff(v),
                )
              }
            />
          </Popover>
        </FormControl>
      </Grid>
    </Grid>
  );
};
