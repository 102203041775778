import { useState } from "react";
import { Button } from "@mui/material";
import "./toggle-button-group.style.css";
import { Trans } from "@lingui/macro";
import { EventType } from "@gamer/common/lib/models/events";

export const getTranslatedEventType = (type: EventType) => {
  switch (type) {
    case EventType.TRAINING:
      return <Trans id="event_type_training">TRAINING</Trans>;
    case EventType.MATCH:
      return <Trans id="event_type_match">MATCH</Trans>;
    case EventType.OTHER:
      return <Trans id="event_type_other">OTHER</Trans>;
  }
};

export interface ToggleButtonGroupProps {
  buttons: EventType[];
  value: string;
  buttonAction: (buttonName: string) => void;
}

const ToggleButtonGroup = ({
  buttons,
  value,
  buttonAction,
}: ToggleButtonGroupProps) => {
  const [clickedId, setClickedId] = useState(-1);

  const handleClick = (event, id) => {
    setClickedId(id);
    buttonAction(event.target.name);
  };

  return (
    <>
      {buttons.map((buttonLabel, i) => {
        const variant =
          i === clickedId || value === buttonLabel ? "contained" : "outlined";

        return (
          <Button
            key={i}
            variant={variant}
            
            name={buttonLabel}
            onClick={(event) => handleClick(event, i)}
            className="toggleButton"
          >
            {getTranslatedEventType(buttonLabel)}
          </Button>
        );
      })}
    </>
  );
};

export default ToggleButtonGroup;
