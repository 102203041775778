import { Grid, Paper, Typography } from "@mui/material";
import SentimentSatisfiedRoundedIcon from "@mui/icons-material/SentimentSatisfiedRounded";
import SpeedIcon from "@mui/icons-material/Speed";
import { Plural, Trans } from "@lingui/macro";

import { UserEvent } from "@gamer/common/lib/models/events";

import { FeedbackBars } from "components/charts/feedback-bars";
import theme, { muiTheme } from "../../../theme";

interface FeedbackStatsProps {
  participants: UserEvent[];
  intensityAverage: number;
  satisfactionAverage: number;
}

export const EventFeedbackStats = ({
  participants,
  intensityAverage,
  satisfactionAverage,
}: FeedbackStatsProps) => {
  const feedbacks = participants
    .filter((v) => Boolean(v.feedback))
    .map((p) => p.feedback);
  const intFeedbacks = feedbacks.filter((v) => Boolean(v?.intensityRating));
  const satisfactionFeedbacks = feedbacks.filter((v) =>
    Boolean(v?.satisfactionRating),
  );
  return (
    <Grid container direction="row" justifyContent="space-around">
      <Grid item id="intensity">
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="h6">
                  <Trans>WORKOUT INTENSITY</Trans>
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="body2"
                  style={{ color: theme.text.subtitle }}
                >
                  <Plural
                    value={intFeedbacks.length}
                    one={"# VOTE"}
                    other={"# VOTES"}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Paper
            style={{
              backgroundColor: theme.background.paper,
              padding: "24px 16px 24px 16px",
            }}
          >
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <Grid container spacing={1}>
                        <Grid item>
                          <SpeedIcon
                            style={{
                              height: 15,
                              color: muiTheme.palette.primary.main,
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <Typography variant="h6" style={{ fontSize: 15 }}>
                            <Trans context="average value">AVG.</Trans>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container direction="row" spacing={1}>
                        <Grid item>
                          <Typography variant="h4">
                            {intensityAverage.toFixed(1)}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="overline" color="textSecondary">
                            / 5
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <FeedbackBars
                        input={feedbacks.map((v) => v?.intensityRating || 0)}
                        color="primary"
                      />
                    </Grid>
                    <Grid item>
                      <Grid container justifyContent="space-between">
                        <Grid item>
                          <Typography
                            style={{ fontSize: 13 }}
                            variant="overline"
                            color="textSecondary"
                          >
                            <Trans context="complexity level">EASY</Trans>
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            style={{ fontSize: 13 }}
                            variant="overline"
                            color="textSecondary"
                          >
                            <Trans context="complexity level">HARD</Trans>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Grid item id="intensity">
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="h6">
                  <Trans>PLAYER HAPPINESS</Trans>
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="body2"
                  style={{ color: theme.text.subtitle }}
                >
                  <Plural
                    value={satisfactionFeedbacks.length}
                    one={"# VOTE"}
                    other={"# VOTES"}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Paper
            style={{
              backgroundColor: theme.background.paper,
              padding: "24px 16px 24px 16px",
            }}
          >
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <Grid container spacing={1}>
                        <Grid item>
                          <SentimentSatisfiedRoundedIcon
                            style={{
                              height: 15,
                              color: muiTheme.palette.secondary.main,
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <Typography variant="h6" style={{ fontSize: 15 }}>
                            <Trans>AVG.</Trans>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container direction="row" spacing={1}>
                        <Grid item>
                          <Typography variant="h4">
                            {satisfactionAverage.toFixed(1)}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="overline" color="textSecondary">
                            / 5
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <FeedbackBars
                        labels={["😣", "🙁", "😐", "🙂", "😊"]}
                        input={feedbacks.map((v) => v?.satisfactionRating || 0)}
                        color="secondary"
                      />
                    </Grid>
                    <Grid item>
                      <Grid container justifyContent="space-between">
                        <Grid item>
                          <Typography
                            style={{ fontSize: 13 }}
                            variant="overline"
                            color="textSecondary"
                          >
                            <Trans>LOW</Trans>
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            style={{ fontSize: 13 }}
                            variant="overline"
                            color="textSecondary"
                          >
                            <Trans>HIGH</Trans>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};
