import { SvgIcon, SvgIconProps } from "@mui/material";

function NoFeedback(props: SvgIconProps) {
  return (
    <SvgIcon
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      fontSize="large"
      {...props}
      style={{ fontSize: 32, ...props.style }}
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M21.9053 4.87595L16.317 10.4642L13.0955 11.2704L12.7311 10.906L13.536 7.68395L19.125 2.0957L21.9053 4.87595ZM16.125 9.5957L20.8447 4.87595L19.125 3.1562L14.4053 7.87595L16.125 9.5957ZM15.3915 9.9227L14.0782 8.6102L13.6403 10.36L15.3915 9.9227Z" fill="#666666"/>
      <path d="M11.2493 9.42413V9.00095H10.8261L11.2493 9.42413Z" fill="#666666"/>
      <path d="M8.57607 6.75095H12.75V6.00095H7.82607L8.57607 6.75095Z" fill="#666666"/>
      <path d="M6.61389 6.00095L7.36389 6.75095H6.15197L5.40197 6.00095H6.61389Z" fill="#666666"/>
      <path d="M4.18978 6.00095H3.75V17.251H7.5V20.7812L11.0302 17.251H15.4398L14.6898 16.501H10.7198L8.25 18.9707V16.501H4.5V6.75095H4.93978L4.18978 6.00095Z" fill="#666666"/>
      <path d="M15.902 16.501L16.652 17.251H17.8639L17.1139 16.501H15.902Z" fill="#666666"/>
      <path d="M14.8639 14.251H13.652L12.9012 13.5002H14.1131L14.8639 14.251Z" fill="#666666"/>
      <path d="M18.3261 16.501L19.0761 17.251H20.25V9.00095H19.5V16.501H18.3261Z" fill="#666666"/>
      <path d="M16.0761 14.251L15.3253 13.5002H17.25V14.251H16.0761Z" fill="#666666"/>
      <path d="M10.3639 9.75095L9.61389 9.00095H8.40197L9.15197 9.75095H10.3639Z" fill="#666666"/>
      <path d="M7.93978 9.75095L7.18978 9.00095H6.75V9.75095H7.93978Z" fill="#666666"/>
      <path d="M9.43978 11.251L10.1898 12.001H6.75V11.251H9.43978Z" fill="#666666"/>
      <path d="M11.2493 11.8482L10.652 11.251H11.2493V11.8482Z" fill="#666666"/>
      <path d="M11.689 13.5002H6.75V14.251H12.4398L11.689 13.5002Z" fill="#666666"/>
      <path d="M4.89174 4.28209L19.6501 19.0404L19.044 19.6465L4.28565 4.88818L4.89174 4.28209Z" fill="#666666"/>
    </SvgIcon>
  );
}

export default NoFeedback;