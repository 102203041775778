import { Box, Grid, Typography } from "@mui/material";
import {
  SolutionText,
  TopTextWithBox,
} from "components/landing/typography.component";
import { LandingLink } from "components/landing/link.component";
import appStoreIcon from "images/landing/app-store.png";
import playMarketIcon from "images/landing/google-play.png";
import { ReactComponent as MyIcon } from "images/landing/play_icon.svg";
import videoPoster from "images/landing/video-poster-2.png";
import { landingTheme } from "landing-theme";
import { Trans } from "@lingui/macro";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useRef, MouseEvent, TouchEvent, useState } from "react";
import { useSource } from "hooks/useSource";
import { font } from "theme";
import { useStyles, LEFT_MARGIN, IOS_URL, AN_URL } from "../constants";
import "./TopSection.style.css";

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
export default function TopSection() {
  const classes = useStyles(landingTheme);
  const [videoPaused, setVideoPaused] = useState(false);
  const bigSize = useMediaQuery("(min-width:720px)");
  const videoRef = useRef<HTMLVideoElement>(null);
  useSource(
    videoRef,
    "https://config-gamer.stg.gmr.api.3stripes.io/adidas_Team_FX_LandingVideo.mp4",
  );

  function handleVideoClick(
    e:
      | MouseEvent<HTMLVideoElement | HTMLDivElement>
      | TouchEvent<HTMLVideoElement>,
  ) {
    const videoElement = e.target as HTMLVideoElement;
    if (!isSafari) {
      e.preventDefault();
      videoElement.paused ? videoElement.play() : videoElement.pause();
    }
    setVideoPaused(videoElement.paused);
  }

  return (
    <Grid item id="topSection" className={classes.topSection}>
      <Grid
        container
        direction="column"
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <Grid item style={{ width: "100%" }}>
          <Box
            paddingLeft={LEFT_MARGIN}
            maxWidth={"580px"}
            paddingTop={bigSize ? 20 : 50}
          >
            <TopTextWithBox>
              <Trans>TRACK, COMPARE AND ANALYSE YOUR PERFORMANCE.</Trans>
            </TopTextWithBox>
          </Box>
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <Box
            paddingLeft={LEFT_MARGIN}
            paddingRight={LEFT_MARGIN}
            maxWidth={"740px"}
            paddingTop={bigSize ? 10 : 5}
          >
            <Typography variant={bigSize ? "h5" : "h6"}>
              <Trans>TEAM FX is a versatile solution designed to meet the needs of
              semi-professional or ambitious amateur football clubs. Our
              platform offers advanced sports technology to empower both coaches
              and players in improving their game.</Trans>
            </Typography>
          </Box>
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <Box
            paddingLeft={bigSize ? LEFT_MARGIN : 0}
            paddingRight={bigSize ? LEFT_MARGIN : 0}
            paddingTop={bigSize ? 20 : 10}
            mt={bigSize ? "200px" : 0}
          >
            <div className={"videoContainer"}>
              <video
                poster={videoPoster}
                style={{ display: "block", cursor: "pointer" }}
                width="100%"
                ref={videoRef}
                className="landingVideo"
                controls={bigSize}
                onCanPlay={() => setVideoPaused(true)}
                onTouchEnd={handleVideoClick}
                onClick={handleVideoClick}
                autoPlay={false}
              />
              {videoPaused && (
                <div className="controls" data-state="hidden">
                  <button
                    className={
                      isSafari ? "safari playVideoButton" : "playVideoButton"
                    }
                    id="playpause"
                    type="button"
                    data-state="play"
                  >
                    <span className="playButtonIcon">
                      <MyIcon />
                    </span>
                  </button>
                </div>
              )}
            </div>
          </Box>
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <Box
            paddingLeft={LEFT_MARGIN}
            paddingRight={LEFT_MARGIN}
            maxWidth={"820px"}
            paddingTop={1}
          >
            <Typography variant={bigSize ? "h5" : "h6"}>
              <Trans>TEAM FX has been developed in close collaboration with talented
              U14 - U17 teams, coaches and players in Germany.</Trans>
            </Typography>
          </Box>
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <Box
            paddingLeft={bigSize ? LEFT_MARGIN : 0}
            paddingTop={4}
            paddingRight={bigSize ? LEFT_MARGIN : 0}
          >
            <Grid container direction="column" style={{ maxWidth: "100%" }}>
              <Grid
                id="solution"
                item
                className={
                  bigSize
                    ? classes.solutionSection
                    : classes.solutionSectionMobile
                }
              >
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                  style={{ minHeight: bigSize ? "890px" : "715px" }}
                >
                  <Grid item style={{ width: "100%" }}>
                    <Box
                      paddingLeft={!bigSize ? LEFT_MARGIN : 0}
                      paddingTop={bigSize ? 4 : 0}
                    >
                      <SolutionText variant="h1"><Trans>Solution</Trans></SolutionText>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    style={{ width: "100%", flexGrow: bigSize ? 0 : 1 }}
                  >
                    <Box
                      paddingLeft={!bigSize ? LEFT_MARGIN : 0}
                      paddingTop={1}
                      marginBottom={"3rem"}
                      maxWidth={"360px"}
                    >
                      <Typography
                        variant={bigSize ? "h5" : "h6"}
                        style={{ fontFamily: font.adihaus.regular }}
                      >
                        <Trans>TEAM FX provides a comprehensive solution that includes
                        a mobile app for Android and iOS platforms, a tag
                        (sensor), two insoles, and a web dashboard. The sensor
                        and app enable precise tracking of five essential
                        football performance metrics: kick speed, sprint speed,
                        distance covered, explosiveness (bursts), and the number
                        of ball contacts.</Trans>
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item style={{ width: "100%" }}>
                    <Box paddingTop={6}>
                      <Grid
                        container
                        justifyContent={"center"}
                        direction={bigSize ? "column" : "row"}
                        spacing={1}
                      >
                        <Grid item>
                          <LandingLink href={IOS_URL}>
                            <img
                              height={bigSize ? "56px" : "45px"}
                              alt="Get it on App Store"
                              src={appStoreIcon}
                            />
                          </LandingLink>
                        </Grid>
                        <Grid item>
                          <LandingLink href={AN_URL}>
                            <img
                              height={bigSize ? "56px" : "45px"}
                              alt="Get it on Google Play"
                              src={playMarketIcon}
                            />
                          </LandingLink>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
