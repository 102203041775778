import styled from "styled-components";
import theme from "../theme";

const Link = styled.a`
  color: ${theme.text.primary};
  :hover {
    color: ${(props) => props.theme.textOnSecondary};
  }
`;

export default Link;
