import {
  PlayerPosition,
  PlayerPositionFullTitle,
} from "@gamer/common/lib/models/user";
import { FilterValue } from "components/filter-modal";
import { Button } from "@mui/material";
import { PositionFilterItem } from "./position-text";
import { useAnalytics } from "use-analytics";
import theme from "../../theme";
import { PositionFilterModalNew } from "./filter-position-modal";
import { DEFENCE_LIST, MIDFIELD_LIST, OFFENCE_LIST } from "./position-models";
import { PositionFilter } from "./position-models";

const positions: FilterValue[] = [
  ...OFFENCE_LIST.map((value) => ({
    value,
    title: PlayerPositionFullTitle[value],
    groupName: "OFFENSE",
  })),
  ...MIDFIELD_LIST.map((value) => ({
    value,
    title: PlayerPositionFullTitle[value],
    groupName: "MIDFIELD",
  })),
  ...DEFENCE_LIST.map((value) => ({
    value,
    title: PlayerPositionFullTitle[value],
    groupName: "DEFENSE",
  })),
];

export type PositionFilterProps = {
  open: boolean;
  filter: PositionFilter;
  onSave: (filter: PositionFilter) => void;
};

export const PositionFilterModal = (props: PositionFilterProps) => {
  const analytics = useAnalytics();

  return (
    <PositionFilterModalNew
      {...props}
      onSave={(vs) => {
        analytics.track("position-filter", { values: vs });
        props.onSave(vs as PositionFilter);
      }}
      filter={props.filter}
      title="Filter position to display"
      label={({ value, selected, onClick }) => (
        <Button
          onClick={() => onClick(value)}
          key={value}
          style={{
            borderRadius: 4,
            border: selected
              ? `1px solid ${theme.actions.primary}`
              : `1px solid ${theme.borders.secondary}`,
            margin: 3,
          }}
        >
          <PositionFilterItem
            selected={selected}
            position={value as PlayerPosition}
          />
        </Button>
      )}
      options={positions}
    />
  );
};
