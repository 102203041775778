import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import { useHistory, useParams } from "react-router-dom";
import {
  Avatar,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
  Stack,
  Box,
  TextField
} from "@mui/material";
import Background from "components/background.component";
import { queryClient, useAuthMutation } from "providers/auth";
import { TeamAvatar } from "components/avatar.component";
import { toast } from "react-toastify";
import { deleteTeamMembership } from "store/team-members/team-members.query";
import { FileUploadComponent } from "components/file-upload.component";
import { NavigationContext } from "providers/navigation.provider";
import { TeamMemberRole } from "store/team-members/team-members.model";
import { validateTeamName } from "store/user-teams/user-teams.model";
import { updateTeam } from "store/user-teams/teams.query";
import { createMedia, uploadMedia } from "store/media/media.query";
import { useMutation } from "react-query";
import { MediaType } from "store/media/media.model";
import { Trans } from "@lingui/macro";
import "./team-profile.tab.style.css";
import { font } from "theme";
import { ITeamInfo } from "store/team/team.model";

interface TeamProfileViewProps {
  playerId: string;
}

export const TeamProfileView = (props: TeamProfileViewProps) => {
  const navigator = useContext(NavigationContext);
  const { currentTeam } = navigator;
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [teamName, setTeamName] = useState(currentTeam.teamName);
  const [teamNameError, setTeamNameError] = useState("");
  const { teamId } = useParams<{ teamId: string }>();
  const history = useHistory();
  const deleteTeamMembershipMutation = useAuthMutation(
    "deleteTeamMembership",
    deleteTeamMembership,
  );
  const saveTeamProps = useAuthMutation("updateTeam", updateTeam);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const createMediaPlaceQuery = useAuthMutation("createMediaTeamAvatar", createMedia);
  const createBannerPlaceQuery = useAuthMutation("createMedia", createMedia);
  const uploadMediaQuery = useMutation("uploadMedia", uploadMedia);
  const uploadBannerQuery = useMutation("uploadMedia", uploadMedia);
  const [avatar, setAvatar] = useState(currentTeam.avatar);
  const [avatarFile, setAvatarFile] = useState<File | undefined>();
  const [avatarKey, setAvatarKey] = useState<string | null>();
  const [uploadUrl, setUploadUrl] = useState("");
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [banner, setBanner] = useState(currentTeam.banner);
  const [bannerFile, setBannerFile] = useState<File | undefined>();
  const [bannerKey, setBannerKey] = useState<string | null>();
  const [uploadBannerUrl, setUploadBannerUrl] = useState("");
  const [uploadBannerInProgress, setUploadBannerInProgress] = useState(false);
  const [isUpdatingBanner, setIsUpdatingBanner] = useState(false);
  const [isUpdatingAvatar, setIsUpdatingAvatar] = useState(false);

  useEffect(() => {
    if (createMediaPlaceQuery.isSuccess) {
      setUploadUrl(createMediaPlaceQuery.data?.url || "");
      setAvatarKey(createMediaPlaceQuery.data?.key || "");
      setUploadInProgress(false);
    }
  }, [createMediaPlaceQuery.isSuccess, createMediaPlaceQuery.data]);

  useEffect(() => {
    const isSame =
      teamName === currentTeam.teamName &&
      avatar === currentTeam.avatar &&
      banner === currentTeam.banner;
    setSubmitEnabled(!isSame);
  }, [teamName, avatar, banner]);

  useEffect(() => {
    if (currentTeam.banner) {
      setBanner(currentTeam.banner);
    }

    if (currentTeam.avatar) {
      setAvatar(currentTeam.avatar);
    }
  }, [currentTeam.banner, currentTeam.avatar]);

  useEffect(() => {
    if (createBannerPlaceQuery.isSuccess) {
      setUploadBannerUrl(createBannerPlaceQuery.data?.url || "");
      setBannerKey(createBannerPlaceQuery.data?.key || "");
      setUploadBannerInProgress(false);
    }
  }, [createBannerPlaceQuery.isSuccess, createBannerPlaceQuery.data]);

  useEffect(() => {
    if (uploadUrl && avatarFile && !uploadInProgress) {
      uploadMediaQuery.mutate({ url: uploadUrl, body: avatarFile });
      setUploadInProgress(true);
    }
  }, [uploadUrl, avatarFile, uploadMediaQuery, uploadInProgress]);

  useEffect(() => {
    if (uploadBannerUrl && bannerFile && !uploadBannerInProgress) {
      uploadBannerQuery.mutate({ url: uploadBannerUrl, body: bannerFile });
      setUploadBannerInProgress(true);
    }
  }, [uploadBannerUrl, bannerFile, uploadBannerQuery, uploadBannerInProgress]);

  useEffect(() => {
    if (currentTeam.teamName !== teamName) {
      setTeamName(currentTeam.teamName);
      setTeamNameError("");
    }
  }, [currentTeam.teamName]);
  const removePlayer = async () => {
    await deleteTeamMembershipMutation.mutateAsync({
      teamId,
      playerId: props.playerId,
    });

    if (deleteTeamMembershipMutation.isError) {
      return toast.error("Failed to remove team member!");
    }
    await queryClient.invalidateQueries("teams");
    history.push("/onboarding/landing");
  };

  const handleFileChange = async (file: File) => {
    if (file) {
      await createMediaPlaceQuery.mutateAsync({ type: MediaType.IMAGE });
      setAvatar(URL.createObjectURL(file));
      setAvatarFile(file);
      setIsUpdatingAvatar(true);
    }
  };

  const removeAvatar = () => {
    setIsUpdatingAvatar(true);
    setAvatar("");
    setAvatarFile(undefined);
    setAvatarKey(null);
  };

  const handleBannerChange = async (file: File) => {
    if (file) {
      await createBannerPlaceQuery.mutateAsync({ type: MediaType.IMAGE });
      setBanner(URL.createObjectURL(file));
      setBannerFile(file);
      setIsUpdatingBanner(true);
    }
  };

  const removeBanner = () => {
    setIsUpdatingBanner(true);
    setBanner("");
    setBannerFile(undefined);
    setBannerKey(null);
  };

  const handleSave = () => {
    const teamInfo: Omit<ITeamInfo, "teamId"> = {
      teamName,
      location: undefined,
    };

    if (isUpdatingAvatar) {
      teamInfo.avatar = avatarKey;
    }
    if (isUpdatingBanner) {
      teamInfo.banner = bannerKey;
    }

    saveTeamProps.mutate({
      teamInfo,
      teamId: currentTeam.teamId,
    });
  };

  useEffect(() => {
    if (saveTeamProps.isError) {
      toast.error(
        `Failed to update team data. ${saveTeamProps.error?.data.detail || ""}`,
      );
    } else if (saveTeamProps.isSuccess) {
      toast.success("Profile info updated!");
      setBannerKey(null);
      setAvatarKey(null);
      setIsUpdatingAvatar(false);
      setIsUpdatingBanner(false);
      setSubmitEnabled(false);
    }
  }, [ saveTeamProps.isError, saveTeamProps.isSuccess ]);

  useEffect(() => {
    if (!teamName) {
      setTeamNameError("Name is required!");
    }
    if (avatarFile) {
      setSubmitEnabled(uploadMediaQuery.isSuccess);
    }
  }, [teamName, avatarFile, uploadMediaQuery.isSuccess]);

  const onTeamNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setTeamName(value);
    const v8n = validateTeamName(value);
    if (v8n) {
      setTeamNameError(v8n);
    } else {
      setTeamNameError("");
    }
  };

  const hideLeaveTeam = () => currentTeam.role === TeamMemberRole.OWNER;

  const allowRoleTeamPropsChange =
    currentTeam.role === TeamMemberRole.OWNER ||
    currentTeam.role === TeamMemberRole.COACH;

  useEffect(() => {
    queryClient.invalidateQueries(["teams"]);
  }, [saveTeamProps.data, teamId]);

  return (
    <Background>
      <Grid container direction="column">
        <Grid item width='fit-content'>
          <Typography variant="h4">
            <Trans>TEAM PROFILE</Trans>
          </Typography>
        </Grid>
        <Grid
          sx={{
            position: "relative",
            paddingTop: 5
          }}
          item
        >
          <div
            style={{ backgroundImage: `url(${banner})` }}
            className="bannerCover"
          />
          <Grid
            container
            direction="row"
            alignItems="stretch"
            justifyContent="flex-end"
            id="teamProfile"
            zIndex={2}
            position="relative"
          >
            <Grid item xs={9}>
              <Grid container direction="column" paddingLeft={3}>
                <Grid item width={450}>
                  <Typography variant="h5">
                    <Trans>TEAM NAME</Trans>
                  </Typography>
                  <TextField
                    variant="filled"
                    label={<Trans>Team Name</Trans>}
                    value={teamName}
                    defaultValue={" "}
                    name="teamName"
                    fullWidth
                    onChange={onTeamNameChange}
                    disabled={!allowRoleTeamPropsChange}
                    helperText={teamNameError}
                    error={!!teamNameError}
                  />
                  <Typography variant="h5" sx={{ marginTop: "3rem" }}>
                    <Trans>TEAM BANNER</Trans>
                  </Typography>
                  <Stack direction={"row"} gap={2}>
                    <FileUploadComponent handleFile={handleBannerChange}>
                      <Button
                        variant="outlined"
                        size="small"
                        disabled={!allowRoleTeamPropsChange}
                      >
                        <Trans>
                        SELECT
                        </Trans>
                      </Button>
                    </FileUploadComponent>
                    {banner ? (
                      <Button
                        color="error"
                        variant="outlined"
                        hidden={!allowRoleTeamPropsChange}
                        onClick={() => removeBanner()}
                      >
                        <Trans>
                        Remove
                        </Trans>
                      </Button>
                    ) : null}
                  </Stack>
                  <Box sx={{ width: "350px" }}>
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={
                        saveTeamProps.isLoading ||
                        createBannerPlaceQuery.isLoading ||
                        uploadBannerQuery.isLoading ||
                        createMediaPlaceQuery.isLoading ||
                        !submitEnabled ||
                        !!teamNameError ||
                        !allowRoleTeamPropsChange
                      }
                      onClick={handleSave}
                      sx={{ marginTop: "3rem" }}
                      fullWidth
                    >
                      {saveTeamProps.isLoading ? (
                        <CircularProgress size={20} />
                      ) : (
                        <Trans>Save</Trans>
                      )}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <Typography variant="h5">
                    <Trans>TEAM LOGO</Trans>
                  </Typography>
                </Grid>
                <Grid item>
                  <FileUploadComponent handleFile={handleFileChange}>
                    <IconButton disabled={!allowRoleTeamPropsChange}>
                      {avatar ? (
                        <Avatar
                          style={{ width: 200, height: 200 }}
                          src={avatar}
                        />
                      ) : (
                        <TeamAvatar
                          style={{ width: 200, height: 200 }}
                          teamInfo={currentTeam}
                          default={true}
                        />
                      )}
                    </IconButton>
                  </FileUploadComponent>
                </Grid>
                <Grid item>
                  {avatar ? (
                    <Button
                      color="error"
                      variant="outlined"
                      hidden={!allowRoleTeamPropsChange}
                      onClick={() => removeAvatar()}
                    >
                      <Trans>
                      Remove
                      </Trans>
                    </Button>
                  ) : null}
                </Grid>
                <Grid item style={{ height: "50px" }}></Grid>
                <Grid item hidden={hideLeaveTeam()}>
                  <Button
                    variant="outlined"
                    style={{ width: "250px" }}
                    onClick={() => setOpenConfirmDialog(true)}
                  >
                    <Trans>
                    Leave the Team
                    </Trans>
                  </Button>

                  <Dialog
                    open={openConfirmDialog}
                    onClose={() => setOpenConfirmDialog(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      <Trans>Are you sure you want to leave the team?</Trans>
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        <Trans>
                          After you leave the team your will be removed from
                          team members. Also you data would not be available in
                          the leaderboard and analytics. In order to rejoin to
                          the team, you need to ask a Coach to be added.
                        </Trans>
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setOpenConfirmDialog(false)}>
                        <Trans>Cancel</Trans>
                      </Button>
                      <Button onClick={removePlayer} color="primary" autoFocus>
                        <Trans>Leave</Trans>
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Background>
  );
};
