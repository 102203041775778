import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import theme from "theme";
import CloseIcon from "@mui/icons-material/Close";
import UpdateIcon from "@mui/icons-material/Update";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { Trans } from "@lingui/macro";
import "./event-timeline.css";
import { TeamEventUserSpecificWithMembers } from "store/events/events.model";
import { UpdateTimelineEventModal } from "./event-update.modal";

type EventUpdateConfirmProps = {
  open: boolean;
  onClose: (eventUpdated: boolean) => void;
  event: TeamEventUserSpecificWithMembers;
};

export const EventUpdateConfirm = (props: EventUpdateConfirmProps) => {
  const { open, onClose, event } = props;
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [isUpdatingSeries, setIsUpdatingSeries] = useState(false);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      scroll="body"
      maxWidth={"sm"}
      keepMounted={open}
    >
      <Paper style={{ background: theme.background.actions }}>
        <DialogTitle id="form-dialog-title">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5">
              <Trans id="event_edit_options_title">
                Edit Options: This Event or Whole Series?
              </Trans>
            </Typography>
            <IconButton onClick={() => onClose(false)}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            <Typography variant="body1" color="white">
              <Trans id="event_edit_options_description">
                You're about to edit an event in a recurring series. What would
                you like to do?
              </Trans>
            </Typography>
          </DialogContentText>
        </DialogContent>

        <DialogActions sx={{ justifyContent: "space-around" }}>
          <Stack
            direction="column"
            sx={{ width: "fit-contentbutton-action__event-repeating-confirm" }}
            spacing={2}
            paddingTop={3}
            paddingBottom={2}
          >
            <Button
              variant="contained"
              startIcon={<CalendarMonthOutlinedIcon />}
              
              className="button-action__event-repeating-confirm"
              onClick={() => {
                setUpdateModalOpen(true);
                setIsUpdatingSeries(false);
              }}
            >
              <Trans id="edit_one_event_cta">Edit Only This Event</Trans>
            </Button>
            <Button
              variant="contained"
              startIcon={<UpdateIcon />}
              
              className="button-action__event-repeating-confirm"
              onClick={() => {
                setUpdateModalOpen(true);
                setIsUpdatingSeries(true);
              }}
            >
              <Trans id="edit_whole_series_cta">Edit the Whole Series</Trans>
            </Button>
            <Button
              variant="text"
              
              className="button-action__event-repeating-confirm"
              onClick={() => onClose(false)}
            >
              <Trans id="go_back_cta">Go Back</Trans>
            </Button>
            <UpdateTimelineEventModal
              open={updateModalOpen}
              {...event}
              onClose={(eventUpdated) => {
                setUpdateModalOpen(false);
                onClose(eventUpdated);
              }}
              isUpdatingSeries={isUpdatingSeries}
            />
          </Stack>
        </DialogActions>
      </Paper>
    </Dialog>
  );
};
