import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  linearProgressClasses,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getTeamEventsWithParticipation } from "store/events/events.query";
import { useAuthQuery } from "providers/auth";
import { font } from "theme";
import { getPlayersToCompare } from "utils/team";
import {
  TeamEventUserSpecificWithParticipants,
} from "store/events/events.model";
import { StyledDataGrid } from "components/table.component";
import { GridColDef } from "@mui/x-data-grid";
import { IconByEventType } from "containers/timeline/components/event-type.icon";
import { PlayerAvatar, PlayerName } from "components/avatar.component";
import { Trans } from "@lingui/macro";
import { NavigationContext } from "providers/navigation.provider";
import { getTeamMembers } from "store/team-members/team-members.query";
import { MobileDateTimePicker } from "components/date-picker.component";
import CheckIcon from "@mui/icons-material/Check";
import { useAnalytics } from "use-analytics";
import {
  PlayerPosition,
  PlayerPositionFullTitle,
} from "store/team-members/team-members.model";
import { EventFeedback, EventType } from "@gamer/common/lib/models/events";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import {
  ChartsReferenceLine,
  LineChart,
} from "@mui/x-charts";

export const MPS_TO_KMPH = 3.6;
export const M_IN_KM = 1000;
export const DAY_IN_MS = 86400 * 1000;

export const ProgressBarWithTicks = ({
  value,
  color,
}: {
  value: number;
  color: string;
}) => (
  <Grid container alignItems="center" direction="row" spacing={1}>
    <Grid
      item
      style={{
        fontFamily: font.adihaus.regular,
        fontStyle: "italic",
        fontSize: "18px",
      }}
    >
      {Number(value).toFixed(1)}
    </Grid>
    <Grid item width={256}>
      <LinearProgress
        value={value * 20}
        variant="determinate"
        sx={{
          height: 9,
          borderRadius: 5,
          [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: "#0D0D0D",
          },
          [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: color,
          },
        }}
      />
      <div
        style={{
          position: "relative",
          top: -10,
          left: 0,
          right: 0,
          bottom: 0,
          height: 0,
          display: "flex",
          justifyContent: "space-between",
          pointerEvents: "none",
        }}
      >
        {[0, 1, 2, 3, 4, 5].map((tick) => (
          <div
            key={tick}
            style={{
              width: 1,
              height: 10,
              backgroundColor: "#0D0D0D",
              opacity: 0.5,
            }}
          />
        ))}
      </div>
    </Grid>
  </Grid>
);

const EVENT_TYPES = ["TRAINING", "MATCH", "OTHER"];
export default function () {
  const analytics = useAnalytics();
  const { currentTeam } = React.useContext(NavigationContext);
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);
  const { teamId } = currentTeam;
  const minDate = React.useMemo(() => DateTime.now().minus({ weeks: 12 }), []);
  const [from, setFrom] = React.useState<DateTime>(
    query.get("from")
      ? DateTime.fromMillis(Number(query.get("from"))).set({ second: 0 })
      : DateTime.now().minus({ week: 1 }).set({ second: 0 }),
  );
  const [to, setTo] = React.useState<DateTime>(
    query.get("to")
      ? DateTime.fromMillis(Number(query.get("to"))).set({ second: 0 })
      : DateTime.now().set({ second: 0 }),
  );

  React.useEffect(() => {
    if (from.valueOf() > to.valueOf()) {
      const min = to;
      const max = from;

      setFrom(min);
      setTo(max);
    }

    const interval = to.valueOf() - from.valueOf();
    if (interval > DAY_IN_MS) {
      setQueryInterval("auto");
    } else if (interval > DAY_IN_MS / 6) {
      setQueryInterval("5m");
    } else {
      setQueryInterval("1m");
    }
  }, [from, to]);

  const [, setQueryInterval] = React.useState<string>("auto");

  const teamMembersQuery = useAuthQuery(
    ["teamMembers", teamId],
    getTeamMembers(teamId),
    { enabled: Boolean(teamId) },
  );
  const [eventTypes, setEventTypes] = React.useState<string[]>(EVENT_TYPES);
  const ALL_TEAM_MARKER = "ALL_TEAM";
  const [memberId, setMemberId] = React.useState<string>(ALL_TEAM_MARKER);
  const eventsQuery = useAuthQuery(
    ["events", "withParticipation", teamId, from, to],
    getTeamEventsWithParticipation({
      teamId,
      from: from.toMillis() - 1,
      to: to.toMillis() + 1,
    }),
    { enabled: Boolean(teamId && to && from) },
  );
  const [averageIntensity, setAverageIntensity] = React.useState(0);
  const [averageSatisfaction, setAverageSatisfaction] = React.useState(0);
  const onFilterClick = (type: string) => {
    if (eventTypes.includes(type)) {
      setEventTypes(eventTypes.filter((t) => t !== type));
    } else {
      setEventTypes([...eventTypes, type]);
    }
  };
  const [feedbackChartData, setFeedbackChartData] = useState<
    {
      title: string;
      type: EventType;
      intensityAverage: number;
      satisfactionAverage: number;
      startTime: number;
      index: number;
    }[]
  >([]);
  const [feedbackAveragesPerPlayer, setFeedbackAveragesPerPlayer] = useState<
    {
      id: string;
      playerId: string;
      position: PlayerPosition;
      avgIntensity: number;
      avgSatisfaction: number;
    }[]
  >([]);

  const getTeamMemeber = (playerId: string) => {
    return teamMembersQuery.data?.teamMembers.find(
      (tm) => tm.playerId === playerId,
    );
  };

  useEffect(() => {
    const filteredTypes =
      eventsQuery.data?.data.filter((e) => eventTypes.includes(e.type)) || [];

    const selectedPlayersOnly = (
      events: TeamEventUserSpecificWithParticipants[],
    ) => {
      return events.map((event) => {
        const personalFeedback =
          event.participants.find((p) => p.playerId === memberId)?.feedback ||
          null;

        return {
          ...event,
          satisfactionAverage: personalFeedback
            ? personalFeedback.satisfactionRating
            : 0,
          intensityAverage: personalFeedback
            ? personalFeedback.intensityRating
            : 0,
        };
      });
    };

    const filteredData =
      memberId && memberId !== ALL_TEAM_MARKER
        ? selectedPlayersOnly(filteredTypes)
        : filteredTypes;

    const skipMissing =
      filteredData
        .filter((event) => event.satisfactionAverage && event.intensityAverage)
        .map((e, index) => ({
          title: e.title,
          type: e.type,
          intensityAverage: e.intensityAverage,
          satisfactionAverage: e.satisfactionAverage,
          startTime: e.startTime,
          index,
        })) || [];

    setAverageIntensity(
      skipMissing.reduce((acc, e) => acc + e.intensityAverage, 0) /
        skipMissing.length || 0,
    );
    setAverageSatisfaction(
      skipMissing.reduce((acc, e) => acc + e.satisfactionAverage, 0) /
        skipMissing.length || 0,
    );
    setFeedbackChartData(skipMissing);
  }, [eventTypes, eventsQuery.data, memberId]);

  useEffect(() => {
    const filteredTypes =
      eventsQuery.data?.data.filter((e) => eventTypes.includes(e.type)) || [];

    const playerParticipations = filteredTypes.reduce(
      (acc, event) => {
        event.participants
          .filter((p) => Boolean(p.feedback))
          .forEach((p) =>
            acc[p.playerId]
              ? acc[p.playerId].push(p.feedback as EventFeedback)
              : (acc[p.playerId] = [p.feedback as EventFeedback]),
          );

        return acc;
      },
      {} as Record<string, EventFeedback[]>,
    );

    const allPlayers = Object.keys(playerParticipations).map((playerId) => ({
      id: playerId,
      playerId,
      position: getTeamMemeber(playerId)?.position || PlayerPosition.GK,
      avgIntensity:
        playerParticipations[playerId].reduce(
          (acc, p) => acc + p.intensityRating,
          0,
        ) / playerParticipations[playerId].length,
      avgSatisfaction:
        playerParticipations[playerId].reduce(
          (acc, p) => acc + p.satisfactionRating,
          0,
        ) / playerParticipations[playerId].length,
    }));

    const result =
      memberId !== ALL_TEAM_MARKER
        ? allPlayers.filter((p) => p.playerId === memberId)
        : allPlayers;

    setFeedbackAveragesPerPlayer(result);
  }, [eventTypes, eventsQuery.data, memberId]);

  const getPlayerName = (playerId: string) => {
    if (teamMembersQuery.data) {
      const player = teamMembersQuery.data.teamMembers.find(
        (v) => v.playerId === playerId,
      );
      return (
        player?.memberInfo?.firstName + " " + player?.memberInfo?.lastName || ""
      );
    }

    return playerId;
  };

  const playerFeedbackAvgColumns: GridColDef[] = [
    {
      field: "id",
      width: 50,
      headerName: "",
      renderCell: (params) => (
        <PlayerAvatar teamId={teamId} playerId={params.value} showPosition />
      ),
    },
    {
      field: "playerId",
      headerName: "Name",
      renderCell: (params) => getPlayerName(params.row.id),
      flex: 1,
    },
    {
      field: "position",
      headerName: "position",
      valueFormatter: (v) => PlayerPositionFullTitle[v],
      flex: 1,
    },
    {
      field: "avgIntensity",
      headerName: "Workout Intensity",
      width: 312,
      renderCell: (params) => (
        <ProgressBarWithTicks value={params.value} color={"#3960AC"} />
      ),
    },
    {
      field: "avgSatisfaction",
      headerName: "Player Happiness",
      flex: 1,
      renderCell: (params) => (
        <ProgressBarWithTicks value={params.value} color={"#FFB700"} />
      ),
    },
  ];

  const openTheEvent = (
    event: Pick<TeamEventUserSpecificWithParticipants, "eventId" | "teamId">,
  ) => {
    history.push(`/teams/${event.teamId}/timeline?eventId=${event.eventId}`);
  };

  return (
    <Stack direction="column" padding="20px">
      <Grid container direction="column" spacing={5}>
        <Grid item>
          <Typography variant="h3" width="fit-content">
            Feedback
          </Typography>
        </Grid>
        <Grid item>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item id="left-date-and-members">
              <Grid container direction="row">
                <Grid item id="date-selector">
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item>
                      <MobileDateTimePicker
                        label="From"
                        ampm={false}
                        disableFuture={true}
                        slotProps={{
                          textField: {
                            className: "leaderBoard-datepicker-input",
                          },
                          toolbar: {
                            className: "leaderBoard-datepicker",
                          },
                          layout: {
                            className: "leaderBoard-datepicker__layout",
                          },
                        }}
                        className={"dayTimePicker-input"}
                        format={"MMMM dd HH:mm"}
                        maxDate={to}
                        value={from}
                        onChange={(v) => {
                          setFrom(v || DateTime.now());
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <MobileDateTimePicker
                        ampm={false}
                        label="To"
                        format={"MMMM dd HH:mm"}
                        slotProps={{
                          textField: {
                            className: "leaderBoard-datepicker-input",
                          },
                          toolbar: {
                            className: "leaderBoard-datepicker",
                          },
                          layout: {
                            className: "leaderBoard-datepicker__layout",
                          },
                        }}
                        maxDate={DateTime.now()}
                        minDate={from}
                        value={to}
                        className={"dayTimePicker-input"}
                        onChange={(v) => {
                          setTo(v || DateTime.now());
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item id="member-selector">
                  <FormControl
                    variant="filled"
                    sx={{ ml: 4, minWidth: "300px" }}
                  >
                    <InputLabel>
                      <Trans>Select player</Trans>
                    </InputLabel>
                    <Select
                      value={memberId}
                      onChange={(_event: any, option: any) =>
                        setMemberId(option.props.value)
                      }
                      sx={{
                        "& .MuiSelect-select": {
                          paddingTop: "15px",
                          fontFamily: font.adihaus.regular,
                          fontSize: "16px",
                          backgroundColor: "#1b1a1a",
                        },
                      }}
                    >
                      <MenuItem
                        key={"team-select-menu-item-all"}
                        value={ALL_TEAM_MARKER}
                        selected={ALL_TEAM_MARKER === memberId}
                      >
                        All players
                      </MenuItem>
                      {getPlayersToCompare(
                        memberId,
                        teamMembersQuery.data?.teamMembers || [],
                      )}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item id="type-filter">
              <Grid
                container
                alignItems="center"
                padding="10px"
                spacing="2px"
                style={{
                  backgroundColor: "#1A1A1A",
                  border: "1px solid #333333",
                  borderRadius: "4px",
                }}
              >
                <Grid item>
                  <Typography
                    fontSize="12px"
                    fontFamily={font.adineue.cond}
                    style={{
                      letterSpacing: "1.2000000476837158px",
                      lineHeight: "8px",
                    }}
                  >
                    Event filter types
                  </Typography>
                </Grid>
                {EVENT_TYPES.map((type) => (
                  <Grid item key={type}>
                    <Button
                      onClick={() => onFilterClick(type)}
                      key={type}
                      color="secondary"
                      variant={
                        eventTypes.includes(type) ? "contained" : "outlined"
                      }
                      style={{
                        height: 32,
                        borderRadius: 20,
                        padding: 10,
                        margin: 3,
                      }}
                    >
                      <Typography
                        key={type}
                        variant="subtitle2"
                        fontFamily={font.adihaus.regular}
                        fontSize="12px"
                        color={
                          eventTypes.includes(type) ? "black" : "secondary"
                        }
                      >
                        <Grid container alignItems="center" spacing={1}>
                          <Grid
                            item
                            marginTop="4px"
                            hidden={!eventTypes.includes(type)}
                          >
                            <CheckIcon />
                          </Grid>
                          <Grid item>
                            <Trans>{type}</Trans>
                          </Grid>
                        </Grid>
                      </Typography>
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Typography
            variant="h6"
            style={{
              fontFamily: font.adihaus.medium,
              fontSize: "24px",
              fontStyle: "italic",
              fontWeight: "500",
              lineHeight: "24px",
              letterSpacing: "1px",
              textAlign: "left",
            }}
          >
            <IconByEventType type={EventType.TRAINING} size={20} />
            <Trans>Intensity</Trans> VS.
            <SentimentSatisfiedAltIcon
              fontSize="large"
              style={{ paddingTop: 5 }}
            />
            Happiness{memberId !== ALL_TEAM_MARKER ? " OF" : ""}
            {memberId !== ALL_TEAM_MARKER ? (
              <a href={`/teams/${teamId}/analytics`}>
                <PlayerName
                  playerId={memberId}
                  teamId={teamId}
                  color="primary"
                  style={{
                    fontFamily: font.adihaus.medium,
                    fontSize: "24px",
                    fontStyle: "italic",
                    fontWeight: "500",
                    lineHeight: "24px",
                    letterSpacing: "1px",
                    textAlign: "left",
                    textDecoration: "underline",
                  }}
                />
              </a>
            ) : (
              ""
            )}
          </Typography>
        </Grid>
        <Grid item height={300} width={"100%"}>
          {eventsQuery.isLoading && feedbackChartData ? (
            <Skeleton height={350} />
          ) : (
            <Grid container direction="row" alignItems="center">
              <Grid item sm={10} height={300}>
                <LineChart
                  dataset={feedbackChartData}
                  xAxis={[
                    {
                      id: "Event",
                      dataKey: "index",
                      valueFormatter: (index) => {
                        const event = eventsQuery.data?.data[index as number];
                        return event
                          ? `${DateTime.fromMillis(event.startTime).toFormat(
                              "ccc dd.MM",
                            )}\n${event.title}`
                          : index + "";
                      },
                      scaleType: "band",
                      disableTicks: true,
                    },
                  ]}
                  yAxis={
                    feedbackChartData.length
                      ? [
                          {
                            max: 5,
                            min: 0,
                            disableLine: true,
                            disableTicks: true,
                          },
                        ]
                      : []
                  }
                  series={[
                    {
                      id: "Intensity",
                      curve: "linear",
                      dataKey: "intensityAverage",
                      color: "#3960AC",
                      showMark: false,
                    },
                    {
                      id: "Satisfaction",
                      curve: "linear",
                      dataKey: "satisfactionAverage",
                      color: "#FFB700",
                      showMark: false,
                    },
                  ]}
                  tooltip={{ trigger: "axis" }}
                  grid={{ horizontal: true }}
                  axisHighlight={{ x: "band" }}
                  slotProps={{
                    legend: {
                      direction: "row",
                      position: { vertical: "bottom", horizontal: "middle" },
                      padding: 10,
                    },
                  }}
                >
                  <ChartsReferenceLine
                    y={averageIntensity}
                    lineStyle={{ stroke: "#3960AC", strokeDasharray: "10 10" }}
                  />
                  <ChartsReferenceLine
                    y={averageSatisfaction}
                    lineStyle={{ stroke: "#FFB700", strokeDasharray: "10 10" }}
                  />
                </LineChart>
              </Grid>
              <Grid item sm={2}>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-around"
                      alignItems="center"
                      spacing="20px"
                    >
                      <Grid item>
                        <IconByEventType
                          type={EventType.TRAINING}
                          size={20}
                          fill="#5189F6"
                        />
                      </Grid>
                      <Grid
                        item
                        style={{
                          //styleName: Web/Headlines/HL Small;
                          fontFamily: font.adihaus.medium,
                          fontSize: "24px",
                          fontStyle: "italic",
                          fontWeight: "500",
                          lineHeight: "24px",
                          letterSpacing: "1px",
                          textAlign: "left",
                        }}
                      >
                        {averageIntensity.toFixed(1)}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-around"
                      alignItems="center"
                      spacing="20px"
                    >
                      <Grid item style={{ color: "#FFB700" }}>
                        <SentimentSatisfiedAltIcon />
                      </Grid>
                      <Grid
                        item
                        style={{
                          //styleName: Web/Headlines/HL Small;
                          fontFamily: font.adihaus.medium,
                          fontSize: "24px",
                          fontStyle: "italic",
                          fontWeight: "500",
                          lineHeight: "24px",
                          letterSpacing: "1px",
                          textAlign: "left",
                        }}
                      >
                        {averageSatisfaction.toFixed(1)}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>Average</Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item height={100}></Grid>
        <Grid item width="100%" hidden={memberId !== ALL_TEAM_MARKER}>
          {eventsQuery.isLoading ? (
            <Skeleton height={300} />
          ) : (
            <StyledDataGrid
              columns={playerFeedbackAvgColumns}
              rows={feedbackAveragesPerPlayer}
              autoPageSize
              sx={{
                height: 450,
                width: "95%",
                boreder: "1px solid white",
                border: "1px solid #262626", // Corrected typo here
                borderRadius: "4px", // Added border radius
                "& .MuiDataGrid-columnHeaderTitle": {
                  textOverflow: "clip",
                  whiteSpace: "break-spaces",
                  lineHeight: 1,
                },
              }}
            />
          )}
        </Grid>
      </Grid>
    </Stack>
  );
}
