import { SvgIcon, SvgIconProps } from "@mui/material";

function NotSynced(props: SvgIconProps) {
  return (
    <SvgIcon
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      fontSize="large"
      {...props}
      style={{ fontSize: 32, verticalAlign: "middle", ...props.style }}
    >
      <path d="M14.7026 12.8778L12.4955 4.64099C12.1953 3.52071 11.0438 2.85588 9.92355 3.15606L6.02534 4.20058L6.65521 4.83045L10.1232 3.9012C10.832 3.7113 11.5605 4.1319 11.7504 4.84065L13.6116 11.7868L14.7026 12.8778Z" fill="#666666"/>
      <path d="M14.0553 13.4427L15.1463 14.5337L15.5888 16.1854L14.4979 15.0945L14.0553 13.4427Z" fill="#666666"/>
      <path d="M5.69919 5.08662L5.06932 4.45675L4.26599 4.672C4.21646 4.68527 4.16783 4.7002 4.12014 4.71672L4.74556 5.34214L5.69919 5.08662Z" fill="#666666"/>
      <path d="M3.91185 5.72062L3.36595 5.17472C2.82481 5.68762 2.57453 6.47318 2.78106 7.24397L6.21966 20.077C6.51983 21.1973 7.67134 21.8621 8.79162 21.5619L14.4492 20.046C15.4595 19.7752 16.0994 18.8121 15.9951 17.8039L14.9416 16.7503L15.189 17.6737C15.3789 18.3824 14.9583 19.1109 14.2495 19.3008L8.59196 20.8168C7.88321 21.0067 7.15471 20.5861 6.9648 19.8773L3.5262 7.04431C3.39357 6.54931 3.55873 6.04468 3.91185 5.72062Z" fill="#666666"/>
      <path d="M10.8166 12.6254L12.0584 13.8672L8.68659 17.2783L8.15834 15.3069L10.8166 12.6254Z" fill="#666666"/>
      <path d="M6.76654 10.1126L7.18499 9.6945L8.41582 10.9442L7.29391 12.0808L6.76654 10.1126Z" fill="#666666"/>
      <path d="M7.46198 12.708L7.99024 14.6795L10.373 12.2695L9.14139 11.0165L7.46198 12.708Z" fill="#666666"/>
      <path d="M15.8276 7.96676L16.1616 8.15954C16.997 8.64153 17.6065 9.4357 17.8561 10.3672C18.1057 11.2988 17.9749 12.2913 17.4925 13.1264L17.2996 13.4604L16.6316 13.0745L16.8245 12.7405C17.2045 12.0826 17.3076 11.3007 17.111 10.5669C16.9143 9.83304 16.4341 9.20741 15.7761 8.82771L15.442 8.63493L15.8276 7.96676Z" fill="#666666"/>
      <path d="M17.0892 6.55247L16.7552 6.35956L16.3694 7.02758L16.7034 7.22049C18.9613 8.52451 19.7349 11.4117 18.4316 13.67L18.2388 14.0041L18.9069 14.3897L19.0997 14.0556C20.616 11.4284 19.716 8.06952 17.0892 6.55247Z" fill="#666666"/>
      <path d="M17.6826 4.75243L18.0167 4.94531C21.5318 6.97518 22.7362 11.4701 20.707 14.9856L20.5141 15.3197L19.846 14.934L20.0388 14.6C21.8552 11.4534 20.7772 7.4302 17.6309 5.61336L17.2969 5.42047L17.6826 4.75243Z" fill="#666666"/>
      <path d="M4.22023 3.61144L19.6499 19.0411L19.0438 19.6472L3.61414 4.21753L4.22023 3.61144Z" fill="#666666"/>
    </SvgIcon>
  );
}

export default NotSynced;