import { Paper } from "@mui/material";
import { withStyles } from "@mui/styles";

const Background = withStyles((theme) => ({
  root: {
    background: theme.palette.background.default,
  },
}))(Paper);

export default Background;
