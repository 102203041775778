import { Tooltip } from "@mui/material";
import { withStyles } from "@mui/styles";

export const TooltipCustom = withStyles((_theme) => ({
  tooltip: {
    backgroundColor: "#444A4F",
    color: "#FFFFFF",
    maxWidth: 160,
    fontSize: "12px",
    borderRadius: "4px",
  },
}))(Tooltip);

export default TooltipCustom;
