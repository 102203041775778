import { templater } from "./utils";
import history from "./history";
import clubTeamsApi from "../config";

export enum ApplicationStatusCodes {
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
}

export const TIMEOUT = 30000;
export interface HttpResponse<T = any> {
  status: number;
  data: T;
}

export type IErrorData = { title: string; detail: string };
export type IErrorResult = HttpResponse<IErrorData>;
export type ISuccessResult<TData> = HttpResponse<TData>;

export type IAsyncResult<TData> = Promise<IErrorResult | ISuccessResult<TData>>;

const MAX_SUCCESS_HTTP_CODE = 299;
export const isError = (resp: HttpResponse): resp is IErrorResult =>
  resp.status ? resp.status > MAX_SUCCESS_HTTP_CODE : true;

export const isOkResponse = (resp: HttpResponse) => !isError(resp);

export const isUnauthorizedError = (resp: HttpResponse) =>
  isError(resp) &&
  (resp.status === ApplicationStatusCodes.UNAUTHORIZED ||
    resp.status === ApplicationStatusCodes.FORBIDDEN);

export const isNotFound = (resp: HttpResponse) =>
  isError(resp) && resp.status === ApplicationStatusCodes.NOT_FOUND;

export const isBadRequestError = (resp: HttpResponse) =>
  isError(resp) && resp.status === ApplicationStatusCodes.BAD_REQUEST;

export const isServerError = (resp: HttpResponse) =>
  isError(resp) && resp.status >= ApplicationStatusCodes.INTERNAL_SERVER_ERROR;

export const getHeaders = (authToken?: string) => {
  const baseHeaders = {
    "Content-Type": "application/json",
    "x-api-key": clubTeamsApi.API.USER.API_KEY
  };

  return authToken && authToken.length > 0
    ? new Headers({
      ...baseHeaders,
      Authorization: `Bearer ${authToken}`,
    })
    : new Headers(baseHeaders);
};

export const getFormHeaders = () => {
  const baseHeaders = {
    "Content-Type": "application/x-www-form-urlencoded",
    "x-api-key": clubTeamsApi.API.USER.API_KEY
  };

  return new Headers(baseHeaders);
};

export const getImageHeaders = () => {
  const baseHeaders = {
    "Content-Type": "image",
    "x-api-key": clubTeamsApi.API.USER.API_KEY
  };

  return new Headers(baseHeaders);
};

export interface ISendParams {
  apiEndpoint: {
    baseUrl?: string;
    apiKey?: string;
    url?: string;
    method: string;
    finalUrl?: string;
  };
  urlParams?: object;
  body?: any;
  headers?: Headers;
}

const getBody = (body: any, headers: Headers) => {
  if (headers.get("Content-Type") === "application/x-www-form-urlencoded") {
    return body as FormData;
  } else if (headers.get("Content-Type") === "image") {
    return body as File;
  } else {
    return body ? JSON.stringify(body) : null;
  }
};

export const send = async <TData>({
  apiEndpoint,
  urlParams = {},
  body,
  headers = getHeaders(),
}: ISendParams) => {
  const url =
    apiEndpoint.finalUrl ||
    templater(`${apiEndpoint.baseUrl}/${apiEndpoint.url}`, urlParams);
  if (apiEndpoint.apiKey) {
    headers.set("X-Api-Key", apiEndpoint.apiKey);
  }
  let res, data;

  try {
    const prom = fetch(url, {
      method: apiEndpoint.method,
      body: getBody(body, headers),
      headers: headers,
    });
    // tslint:disable-next-line: no-console
    // console.log(prom);
    res = await prom;
    // tslint:disable-next-line: no-console
    // console.log(res);

    data = await res.json();
  } catch (error) {
    const stringifiedError = JSON.stringify(error);
    data = {
      message: stringifiedError === "{}" ? "Network error" : stringifiedError,
    };
  }

  const result: IErrorResult | ISuccessResult<TData> = {
    status: res ? res.status : 0,
    data,
  };

  return result;
};

interface ISendWithAuthParams {
  store: any;
  errorWhileSaving?: boolean;
  options: ISendParams;
}

// tslint:disable:completed-docs
export async function sendWithAuth<TData>({
  store,
  options,
}: ISendWithAuthParams) {
  const patchOptions = (currentStore: any) => ({
    ...options,
    headers: getHeaders(currentStore.getState().user.token),
  });

  const result = await send<TData>(patchOptions(store));

  if (isError(result) && isUnauthorizedError(result)) {
    history.push("/logout");

    return result;
  }

  return result;
}
