import { getHeaders, isError, send } from "services/commons.api";
import { PlayerAutoEvent } from "./auto-events.model";
import api from "../../config";

export const getAutoEvents = (token: string) => () =>
  send<{ data: PlayerAutoEvent[] }>({
    apiEndpoint: api.API.AUTO_EVENTS.GET_LIST,
    headers: getHeaders(token),
  }).then((v) => {
    if (isError(v)) {
      throw v;
    }

    return v.data;
  });
