import { getHeaders, isError, send } from "services/commons.api";
import { User, UserTokens } from "./user.model";
import { sendQuery } from "../../providers/auth";
import config from "../../config";

export const getUserProfile = (token: string) => (_context) =>
  send<User>({
    apiEndpoint: config.API.USER.GET,
    headers: getHeaders(token),
  }).then((v) => {
    if (isError(v)) {
      throw v;
    }

    return v.data;
  });

export const updateUserProfile =
  (token: string) =>
    ({ data: { firstName, lastName, position, avatar } }) =>
      send<User>({
        apiEndpoint: config.API.USER.PATCH,
        headers: getHeaders(token),
        body: {
          firstName,
          lastName,
          position,
          avatar,
        },
      }).then((v) => {
        if (isError(v)) {
          throw v;
        }

        return v.data;
      });

export const getUserTokens = (token: string) => (_context) =>
  send<UserTokens>({
    apiEndpoint: config.API.USER.TOKENS,
    headers: getHeaders(token),
  }).then((v) => {
    if (isError(v)) {
      throw v;
    }

    return v.data;
  });

interface SearchByTagResponse {
  tags: Array<{
    playerId: string;
  }>;
}

export const searchByTagId = (tagId: string) => (token: string) => () =>
  sendQuery<SearchByTagResponse>({
    apiEndpoint: config.API.USER.SEARCH,
    urlParams: {
      tagId,
    },
    headers: getHeaders(token),
  });
