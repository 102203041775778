import { useEffect, useState } from "react";
import { Trans } from "@lingui/macro";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import {
  EventRepeatingCheckBox,
  ReoccuringDaysProps,
  WeekDays,
} from "./event-repeating-checkbox";
import { useAnalytics } from "use-analytics";

export interface ReoccuranceProps {
  isRepeating: boolean;
  repeatFreq: RepeatFrequency;
  repeatDays: ReoccuringDaysProps[];
  dtStart: DateTime;
  dtEnd: DateTime;
}

export enum RepeatFrequency {
  EVERY_WEEK = "Every Week",
  EVERY_SECOND_WEEK = "Every Second Week",
  EVERY_FOUR_WEEK = "Every Four Weeks",
}

export interface EventRepeatingProps {
  isUpdatingSeries?: boolean;
  isUpdatingEvent: boolean;
  selectedRepeatingEvent: boolean;
  props: ReoccuranceProps;
  handleChange: (reoccurance: ReoccuranceProps) => void;
}

export const EventRepeating = ({
  isUpdatingSeries,
  isUpdatingEvent,
  selectedRepeatingEvent,
  props,
  handleChange,
}: EventRepeatingProps) => {
  const analytics = useAnalytics();
  const isSmallScreen = useMediaQuery("(max-width: 480px)");

  const { isRepeating, repeatFreq, repeatDays, dtStart, dtEnd } = props;

  const reocccuringDays: ReoccuringDaysProps = {
    isChecked: false,
    day: WeekDays.MO,
  };
  const repeatingTimes = Object.values(RepeatFrequency);

  const [isEventRepeating, setIsEventRepeating] = useState(
    isUpdatingSeries ?? isRepeating,
  );
  const [repeatsEvery, setRepeatsEvery] = useState<RepeatFrequency>(repeatFreq);
  const [repeatingStartDate, setRepeatingStartDate] = useState(dtStart);
  const [repeatingEndDate, setRepeatingEndDate] = useState(dtEnd);
  const [mondayChecked, setMondayChecked] = useState<ReoccuringDaysProps>({
    ...reocccuringDays,
    day: WeekDays.MO,
  });
  const [tuesdayChecked, setTuesdayChecked] = useState<ReoccuringDaysProps>({
    ...reocccuringDays,
    day: WeekDays.TU,
  });
  const [wednesdayChecked, setWednesdayChecked] = useState<ReoccuringDaysProps>(
    {
      ...reocccuringDays,
      day: WeekDays.WE,
    },
  );
  const [thursdayChecked, setThursdayChecked] = useState<ReoccuringDaysProps>({
    ...reocccuringDays,
    day: WeekDays.TH,
  });
  const [fridayChecked, setFridayChecked] = useState<ReoccuringDaysProps>({
    ...reocccuringDays,
    day: WeekDays.FR,
  });
  const [saturdayChecked, setSaturdayChecked] = useState<ReoccuringDaysProps>({
    ...reocccuringDays,
    day: WeekDays.SA,
  });
  const [sundayChecked, setSundayChecked] = useState<ReoccuringDaysProps>({
    ...reocccuringDays,
    day: WeekDays.SU,
  });

  const getTranslatedRepeatingFrequency = (freq: RepeatFrequency) => {
    switch (freq) {
      case RepeatFrequency.EVERY_WEEK:
        return <Trans id="repeat_every_week">Every Week</Trans>;
      case RepeatFrequency.EVERY_SECOND_WEEK:
        return <Trans id="repeat_every_second_week">Every Second Week</Trans>;
      case RepeatFrequency.EVERY_FOUR_WEEK:
        return <Trans id="repeat_every_four_week">Every Four Weeks</Trans>;
    }
  };

  useEffect(() => {
    repeatDays.forEach((ele) => {
      switch (ele.day) {
        case WeekDays.MO:
          setMondayChecked((prev) => ({ ...prev, ...ele }));
          break;
        case WeekDays.TU:
          setTuesdayChecked((prev) => ({ ...prev, ...ele }));
          break;
        case WeekDays.WE:
          setWednesdayChecked((prev) => ({ ...prev, ...ele }));
          break;
        case WeekDays.TH:
          setThursdayChecked((prev) => ({ ...prev, ...ele }));
          break;
        case WeekDays.FR:
          setFridayChecked((prev) => ({ ...prev, ...ele }));
          break;
        case WeekDays.SA:
          setSaturdayChecked((prev) => ({ ...prev, ...ele }));
          break;
        case WeekDays.SU:
          setSundayChecked((prev) => ({ ...prev, ...ele }));
          break;
        default:
          break;
      }
    });
  }, []);

  useEffect(() => {
    const days: ReoccuringDaysProps[] = [];
    if (mondayChecked.isChecked) {
      days.push(mondayChecked);
    }
    if (tuesdayChecked.isChecked) {
      days.push(tuesdayChecked);
    }
    if (wednesdayChecked.isChecked) {
      days.push(wednesdayChecked);
    }
    if (thursdayChecked.isChecked) {
      days.push(thursdayChecked);
    }
    if (fridayChecked.isChecked) {
      days.push(fridayChecked);
    }
    if (saturdayChecked.isChecked) {
      days.push(saturdayChecked);
    }
    if (sundayChecked.isChecked) {
      days.push(sundayChecked);
    }

    handleChange({
      isRepeating: isEventRepeating,
      repeatFreq: repeatsEvery,
      repeatDays: days,
      dtStart: repeatingStartDate,
      dtEnd: repeatingEndDate,
    });
  }, [
    isEventRepeating,
    repeatsEvery,
    repeatingStartDate,
    repeatingEndDate,
    mondayChecked,
    tuesdayChecked,
    wednesdayChecked,
    thursdayChecked,
    fridayChecked,
    saturdayChecked,
    sundayChecked,
  ]);

  return (
    <Stack direction="column" sx={{ width: "100%" }} spacing={2}>
      <Stack
        direction="row"
        sx={{ width: "100%" }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid container direction="row" alignItems="center" spacing={1} alignContent="center">
          <Grid item>
          <Switch
            disabled={isUpdatingEvent && selectedRepeatingEvent}
            checked={isRepeating}
            onClick={() => {
              analytics.track("event-repeating-switch", { isEventRepeating: !isEventRepeating });
              setIsEventRepeating((prev) => !prev);
            }}
          /></Grid>
          <Grid item>
          <Typography variant="body1" color="white">
            <Trans id="event_switch_repeating_training_label">
              Repeating Training
            </Trans>
          </Typography>
          </Grid>
        </Grid>
        {isEventRepeating && (
          <FormControl
            
            variant="standard"
            sx={{
              maxWidth: "450px",
              width: "100%",
            }}
          >
            <InputLabel id="select-repeating-label">
              <Trans id="event_repeating_repeats_label">Repeats</Trans>
            </InputLabel>
            <Select
              labelId="select-repeating-label"
              id="select-repeating"
              label={<Trans id="event_repeating_repeats_label">Repeats</Trans>}
              value={repeatsEvery}
              onChange={(_e, v: any) => setRepeatsEvery(v.props.value)}
            >
              {repeatingTimes.map((k, i) => (
                <MenuItem key={i} value={k}>
                  {getTranslatedRepeatingFrequency(k)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Stack>

      {isEventRepeating && (
        <Stack>
          <Typography fontSize={12}>
            <Trans id="event_repeating_every_label_text">EVERY</Trans>:
          </Typography>
          <Grid container spacing={2} paddingBottom={2}>
            <Grid item xs={6}>
              <Stack direction="column" spacing={1}>
                <EventRepeatingCheckBox
                  data={mondayChecked}
                  handleChange={(recDays) =>
                    setMondayChecked((prev) => ({ ...prev, ...recDays }))
                  }
                />
                <EventRepeatingCheckBox
                  data={tuesdayChecked}
                  handleChange={(recDays) =>
                    setTuesdayChecked((prev) => ({ ...prev, ...recDays }))
                  }
                />
                <EventRepeatingCheckBox
                  data={wednesdayChecked}
                  handleChange={(recDays) =>
                    setWednesdayChecked((prev) => ({ ...prev, ...recDays }))
                  }
                />
                <EventRepeatingCheckBox
                  data={thursdayChecked}
                  handleChange={(recDays) =>
                    setThursdayChecked((prev) => ({ ...prev, ...recDays }))
                  }
                />
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack direction="column" spacing={1}>
                <EventRepeatingCheckBox
                  data={fridayChecked}
                  handleChange={(recDays) =>
                    setFridayChecked((prev) => ({ ...prev, ...recDays }))
                  }
                />
                <EventRepeatingCheckBox
                  data={saturdayChecked}
                  handleChange={(recDays) =>
                    setSaturdayChecked((prev) => ({ ...prev, ...recDays }))
                  }
                />
                <EventRepeatingCheckBox
                  data={sundayChecked}
                  handleChange={(recDays) =>
                    setSundayChecked((prev) => ({ ...prev, ...recDays }))
                  }
                />
              </Stack>
            </Grid>
          </Grid>

          <Stack
            direction={isSmallScreen ? "column" : "row"}
            spacing={1}
            justifyContent="space-around"
            alignItems="center"
          >
            <MobileDatePicker
              value={repeatingStartDate}
              onChange={(v) => {
                setRepeatingStartDate(v || DateTime.now());
                setRepeatingEndDate((v || DateTime.now()).plus({ year: 1 }));
              }}
              minDate={DateTime.now()}
              format={"dd MMMM yyyy"}
              label={<Trans id="event_time_start_date_label">Start Date</Trans>}
              slots={{
                textField: (params) => (
                  <TextField variant="filled" {...params} />
                ),
              }}
              slotProps={{
                toolbar: {
                  className: "leaderBoard-datepicker",
                },
                textField: {
                  style: {
                    width: "100%",
                  },
                },
              }}
            />
            <MobileDatePicker
              value={repeatingEndDate}
              onChange={(v) => setRepeatingEndDate(v || DateTime.now())}
              minDate={repeatingStartDate}
              label={<Trans id="event_time_end_date_label">End Date</Trans>}
              format={"dd MMMM yyyy"}
              slots={{
                textField: (params) => (
                  <TextField variant="filled" {...params} />
                ),
              }}
              slotProps={{
                toolbar: {
                  className: "leaderBoard-datepicker",
                },
                textField: {
                  style: {
                    width: "100%",
                  },
                },
              }}
            />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
