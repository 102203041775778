import { Trans } from "@lingui/macro";
import CookieConsent from "react-cookie-consent";
import theme, { font } from "theme";

export const CookiesConsent = () => (
  <CookieConsent
    buttonText="I UNDERSTAND"
    buttonStyle={{
      width: 350,
      height: 50,
      fontSize: 24,
      color: theme.text.primary,
      background: theme.actions.secondary,
      fontFamily: font.adineue.cond,
    }}
    style={{
      fontFamily: font.adihaus.regular,
      paddingLeft: "10%",
      paddingRight: "10%",
    }}
  >
    <Trans>This website uses cookies to enhance the user experience.</Trans>
  </CookieConsent>
);
