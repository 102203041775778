import { PlayerPosition } from "@gamer/common/lib/models/user";

export const DEFENCE_LIST = [
  PlayerPosition.GK,
  PlayerPosition.LWB,
  PlayerPosition.RWB,
  PlayerPosition.CB,
  PlayerPosition.RB,
  PlayerPosition.LB,
];
export const MIDFIELD_LIST = [
  PlayerPosition.CM,
  PlayerPosition.RM,
  PlayerPosition.LM,
  PlayerPosition.CAM,
  PlayerPosition.CDM,
];
export const OFFENCE_LIST = [
  PlayerPosition.ST,
  PlayerPosition.RW,
  PlayerPosition.LW,
  PlayerPosition.CF,
  PlayerPosition.RF,
  PlayerPosition.LF,
];

export type PositionFilter = {
  positions: string[],
  includeNotDefined: boolean
};
