import { UseQueryResult } from "react-query";
import { IntervalStats } from "store/member-stats/member-stats.model";
import { getPlayerChartData } from "../pages/analytic/tabs/helpers";

export const CHARTS_COLORS = [
  "rgba(81, 137, 246, 1)",
  "rgba(215, 102, 255, 1)",
  "rgba(81, 246, 157, 1)",
  "rgba(255, 102, 102, 1)",
  "rgba(102, 255, 220, 1)"
];
const chartsNames = (count: number) => ({
  dist: {
    label: `Player ${count} Distance`,
    measure: "KM",
    yAxisID: "statOneAxis",
  },
  kick: {
    label: `Player ${count} Kicks Count`,
    measure: "COUNT",
    yAxisID: "statOneAxis",
  },
  avgSpeed: {
    label: `Player ${count} Avg Running Speed`,
    measure: "KM/H",
    yAxisID: "statOneAxis",
  },
  peakSpeed: {
    label: `Player ${count} Peak Speed`,
    measure: "KM/H",
    yAxisID: "statOneAxis",
  },
});
interface LabelData {
  time: number;
  str: string;
  intervalStart: number;
  intervalIndex: number;
}

type ChartDataItem = {
  x: string;
  y: number;
};

type DataSet = {
  label: string;
  yAxisID: string;
  data: ChartDataItem[];
  backgroundColor: string;
  borderColor: string;
};
const DEFAULT_DATA = {
  labels: [],
  datasets: [],
};
export function useGetChartsData(
  statsData: UseQueryResult<unknown, unknown>[],
) {
  let chartDistData: {
    labels: string[];
    datasets: DataSet[];
  } = DEFAULT_DATA;
  let chartKickData: {
    labels: string[];
    datasets: DataSet[];
  } = DEFAULT_DATA;
  let chartAvgSpeedData: {
    labels: string[];
    datasets: DataSet[];
  } = DEFAULT_DATA;
  let chartPeakSpeedData: {
    labels: string[];
    datasets: DataSet[];
  } = DEFAULT_DATA;
  const ballZonesData: number[][] = [];
  const avgRunZonesData: number[][] = [];
  const peakRunZonesData: number[][] = [];

  if (statsData?.length > 0) {
    const distSet: DataSet[] = [];
    const kickSet: DataSet[] = [];
    const avgSpeedSet: DataSet[] = [];
    const peakSpeedSet: DataSet[] = [];
    const allLabels = new Array<LabelData>();

    statsData.forEach((data, idx) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      if (data.isLoading || !data.data?.data) return;
      const names = chartsNames(idx + 1);
      const {
        ballZones,
        avgRunZones,
        peakRunZones,
        distItems,
        kickItems,
        avgSpeedItems,
        peakSpeedItems,
        labels,
      } = getPlayerChartData(data.data as unknown as IntervalStats);
      allLabels.push(...labels);
      ballZonesData[idx] = Object.values(ballZones);
      avgRunZonesData[idx] = Object.values(avgRunZones);
      peakRunZonesData[idx] = Object.values(peakRunZones);
      const distItemsData = distItems?.map((val, index) => ({
        x: labels[index]?.str || "",
        y: val,
      }));

      const kickItemsData = kickItems?.map((val, index) => ({
        x: labels[index]?.str || "",
        y: val,
      }));
      const avgSpeedItemsData = avgSpeedItems?.map((val, index) => ({
        x: labels[index]?.str || "",
        y: val,
      }));
      const peakSpeedItemsData = peakSpeedItems?.map((val, index) => ({
        x: labels[index]?.str || "",
        y: val,
      }));
      distSet.push({
        label: names.dist.label,
        yAxisID: names.dist.yAxisID,
        data: distItemsData,
        backgroundColor: CHARTS_COLORS[idx],
        borderColor: CHARTS_COLORS[idx],
      });
      kickSet.push({
        label: names.kick.label,
        yAxisID: names.kick.yAxisID,
        data: kickItemsData,
        backgroundColor: CHARTS_COLORS[idx],
        borderColor: CHARTS_COLORS[idx],
      });
      avgSpeedSet.push({
        label: names.avgSpeed.label,
        yAxisID: names.avgSpeed.yAxisID,
        data: avgSpeedItemsData,
        backgroundColor: CHARTS_COLORS[idx],
        borderColor: CHARTS_COLORS[idx],
      });
      peakSpeedSet.push({
        label: names.peakSpeed.label,
        yAxisID: names.peakSpeed.yAxisID,
        data: peakSpeedItemsData,
        backgroundColor: CHARTS_COLORS[idx],
        borderColor: CHARTS_COLORS[idx],
      });
    });
    chartDistData = {
      labels: [],
      datasets: distSet || [],
    };
    chartKickData = {
      labels: [],
      datasets: kickSet || [],
    };
    chartAvgSpeedData = {
      labels: [],
      datasets: avgSpeedSet,
    };
    chartPeakSpeedData = {
      labels: [],
      datasets: peakSpeedSet || [],
    };
  }

  return {
    ballZonesData,
    avgRunZonesData,
    peakRunZonesData,
    chartDistData,
    chartKickData,
    chartAvgSpeedData,
    chartPeakSpeedData,
  };
}
