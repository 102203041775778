import React from "react";
import styled from "styled-components";

const IconContainer = styled.div`
  background: #d9dcdd;
  border-radius: 100px;
  height: 32px;
`;

function UserIconDefault() {
  return (
    <IconContainer>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        fill="none"
        viewBox="0 0 64 64"
      >
        <path
          fill="#98989A"
          fillRule="evenodd"
          d="M41 24c0-4.962-4.038-9-9-9s-9 4.038-9 9 4.038 9 9 9 9-4.038 9-9zm2 0c0 6.064-4.934 11-11 11s-11-4.936-11-11 4.934-11 11-11 11 4.936 11 11zM17.544 40h28.91l5.896 9.418-1.696 1.062L45.346 42H18.652l-5.308 8.48-1.696-1.062L17.544 40z"
          clipRule="evenodd"
        ></path>
      </svg>
    </IconContainer>
  );
}

export default UserIconDefault;
