import { sendQuery } from "../../providers/auth";
import config from "../../config";
import { getHeaders, isError, send } from "services/commons.api";
import {
  IGetTeamMembersData,
  IPatchTeamMembershipData,
  ITeamMemberInfo,
} from "./team-members.model";
import clubTeamsApi from "../../config";

interface IAddTeamMemberParams {
  playerId: string;
  teamId: string;
}

export const addTeamMember =
  (token: string) =>
    ({ teamId, playerId }: IAddTeamMemberParams) =>
      sendQuery({
        apiEndpoint: config.API.TEAM_MEMBERS.ADD,
        urlParams: {
          teamId,
        },
        body: {
          playerId,
        },
        headers: getHeaders(token),
      });

interface IPatchTeamMember extends IAddTeamMemberParams {
  status: string;
}

export const updateTeamMember =
  (token: string) =>
    ({ teamId, playerId, status }: IPatchTeamMember) =>
      sendQuery({
        apiEndpoint: config.API.TEAM_MEMBERS.UPDATE,
        urlParams: {
          teamId,
          playerId,
        },
        body: {
          status,
        },
        headers: getHeaders(token),
      });

export const getTeamMembers = (teamId: string) => (token: string) => () =>
  send<IGetTeamMembersData>({
    apiEndpoint: clubTeamsApi.API.TEAM_MEMBERS.GET_ALL,
    urlParams: { teamId },
    headers: getHeaders(token),
  }).then((v) => {
    if (isError(v)) {
      throw v;
    }

    return v.data;
  });

export const patchTeamMembership =
  (token: string) =>
    (data: {
    teamId: string;
    playerId: string;
    patchReq: IPatchTeamMembershipData;
  }) =>
      send<ITeamMemberInfo>({
        apiEndpoint: clubTeamsApi.API.TEAM_MEMBERS.UPDATE,
        urlParams: { teamId: data.teamId, playerId: data.playerId },
        headers: getHeaders(token),
        body: data.patchReq,
      }).then((v) => {
        if (isError(v)) {
          throw v;
        }

        return v.data;
      });

export const deleteTeamMembership =
  (token: string) => (data: { teamId: string; playerId: string }) =>
    send({
      apiEndpoint: clubTeamsApi.API.TEAM_MEMBERS.DELETE_TEAM_MEMBERSHIP,
      urlParams: { teamId: data.teamId, playerId: data.playerId },
      headers: getHeaders(token),
    }).then((v) => {
      if (isError(v)) {
        throw v;
      }

      return v.data;
    });
