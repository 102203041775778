import { Box, Grid, Skeleton, Typography } from "@mui/material";
import {
  DEFENCE_LIST,
  MIDFIELD_LIST,
  OFFENCE_LIST,
} from "containers/position/position-models";
import Tilt from "react-parallax-tilt";
import { PlayerCardTeams } from "containers/user/player-card.container";
import { DateTime } from "luxon";
import { useAuthQuery } from "providers/auth";
import { useMemo } from "react";
import { getPlayerCards } from "store/player-card/player-card.query";
import { getTeamMembers } from "store/team-members/team-members.query";
import { IUserTeamInfo } from "store/user-teams/user-teams.model";
import { PlayerCard } from "store/player-card/player-card.model";

export const TeamCardsTab = (team: IUserTeamInfo) => {
  const { teamId } = team;
  const from = useMemo(() => DateTime.now().minus({ week: 4 }).toMillis(), []);
  const cardsQuery = useAuthQuery(
    ["cards", teamId, from],
    getPlayerCards({
      teamId,
      from,
    }),
    {
      enabled: Boolean(teamId),
    },
  );
  const teamMembersQuery = useAuthQuery(
    ["teamMembers", teamId],
    getTeamMembers(teamId),
  );

  if (!cardsQuery.data || !teamMembersQuery.data) {
    return <Skeleton height={400} />;
  }

  const bestCards = Object.values(cardsQuery.data.data.reduce((acc, card) => {
    const exist = acc[card.playerId];
    if (!exist || exist.total < card.total) {
      return { ...acc, [card.playerId]: card };
    }
    return acc;
  }, {} as Record<string, PlayerCard>));

  const offenceCards = bestCards.filter((c) =>
    OFFENCE_LIST.includes(c.position),
  );
  const midfieldCards = bestCards.filter((c) =>
    MIDFIELD_LIST.includes(c.position),
  );
  const defenceCards = bestCards.filter((c) =>
    DEFENCE_LIST.includes(c.position),
  );
  const noCard = teamMembersQuery.data.teamMembers.filter
  ((m) => m.status === "APPROVED" ).filter(
    (m) => !cardsQuery.data.data.find((c) => c.playerId === m.playerId),
  );

  const isOld = (card: PlayerCard) => DateTime.now().diff(DateTime.fromMillis(card.from)).weeks > 1;

  return (
    <Grid container spacing={1} direction="column">
      <Grid item hidden={!offenceCards.length}>
        <Grid container direction="column" spacing={2}>
          <Grid item width="fit-content">
            <Typography variant="h3">OFFENCE</Typography>
          </Grid>
          <Grid container direction="row" spacing={2} padding={2}>
            <Box
              sx={{
                display: "flex",
                gap: 1,
                py: 1,
                overflow: "auto",
                width: 328 * 3,
                height: 535,
                scrollSnapType: "x mandatory",
                "& > *": {
                  scrollSnapAlign: "center",
                },
                "::-webkit-scrollbar": { display: "none" },
                padding: 2,
              }}
            >
              {offenceCards.map((card) => (
                <Grid item key={card.id}>
                  <Tilt>
                    <PlayerCardTeams
                      card={card}
                      team={team}
                      members={teamMembersQuery.data.teamMembers}
                      date={card.from}
                      old={isOld(card)}
                    />
                  </Tilt>
                </Grid>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item hidden={!midfieldCards.length}>
        <Grid container direction="column" spacing={2} padding={2}>
          <Grid item width="fit-content">
            <Typography variant="h3">MIDFIELD</Typography>
          </Grid>
          <Grid item>
            <Grid container direction="row" spacing={2}>
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  py: 1,
                  overflow: "auto",
                  width: 328 * 3,
                  height: 535,
                  scrollSnapType: "x mandatory",
                  "& > *": {
                    scrollSnapAlign: "center",
                  },
                  "::-webkit-scrollbar": { display: "none" },
                  padding: 2,
                }}
              >
                {midfieldCards.map((card) => (
                  <Grid item key={card.id}>
                    <Tilt>
                      <PlayerCardTeams
                        card={card}
                        team={team}
                        members={teamMembersQuery.data.teamMembers}
                        date={card.from}
                        old={isOld(card)}
                      />
                    </Tilt>
                  </Grid>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item hidden={!defenceCards.length}>
        <Grid container direction="column" spacing={2} padding={2}>
          <Grid item width="fit-content">
            <Typography variant="h3">DEFENCE</Typography>
          </Grid>

          <Grid item>
            <Grid container direction="row" spacing={2}>
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  py: 1,
                  overflow: "auto",
                  width: 328 * 3,
                  height: 535,
                  scrollSnapType: "x mandatory",
                  "& > *": {
                    scrollSnapAlign: "center",
                  },
                  "::-webkit-scrollbar": { display: "none" },
                  padding: 2,
                }}
              >
                {defenceCards.map((card) => (
                  <Grid item key={card.id}>
                    <Tilt>
                      <PlayerCardTeams
                        card={card}
                        team={team}
                        members={teamMembersQuery.data.teamMembers}
                        date={card.from}
                        old={isOld(card)}
                      />
                    </Tilt>
                  </Grid>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item hidden={!noCard.length}>
        <Grid container direction="column" spacing={2} padding={2}>
          <Grid item width="fit-content">
            <Typography variant="h3">NO CARD</Typography>
          </Grid>
          <Grid item>
            <Grid container direction="row" spacing={2}>
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  py: 1,
                  overflow: "auto",
                  width: 328 * 3,
                  height: 535,
                  scrollSnapType: "x mandatory",
                  "& > *": {
                    scrollSnapAlign: "center",
                  },
                  "::-webkit-scrollbar": { display: "none" },
                  padding: 2,
                }}
              >
                {noCard.map((m) => (
                  <Grid item key={m.playerId}>
                    <Tilt>
                      <PlayerCardTeams
                        card={{
                          playerId: m.playerId,
                          total: 0,
                          DEF: 0,
                          DRI: 0,
                          PAC: 0,
                          PAS: 0,
                          PHY: 0,
                          SHO: 0,
                          position: "" as any,
                        }}
                        team={team}
                        old={true}
                        members={teamMembersQuery.data.teamMembers}
                      />
                    </Tilt>
                  </Grid>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
