import React, { ReactNode, useContext, useEffect, useMemo } from "react";
import Grid from "@mui/material/Grid";
import { useHistory } from "react-router-dom";
import Typography from "@mui/material/Typography";
import MoodIcon from "@mui/icons-material/Mood";
import SpeedIcon from "@mui/icons-material/Speed";
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Paper,
  Skeleton,
  Tooltip,
} from "@mui/material";

import { TeamMemberRole } from "store/team-members/team-members.model";
import { useAuthQuery } from "providers/auth";
import Tilt from "react-parallax-tilt";
import { NavigationContext } from "providers/navigation.provider";
import { getPlayerCards } from "store/player-card/player-card.query";
import { getTeamMembers } from "store/team-members/team-members.query";
import { PlayerCardTeams } from "containers/user/player-card.container";
import { DateTime } from "luxon";
import { PlayerCard } from "store/player-card/player-card.model";
import { getTeamEventsWithParticipation } from "store/events/events.query";
import {
  EventFeedback,
  TeamEvent,
  TeamEventUserSpecificWithParticipants,
} from "store/events/events.model";
import theme, { muiTheme } from "theme";
import { EventRecord } from "containers/timeline/components/event-record";
import { MatchScore } from "containers/timeline/event-details.tab";
import { IconByEventType } from "containers/timeline/components/event-type.icon";
import { getTeamLeaderboard } from "store/team-leaderboard/leaderboard.query";
import MetricsDistance from "components/icons/MetricsDistance";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import MetricsKickCount from "components/icons/MetricsKickCount";
import CloudSyncOutlinedIcon from "@mui/icons-material/CloudSyncOutlined";
import { EventType } from "@gamer/common/lib/models/events";
import { PlayerAvatar, PlayerName } from "components/avatar.component";
import { LinePlot, ChartContainer, LineChart } from "@mui/x-charts";
import { TeamTrophie } from "containers/user/user-trophies.tab";

export const StatPaperCard = ({
  title,
  value,
  icon,
  onClick,
}: {
  title: string;
  value: string;
  icon: ReactNode;
  onClick?: () => void;
}) => (
  <Paper>
    <IconButton
      // style={{ backgroundColor: theme.background.paper }}
      onClick={onClick}
    >
      <Grid
        container
        direction="row"
        width={225}
        height={75}
        justifyContent="space-around"
        alignItems="center"
      >
        <Grid item>{icon}</Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <Typography variant="body2">{title}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" fontStyle="bold">
                {value}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </IconButton>
  </Paper>
);

export const Dashboard = () => {
  const from = useMemo(() => DateTime.now().minus({ days: 14 }).toMillis(), []);
  const to = useMemo(() => DateTime.now().plus({ day: 14 }).toMillis(), []);
  const now = useMemo(() => DateTime.now().toMillis(), []);
  const currentTeam = useContext(NavigationContext).currentTeam;
  const isAdministarativeRole = currentTeam.role !== TeamMemberRole.PLAYER;
  const cardsQuery = useAuthQuery(
    ["cards", currentTeam.teamId, currentTeam.playerId, from],
    isAdministarativeRole
      ? getPlayerCards({
          teamId: currentTeam.teamId,
          from,
        })
      : getPlayerCards({
          teamId: currentTeam.teamId,
          playerId: currentTeam.playerId,
          from,
        }),
    {
      enabled: !!currentTeam.teamId,
    },
  );
  const teamMembersQuery = useAuthQuery(
    ["teamMembers", currentTeam.teamId],
    getTeamMembers(currentTeam.teamId),
  );
  const eventsQuery = useAuthQuery(
    ["events", "withParticipation", currentTeam.teamId, from, to],
    getTeamEventsWithParticipation({
      teamId: currentTeam.teamId,
      from,
      to,
    }),
    { enabled: Boolean(currentTeam.teamId && to && from) },
  );
  const leaderboardQuery = useAuthQuery(
    ["leaderboard", currentTeam.teamId, from, to],
    getTeamLeaderboard({
      teamId: currentTeam.teamId,
      from: from,
      to: now,
    }),
    { enabled: Boolean(currentTeam.teamId) },
  );
  const [featuredCard, setFeaturedCard] = React.useState<
    PlayerCard | undefined
  >();
  const [featuredFeedback, setFeaturedFeedback] = React.useState<
    | (EventFeedback & {
        playerName: string;
        playerAvatar: string;
        eventId: string;
      })
    | undefined
  >();
  const [upcomminEvents, setUpcommingEvents] = React.useState<TeamEvent[]>([]);
  const [lastMatch, setLastMatch] = React.useState<TeamEvent | undefined>(
    undefined,
  );
  const [lastCoachReview, setLastCoachReview] = React.useState<
    TeamEvent | undefined
  >(undefined);
  const [teamStats, setTeamStats] = React.useState<
    | Record<
        | "totalDistance"
        | "countKick"
        | "playersParticipated"
        | "feedbacksProvided"
        | "eventsCount"
        | "playersCount",
        number
      >
    | undefined
  >();
  const [lastEvent, setLastEvent] = React.useState<
    TeamEventUserSpecificWithParticipants | undefined
  >();
  const [lastestEvents, setLastestEvents] = React.useState<
    TeamEventUserSpecificWithParticipants[]
  >([]);

  const { teamId, banner } = currentTeam;
  const history = useHistory();
  const isLoading =
    cardsQuery.isLoading ||
    teamMembersQuery.isLoading ||
    eventsQuery.isLoading ||
    leaderboardQuery.isLoading;

  useEffect(() => {
    setFeaturedCard(undefined);
    setFeaturedFeedback(undefined);
    setUpcommingEvents([]);
    setLastMatch(undefined);
    setLastCoachReview(undefined);
    setTeamStats(undefined);
    setLastEvent(undefined);
    setLastestEvents([]);
  }, [currentTeam]);

  useEffect(() => {
    if (!featuredCard) {
      if (
        teamMembersQuery.data &&
        cardsQuery.data &&
        cardsQuery.data.data.length > 0
      ) {
        const topCard = cardsQuery.data?.data.sort(
          (a, b) => b.total - a.total,
        )[0];
        setFeaturedCard(topCard);
      }
    }
  }, [cardsQuery.data, teamMembersQuery.data, currentTeam, featuredCard]);

  useEffect(() => {
    if (!featuredFeedback) {
      if (eventsQuery.data && teamMembersQuery.data) {
        const feedbacks = eventsQuery.data.data
          .filter((e) => e.endTime < DateTime.now().toMillis())
          .map((event) =>
            event.participants.map((p) => ({
              ...p.feedback,
              eventId: event.eventId,
              updatedAt: p.updatedAt,
              playerId: p.playerId,
            })),
          );
        const recentFeedback = feedbacks
          .flat()
          .filter((f) => f.comment && f.intensityRating && f.satisfactionRating)
          .sort((a, b) => (b.updatedAt || 0) - (a.updatedAt || 0))[0];

        if (recentFeedback) {
          const player = teamMembersQuery.data.teamMembers.find(
            (m) => m.playerId === recentFeedback.playerId,
          );

          setFeaturedFeedback({
            ...recentFeedback,
            playerName: `${player?.memberInfo?.firstName} ${player?.memberInfo?.lastName}`,
            playerAvatar: player?.memberInfo?.avatar || "",
          });
        }
      }
    }

    if (!upcomminEvents.length) {
      if (eventsQuery.data) {
        const events = eventsQuery.data.data
          .filter((e) => e.endTime > DateTime.now().toMillis())
          .sort((a, b) => a.endTime - b.endTime)
          .slice(0, 3);
        setUpcommingEvents(events);
      }
    }

    if (!lastMatch) {
      if (eventsQuery.data) {
        const match = eventsQuery.data.data
          .filter((e) => e.endTime < DateTime.now().toMillis())
          .filter((e) => e.type === EventType.MATCH)
          .sort((a, b) => b.endTime - a.endTime)[0];
        setLastMatch(match);
      }
    }

    if (!lastCoachReview) {
      if (eventsQuery.data) {
        const eventWithReview = eventsQuery.data.data
          .filter((e) => e.coachReview)
          .filter((e) => e.endTime < DateTime.now().toMillis())[0];
        setLastCoachReview(eventWithReview);
      }
    }

    if (!lastEvent) {
      if (eventsQuery.data) {
        const last = eventsQuery.data.data
          .filter((e) => e.endTime < DateTime.now().toMillis())
          .sort((a, b) => b.endTime - a.endTime)[0];
        setLastEvent(last);
      }
    }

    if (!lastestEvents.length) {
      if (eventsQuery.data) {
        const lastest = eventsQuery.data.data
          .filter((e) => e.endTime < DateTime.now().toMillis())
          .sort((a, b) => b.endTime - a.endTime)
          .slice(0, 5);
        setLastestEvents(lastest);
      }
    }
  }, [eventsQuery.data, teamMembersQuery.data, currentTeam]);

  useEffect(() => {
    if (
      leaderboardQuery.data &&
      eventsQuery.data &&
      teamMembersQuery.data &&
      !teamStats
    ) {
      setTeamStats({
        totalDistance: leaderboardQuery.data.metrics.totalDistance.reduce(
          (a, v) => a + Number(v.value),
          0,
        ),
        countKick: leaderboardQuery.data.metrics.countKicks.reduce(
          (a, v) => a + Number(v.value),
          0,
        ),
        playersParticipated: Math.max(
          ...Object.keys(leaderboardQuery.data.metrics).map(
            (k) => leaderboardQuery.data.metrics[k].length,
          ),
        ),
        feedbacksProvided: eventsQuery.data.data
          .map((e) => e.participants.filter((p) => p.feedback).length)
          .reduce((a, v) => a + v, 0),
        eventsCount: eventsQuery.data.data.filter(
          (e) => e.startTime < Date.now(),
        ).length,
        playersCount: teamMembersQuery.data.teamMembers.filter(
          (p) => p.role === TeamMemberRole.PLAYER,
        ).length,
      });
    }
  }, [leaderboardQuery.data, eventsQuery.data, currentTeam, teamStats]);

  const goToTeamMembers = () => {
    history.push(`/teams/${teamId}/settings?tabSelected=MEMBERS`);
  };

  const goToEvent = (eventId?: string) => {
    history.push(
      eventId
        ? `/teams/${teamId}/timeline?eventId=${eventId}`
        : `/teams/${teamId}/timeline`,
    );
  };

  const goToLeaderboard = () => {
    history.push(`/teams/${teamId}/leaderboard?from=${from}&to=${Date.now()}`);
  };

  if (isLoading) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        spacing={1}
        padding={2}
      >
        <Grid item xs>
          <Skeleton variant="rectangular" height={500} />
        </Grid>
        <Grid item xs>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Skeleton variant="rectangular" height={300} />
            </Grid>
            <Grid item>
              <Skeleton variant="rectangular" height={300} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Skeleton variant="rectangular" height={200} />
            </Grid>
            <Grid item>
              <Skeleton variant="rectangular" height={400} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs>
          <Skeleton variant="rectangular" height={1000} />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container direction="column" style={{ margin: 10 }}>
      <Grid item>
        <Grid container direction="row" alignItems="start" spacing={2}>
          <Grid item id="first-row">
            <Grid container direction="column" alignItems="start" spacing={2}>
              {featuredCard ? (
                <Grid item>
                  <Grid
                    container
                    direction="column"
                    alignItems="start"
                    spacing={2}
                  >
                    <Grid item width="fit-content">
                      {isAdministarativeRole ? (
                        <Tooltip
                          title={"The best player card over the two weeks"}
                          arrow
                        >
                          <Typography variant="h3">Featured Player</Typography>
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title={"Your best player card over the two weeks"}
                          arrow
                        >
                          <Typography variant="h3">
                            Your best Player card
                          </Typography>
                        </Tooltip>
                      )}
                    </Grid>
                    <Grid item>
                      <Button
                        onClick={() => goToEvent(featuredCard.eventId)}
                        style={{ padding: 0 }}
                      >
                        <Tilt
                          glareEnable={true}
                          glareMaxOpacity={0.8}
                          glareColor="#ffffff"
                          glarePosition="bottom"
                          glareBorderRadius="20px"
                        >
                          <PlayerCardTeams
                            team={currentTeam}
                            members={teamMembersQuery.data?.teamMembers || []}
                            card={featuredCard}
                            date={featuredCard.from}
                            trophy
                          />
                        </Tilt>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
              <Grid item width={370}>
                <TeamTrophie limit={4} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item id="second-row">
            <Grid container direction="column" spacing={2} width={370}>
              {featuredFeedback ? (
                <Grid item>
                  <Grid container direction="column" spacing={2}>
                    <Grid item width="fit-content">
                      <Tooltip
                        title={"The latest feedback that was left by a player"}
                        arrow
                      >
                        <Typography variant="h3">Latest Feedback</Typography>
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Card>
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            height="145"
                            image={featuredFeedback?.playerAvatar || banner}
                          />
                          <CardContent>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                            >
                              {featuredFeedback.playerName}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ color: "text.secondary" }}
                            >
                              {featuredFeedback.comment}
                            </Typography>
                            <Grid container>
                              <Grid item></Grid>
                              <Grid item></Grid>
                            </Grid>
                          </CardContent>
                        </CardActionArea>
                        <CardActions>
                          <Button
                            size="small"
                            color="primary"
                            fullWidth
                            onClick={() =>
                              history.push(
                                `/teams/${teamId}/timeline?eventId=${featuredFeedback.eventId}`,
                              )
                            }
                          >
                            Open Event
                          </Button>
                          <Grid
                            container
                            spacing={2}
                            direction="row"
                            justifyContent="flex-end"
                          >
                            <Grid item>
                              <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={1}
                              >
                                <Grid item>
                                  <SpeedIcon
                                    fontSize="large"
                                    style={{
                                      color: muiTheme.palette.primary.main,
                                    }}
                                  />
                                </Grid>
                                <Grid item>
                                  <Typography
                                    style={{
                                      fontSize: 20,
                                    }}
                                  >
                                    {featuredFeedback.intensityRating?.toFixed(
                                      1,
                                    )}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item>
                              <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={1}
                              >
                                <Grid item>
                                  <MoodIcon
                                    fontSize="large"
                                    style={{
                                      color: muiTheme.palette.secondary.main,
                                    }}
                                  />
                                </Grid>
                                <Grid item>
                                  <Typography
                                    style={{
                                      fontSize: 20,
                                    }}
                                  >
                                    {featuredFeedback?.satisfactionRating?.toFixed(
                                      1,
                                    )}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </CardActions>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
              {lastCoachReview ? (
                <Grid item>
                  <Grid container direction="column" spacing={2}>
                    <Grid item width="fit-content">
                      <Tooltip
                        title={"The latest review that was left by a coach"}
                        arrow
                      >
                        <Typography variant="h3">Last Coach Review</Typography>
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Paper>
                        <Grid container direction="column" padding={3}>
                          <Grid item>
                            <Grid
                              container
                              direction="row"
                              alignItems="center"
                              spacing={1}
                            >
                              <Grid item>
                                <IconByEventType
                                  type={lastCoachReview.type}
                                  size={35}
                                />
                              </Grid>
                              <Grid item>
                                <Button
                                  onClick={() =>
                                    goToEvent(lastCoachReview.eventId)
                                  }
                                  style={{ padding: 5 }}
                                >
                                  <Typography variant="h5" noWrap>
                                    {lastCoachReview.title}
                                  </Typography>
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="body2"
                              style={{ fontStyle: "italic" }}
                            >
                              "{lastCoachReview.coachReview}"
                            </Typography>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
          <Grid item id="third-row">
            <Grid container direction="column" spacing={2}>
              {lastMatch ? (
                <Grid item>
                  <Grid container direction="column" spacing={2}>
                    <Grid item width="fit-content">
                      <Tooltip title={"The last match score"} arrow>
                        <Typography variant="h3">Last Match</Typography>
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Button
                        onClick={() => goToEvent(lastMatch.eventId)}
                        style={{ padding: 0 }}
                      >
                        <MatchScore
                          event={lastMatch}
                          currentTeam={currentTeam}
                        />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                ""
              )}

              {upcomminEvents.length ? (
                <Grid item>
                  <Grid container direction="column" spacing={2}>
                    <Grid item width="fit-content">
                      <Typography variant="h3">Upcomming Events</Typography>
                    </Grid>
                    <Grid item>
                      <Grid container direction="column" spacing={1}>
                        {upcomminEvents.map((event) => (
                          <Grid item>
                            <Button
                              style={{ padding: 0 }}
                              onClick={() => goToEvent(event.eventId)}
                            >
                              <EventRecord
                                active={DateTime.now().hasSame(
                                  DateTime.fromMillis(event.startTime),
                                  "day",
                                )}
                                {...event}
                              />
                            </Button>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
              {lastestEvents.length ? (
                <Grid item width={380}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item width="fit-content">
                      <Typography variant="h3">Feedbacks Chart</Typography>
                    </Grid>
                    <Grid item>
                      <Paper>
                        <LineChart
                          series={[
                            {
                              data: lastestEvents.map(
                                (v) =>
                                  v.participants.filter((p) => p.feedback)
                                    .length,
                              ),
                              color: "#51F69D",
                              label: "Feedbacks Count",
                              yAxisId: "leftAxis",
                            },
                            {
                              data: lastestEvents.map(
                                (v) => v.intensityAverage,
                              ),
                              color: "#3960AC",
                              label: "Intensity Avg.",
                              yAxisId: "rightAxis",
                            },
                            {
                              data: lastestEvents.map(
                                (v) => v.satisfactionAverage,
                              ),
                              color: "#FFB700",
                              label: "Happiness Avg.",
                              yAxisId: "rightAxis",
                            },
                          ]}
                          leftAxis={null}
                          bottomAxis={null}
                          xAxis={[
                            {
                              scaleType: "point",
                              data: lastestEvents.map(
                                (v) =>
                                  DateTime.fromMillis(v.startTime).toFormat(
                                    "dd LLL",
                                  ) +
                                  " " +
                                  v.title,
                              ),
                            },
                          ]}
                          yAxis={[{ id: "leftAxis" }, { id: "rightAxis" }]}
                          height={250}
                          slotProps={{
                            legend: {
                              direction: "row",
                              position: {
                                vertical: "top",
                                horizontal: "middle",
                              },
                              padding: 0,
                              labelStyle: {
                                fontSize: 12,
                              },
                            },
                          }}
                        />
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
          <Grid item id="fourth-row">
            <Grid container direction="column" spacing={2}>
              {teamStats ? (
                <Grid item>
                  <Grid container direction="column" spacing={2}>
                    <Grid item width="fit-content">
                      <Tooltip title={"The stats over last 2 weeks"} arrow>
                        <Typography variant="h3">Team Stats</Typography>
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Grid container direction="column" spacing={1}>
                        <Grid item>
                          <Paper
                            style={{
                              padding: 5,
                              backgroundColor: theme.background.paper,
                            }}
                          >
                            <div
                              style={{
                                background: `url(${banner}) no-repeat`,
                                backgroundPosition: "center",
                                backgroundSize: "480px 220px",
                                borderRadius: 5,
                                height: 220,
                              }}
                            />
                          </Paper>
                        </Grid>
                        <Grid item>
                          <Grid container spacing={1}>
                            <Grid item>
                              <StatPaperCard
                                title="Total Distance"
                                value={`${(
                                  teamStats.totalDistance / 1000
                                ).toFixed(2)} KM`}
                                onClick={goToLeaderboard}
                                icon={
                                  <MetricsDistance
                                    fontSize="large"
                                    style={{ fontSize: 70 }}
                                  />
                                }
                              />
                            </Grid>
                            <Grid item>
                              <StatPaperCard
                                title="Kicks Perfomed"
                                value={`${teamStats.countKick}`}
                                onClick={goToLeaderboard}
                                icon={
                                  <MetricsKickCount
                                    fontSize="large"
                                    style={{ fontSize: 70 }}
                                  />
                                }
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Grid container spacing={1}>
                            <Grid item>
                              <StatPaperCard
                                title="Finished Events"
                                value={`${teamStats.eventsCount}`}
                                onClick={goToEvent}
                                icon={
                                  <EventAvailableOutlinedIcon
                                    fontSize="large"
                                    style={{ fontSize: 50, margin: 10 }}
                                  />
                                }
                              />
                            </Grid>
                            <Grid item>
                              <StatPaperCard
                                title="Feedback Provided"
                                value={`${teamStats.feedbacksProvided}`}
                                onClick={goToEvent}
                                icon={
                                  <MessageOutlinedIcon
                                    fontSize="large"
                                    style={{ fontSize: 50, margin: 10 }}
                                  />
                                }
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Grid container spacing={1}>
                            <Grid item>
                              <StatPaperCard
                                title="Players Synced"
                                value={`${teamStats.playersParticipated} out of ${teamStats.playersCount}`}
                                onClick={goToTeamMembers}
                                icon={
                                  <CloudSyncOutlinedIcon
                                    fontSize="large"
                                    style={{ fontSize: 70 }}
                                  />
                                }
                              />
                            </Grid>
                            <Grid item>
                              <Button
                                style={{ height: 90, width: 240, fontSize: 20 }}
                                variant="contained"
                                onClick={goToTeamMembers}
                              >
                                MANAGE TEAM
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
              {lastEvent ? (
                <Grid item width={500}>
                  <Grid container spacing={2}>
                    <Grid item width="fit-content">
                      <Typography variant="h3">
                        Last Event Pending Feedbacks
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Grid container direction="row" spacing={1}>
                        {lastEvent.participants
                          .filter((v) => !v.feedback)
                          .filter(
                            (p) =>
                              teamMembersQuery.data?.teamMembers.find(
                                (u) => u.playerId === p.playerId,
                              )?.role === TeamMemberRole.PLAYER,
                          )
                          .map((p) => (
                            <Grid item>
                              <Paper elevation={10}>
                                <Grid
                                  container
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                  padding={1}
                                >
                                  <Grid item>
                                    <PlayerAvatar
                                      showPosition
                                      playerId={p.playerId}
                                      teamId={p.teamId}
                                    />
                                  </Grid>
                                  <Grid item>
                                    <PlayerName
                                      playerId={p.playerId}
                                      teamId={p.teamId}
                                      variant="body2"
                                    />
                                  </Grid>
                                </Grid>
                              </Paper>
                            </Grid>
                          ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
