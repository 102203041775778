import { useState, useEffect, useContext } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import theme from "theme";
import { queryClient, useAuthMutation } from "providers/auth";
import { DateTime } from "luxon";
import { updateEvent } from "store/events/events.query";
import { TeamEvent } from "store/events/events.model";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import { Trans } from "@lingui/macro";
import { useAnalytics } from "use-analytics";
import { NavigationContext } from "providers/navigation.provider";

interface TeamModalProps {
  event: TeamEvent;
  open: boolean;
  onClose: () => void;
}

export const EventCoachReviewModal = (props: TeamModalProps) => {
  const { selectedLocale } = useContext(NavigationContext);
  const analytics = useAnalytics();
  const { event } = props;
  const mutation = useAuthMutation(
    "updateEvent",
    updateEvent(props.event.eventId),
  );
  const { open, onClose } = props;
  const [review, setReview] = useState(event.coachReview);

  useEffect(() => {
    setReview(event.coachReview);
  }, [event.eventId]);

  useEffect(() => {
    if (mutation.isSuccess) {
      queryClient.invalidateQueries(["event", props.event.eventId]);
      onClose();
    }
  }, [mutation.isSuccess]);

  if (mutation.isError) {
    toast.error(mutation.error.data.detail);
    onClose();
  }

  const onSubmit = () => {
    const data = {
      coachReview: review,
    };
    analytics.track("event-coach-review-submitted", { review });

    mutation.mutate(data);
  };

  return (
    <Dialog
      maxWidth={"sm"}
      open={open}
      onClose={onClose}
      scroll="paper"
      keepMounted={open}
      sx={{
        "& .MuiPaper-root": {
          width: "100%",
          background: theme.background.actions,
        },
      }}
    >
      <DialogTitle id="form-dialog-title">
        <Stack direction="row" justifyContent="space-between">
          <Stack>
            <Typography variant="h5">
              {props.event.coachReview ? (
                <Trans id="edit_coach_review_title">EDIT COACH REVIEW</Trans>
              ) : (
                <Trans id="add_coach_review_title">ADD COACH REVIEW</Trans>
              )}{" "}
            </Typography>
            <Typography
              variant="body2"
              style={{ fontSize: 13 }}
              color="textSecondary"
            >
              {DateTime.fromMillis(props.event.startTime)
                .setLocale(selectedLocale)
                .toFormat("dd LLLL yyyy")}
            </Typography>
          </Stack>
          <IconButton sx={{ width: "50px", height: "50px" }} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ width: "100%" }} dividers>
        <TextField
          id="filled-multiline-flexible"
          label={<Trans id="add_coach_review_placeholder">Review</Trans>}
          minRows={5}
          multiline
          sx={{ width: "100%" }}
          variant="filled"
          value={review}
          onChange={(e) => setReview(e.target.value)}
        />
      </DialogContent>
      <DialogActions sx={{ p: "16px 24px" }}>
        <Button variant="outlined" onClick={onClose}>
          <Trans id="cancel_cta">CANCEL</Trans>
        </Button>
        <Button
          variant="contained"
          disabled={Boolean(mutation.isLoading)}
          onClick={onSubmit}
          sx={{ ml: "16px !important" }}
        >
          {mutation.isLoading ? (
            <CircularProgress size={20} />
          ) : (
            <Trans id="save_cta">Save</Trans>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
