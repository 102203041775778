import {
  Avatar,
  AvatarProps,
  Badge,
  Typography,
  TypographyProps,
} from "@mui/material";
import MoodIcon from "@mui/icons-material/Mood";
import { Skeleton } from "@mui/material";
import theme from "../theme";
import TeamDefaultIcon from "./icons/TeamDefaultIcon";
import { useAuthQuery } from "providers/auth";
import { getTeamMembers } from "store/team-members/team-members.query";
import { IUserTeamInfo } from "store/user-teams/user-teams.model";
import { Trans } from "@lingui/macro";
import TooltipCustom from "./tooltip.component";
import { PlayerPositionFullTitle } from "store/team-members/team-members.model";

const COLORS = [
  "#F46036",
  "#F46036",
  "#BBF247",
  "#98DFEA",
  "#07BEB8",
  "#D3C4E3",
  "#EDF060",
];

export const getAvatarLetters = (name = "GMR") => {
  const capitals = name.replace(/[^A-Z]+/g, "").slice(0, 3);

  if (capitals.length) {
    return capitals;
  }

  const first = name
    .split(" ")
    .map((v) => v[0])
    .join("")
    .toUpperCase();

  if (first.length) {
    return first;
  }

  return name;
};

export const stringToColor = (str = "GMR") => {
  const hash = str
    .split("")
    .reduce((hash, v) => v.charCodeAt(0) + ((hash << 5) - hash), 0);
  let color = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    const current = "00" + value.toString(16);
    color += current.substring(current.length - 2);
  }
  return color;
};

export const stringToPresetColor = (str = "GMR") => {
  const index =
    str.split("").reduce((acc, v) => (acc += v.charCodeAt(0)), 0) %
    COLORS.length;

  return COLORS[index];
};

export const textColor = (color: string) => {
  const hexColor = color.replace("#", "");
  const r = parseInt(hexColor.substring(0, 2), 16);
  const g = parseInt(hexColor.substring(2, 2), 16);
  const b = parseInt(hexColor.substring(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  return yiq >= 128 ? "black" : "white";
};

interface IProps extends AvatarProps {
  teamInfo?: IUserTeamInfo;
  default?: boolean;
}

export const TeamAvatar = ({ teamInfo, ...props }: IProps) => {
  if (props.default) {
    return (
      <Avatar
        {...props}
        alt={"Team Avatar"}
        style={{ backgroundColor: "white", ...props.style }}
      >
        <TeamDefaultIcon />
      </Avatar>
    );
  }

  if (!teamInfo) {
    return <Skeleton {...props} variant="circular" />;
  }

  if (teamInfo.avatar) {
    return (
      <Avatar
        {...props}
        src={teamInfo.avatar}
        alt={teamInfo.teamName || "Team Avatar"}
      ></Avatar>
    );
  }

  return (
    <Avatar
      {...props}
      alt={"Team Avatar"}
      style={{ backgroundColor: "white", ...props.style }}
    >
      <TeamDefaultIcon />
    </Avatar>
  );
};

interface PlayerNameProps extends TypographyProps {
  playerId?: string;
  teamId?: string;
}

export const PlayerName = ({ teamId, playerId, ...props }: PlayerNameProps) => {
  const membersQuery = useAuthQuery(
    ["teamMembers", teamId],
    getTeamMembers(teamId || ""),
  );

  if (!teamId || !playerId) {
    return (
      <Skeleton
        style={{ width: 100, ...props.style }}
        variant="rectangular"
      ></Skeleton>
    );
  }

  if (membersQuery.isLoading) {
    return (
      <Skeleton
        style={{ width: 100, ...props.style }}
        variant="rectangular"
      ></Skeleton>
    );
  }

  const member = membersQuery.data?.teamMembers.find(
    (v) => v.playerId === playerId,
  );
  const name =
    member && member.memberInfo
      ? `${member.memberInfo.firstName} ${member.memberInfo.lastName}`
      : "";

  if (membersQuery.isError || !name) {
    return (
      <Typography {...props}>
        <Trans>Name unavailable</Trans>
      </Typography>
    );
  }

  return <Typography {...props}>{name}</Typography>;
};

interface PlayerAvatarProps extends AvatarProps {
  playerId?: string;
  teamId?: string;
  showPosition?: boolean;
  disabled?: boolean;
}

export const PlayerAvatar = ({
  teamId,
  playerId,
  disabled,
  ...props
}: PlayerAvatarProps) => {
  const teamMembersQuery = useAuthQuery(
    ["teamMembers", teamId],
    getTeamMembers(teamId || ""),
    { enabled: Boolean(teamId) },
  );

  if (!teamId || !playerId) {
    return <Skeleton {...props} variant="circular"></Skeleton>;
  }

  if (teamMembersQuery.isLoading) {
    return <Skeleton {...props} variant="circular"></Skeleton>;
  }

  const background = stringToPresetColor(playerId);
  const color = textColor(background);

  if (teamMembersQuery.data) {
    const member = teamMembersQuery.data.teamMembers.find(
      ({ memberInfo, playerId: plrId }) =>
        memberInfo ? plrId === playerId : false,
    );

    if (member) {
      if (member.memberInfo?.avatar) {
        return props.showPosition && member.position ? (
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            badgeContent={
              <TooltipCustom title={PlayerPositionFullTitle[member.position]}>
                <Avatar
                  style={{
                    width: 18,
                    height: 18,
                    backgroundColor: theme.background.primary,
                    color: theme.text.primary,
                    fontSize: 10,
                  }}
                >
                  {member.position}
                </Avatar>
              </TooltipCustom>
            }
          >
            <Avatar
              {...props}
              style={{
                ...props.style,
                filter: disabled ? "grayscale(100%)" : "none",
              }}
              src={member.memberInfo?.avatar || undefined}
            />
          </Badge>
        ) : (
          <Avatar {...props}               style={{
            ...props.style,
            filter: disabled ? "grayscale(100%)" : "none",
          }} src={member.memberInfo?.avatar || undefined} />
        );
      } else {
        return props.showPosition && member.position ? (
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            badgeContent={
              <TooltipCustom title={PlayerPositionFullTitle[member.position]}>
                <Avatar
                  style={{
                    width: 18,
                    height: 18,
                    backgroundColor: theme.background.primary,
                    color: theme.text.primary,
                    fontSize: 10,
                  }}
                >
                  {member.position}
                </Avatar>
              </TooltipCustom>
            }
          >
            <Avatar
              {...props}
              style={{
                background,
                fontFamily: theme.font.light,
                filter: disabled ? "grayscale(100%)" : "none",
                color,
                ...props.style,
              }}
            >
              {member.memberInfo?.firstName?.slice(0, 1)}
              {member.memberInfo?.lastName?.slice(0, 1)}
            </Avatar>
          </Badge>
        ) : (
          <Avatar
            {...props}
            style={{
              background,
              fontFamily: theme.font.light,
              filter: disabled ? "grayscale(100%)" : "none",
              color,
              ...props.style,
            }}
          >
            {member.memberInfo?.firstName?.slice(0, 1)}
            {member.memberInfo?.lastName?.slice(0, 1)}
          </Avatar>
        );
      }
    }
  }

  return (
    <Avatar
      {...props}
      style={{
        background,
        fontFamily: theme.font.light,
        color,
        ...props.style,
      }}
    >
      <MoodIcon />
    </Avatar>
  );
};
