import config from "config";
import { getHeaders, isError, send } from "services/commons.api";
import {
  IGetUserNotificationsData,
  IPatchUserNotification,
} from "./notification.model";
import { UserNotification } from "@gamer/common/lib/models/push-notifications";

export const getUserNotifications = (token: string) => (_context) =>
  send<IGetUserNotificationsData>({
    apiEndpoint: config.API.USER_NOTIFICATIONS.GET_ALL,
    headers: getHeaders(token),
  }).then((v) => {
    if (isError(v)) {
      throw v;
    }

    return v.data;
  });

export const updateNotificationStatus =
  (token: string) =>
    (data: { notificationId: string; patchReq: IPatchUserNotification }) =>
      send<UserNotification>({
        apiEndpoint: config.API.USER_NOTIFICATIONS.UPDATE_NOTIFICATION,
        urlParams: { notificationId: data.notificationId },
        headers: getHeaders(token),
        body: data.patchReq,
      }).then((v) => {
        if (isError(v)) {
          throw v;
        }

        return v.data;
      });
