import React from "react";
import {
  Grid,
  LinearProgress,
  LinearProgressProps,
  Theme,
  Typography,
  createStyles,
} from "@mui/material";
import { withStyles } from "@mui/styles";

const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 700],
    },
    colorSecondary: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
    },
  }),
)(LinearProgress);

export const Progress = (props: LinearProgressProps) => {
  return (
    <Grid container direction="column" style={{ paddingBottom: 3 }}>
      <Grid item>
        <BorderLinearProgress {...props} />
      </Grid>
      <Grid item style={{ height: 0 }}>
        <Grid
          container
          justifyContent="space-around"
          style={{ top: -10, position: "relative" }}
        >
          {[1, 2, 3, 4, 5].map((v, index) => (
            <Grid item id={`${index}-progress-tick`}>
              {Number(props.value) >= v * 20 ? (
                <Typography style={{ fontSize: 7, color: "black" }}>
                  &#9646;
                </Typography>
              ) : (
                <Typography style={{ fontSize: 7, color: "black" }}>
                  &nbsp;
                </Typography>
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
