import React from "react";
import { Bar, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { font } from "theme";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

type ChartDataItem = {
  x: string;
  y: number;
};
type DataSet = {
  label: string;
  yAxisID: string;
  data: ChartDataItem[];
  backgroundColor: string;
  borderColor: string;
};
export interface PlayerStatsProps {
  data: {
    labels: string[];
    datasets: DataSet[];
  };
  measure: string;
  title?: string;
  useSingleYAxis?: boolean;
}
export const PlayersStatsLines = (props: PlayerStatsProps) => {
  const scales = {
    x: {
      ticks: {
        color: "rgba(255, 255, 255, 1)",
      },
    },
    statOneAxis: {
      position: "left" as const,
      title: {
        text: props.measure,
        align: "end" as const,
        display: true,
        color: "rgba(255, 255, 255, 1)",
      },
      grid: {
        color: "rgba(114, 123, 132, 0.3)",
      },
      ticks: {
        color: "rgba(255, 255, 255, 1)",
      },
    },
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom" as const,
        labels: {
          color: "rgba(255, 255, 255, 1)",
          usePointStyle: true,
        },
      },
      title: {
        display: true,
        text: props.title || "Two Players comparision",
        color: "rgba(255, 255, 255, 1)",
        font: { size: 16 },
      },
    },
    scales,
  };

  return <Line options={options} data={props.data} />;
};
export const PlayersStatsBars = (props: PlayerStatsProps) => {
  const scales = {
    x: {
      ticks: {
        color: "rgba(255, 255, 255, 1)",
      },
    },
    statOneAxis: {
      position: "left" as const,
      title: {
        text: props.measure,
        align: "end" as const,
        display: true,
        color: "rgba(255, 255, 255, 1)",
        font: { family: font.adihaus.regular },
      },
      grid: {
        color: "rgba(114, 123, 132, 0.3)",
      },
      ticks: {
        color: "rgba(255, 255, 255, 1)",
      },
    },
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom" as const,
        labels: {
          color: "rgba(255, 255, 255, 1)",
          usePointStyle: true,
          font: { family: font.adihaus.regular },
        },
      },
      title: {
        display: true,
        text: props.title || "Two Players comparision",
        color: "rgba(255, 255, 255, 1)",
        font: { size: 18, family: font.adihaus.regular }, 
      },
    },
    scales,
    elements: {
      bar: {
        borderRadius: 5,
      },
    },
  };

  return <Bar options={options} data={props.data} />;
};
