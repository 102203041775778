import { useEffect, useState } from "react";
import { Stack, Skeleton } from "@mui/material";
import { Duration } from "luxon";
import { IGetTeamMembersData } from "store/team-members/team-members.model";
import { useQueries } from "react-query";
import Cookies from "js-cookie";

import { getMemberStats } from "store/member-stats/member-stats.query";
import { BallSpeedZone, IntervalMetric, IntervalStats, RunSpeedZone } from "store/member-stats/member-stats.model";
import { HeadlineTitle } from "../components/headline-title";
import MetricsDistance from "components/icons/MetricsDistance";
import { MotionSpeedLines } from "components/charts/motion-speed-lines";
import { StatTitle } from "../components/stat-title";
import { DataSetRecord, getDefaultDataSet, getQueryInterval } from "./helpers";
import { ChartInterval } from "components/charts/chart-types";
import { PlayerSpeedZoneData, SpeedZoneData } from "components/charts/utils";
import { getPlayerName } from "containers/leaderboard/rows-leaderboard";
import MetricsRunningSpeed from "components/icons/MetricsRunningSpeed";
import MetricsSprintDistance from "components/icons/MetricsSprintDistance";
import { AtomicMetricsBars } from "components/charts/atomic-metrics-bars";
import MetricsExplosivenessDistance from "components/icons/MetricsExplosivenessDistance";
import { BarSpeedZoneChart } from "components/charts/bar-speedzone-chart";
import MetricsKickCount from "components/icons/MetricsKickCount";
import MetricsBallSpeed from "components/icons/MetricsBallSpeed";
import { PlayerAvatar } from "components/avatar.component";
import TooltipCustom from "components/tooltip.component";
import InfoIcon from "components/icons/InfoIcon";

type ComparisonNewProps = {
  teamMembers?: IGetTeamMembersData;
  to: number;
  from: number;
  queryInterval: string;
  teamId: string;
  playerIds: string[];
  setDisabledPlayerIds?: (playerIds: string[]) => void;
  onExport?: (data: IntervalMetric[][]) => void;
};
export default function ComparisonNew({
  to,
  from,
  teamId,
  playerIds,
  teamMembers,
  setDisabledPlayerIds,
  onExport
}: ComparisonNewProps) {
  const token = Cookies.get("jwtToken") || "";

  const [dataSet, setDataSet] = useState<Array<any>>([]);
  const [defDataSet, setDefDataSet] = useState<Array<DataSetRecord>>([]);

  const fromtoDuration = to - from;
  const chartInterval =
    fromtoDuration > Duration.fromObject({ days: 14 }).as("milliseconds") ? ChartInterval.WEEK :
    fromtoDuration > Duration.fromObject({ days: 1 }).as("milliseconds") ? ChartInterval.DAY :
    fromtoDuration > Duration.fromObject({ hour: 4 }).as("milliseconds") ? ChartInterval.HOUR : ChartInterval.MINUTE;

  const [peakRunSpeedZones, setPeakRunSpeedZones] = useState(Array<PlayerSpeedZoneData>);
  const [ballSpeedZones, setBallSpeedZones] = useState(Array<PlayerSpeedZoneData>);

  const queryInterval = getQueryInterval(chartInterval);

  const membersStats = useQueries(
    playerIds
      .filter((memberId) => !!memberId)
      .map((memberId) => {
        return {
          queryKey: ["memberStats", teamId, memberId, from, to, queryInterval],
          queryFn: getMemberStats({
            teamId,
            memberId,
            from: from.valueOf(),
            to: to.valueOf(),
            interval: queryInterval,
          })(token),
        };
      }),
  );
  const isLoading = membersStats.some((predicate) => predicate.isLoading);

  useEffect(() => {
    if (membersStats.every((q) => q.isSuccess)) {
      onExport?.(membersStats.map((q) => q.data?.data || []));
    }
  }, [...membersStats]);

  useEffect(() => {
    setDefDataSet(getDefaultDataSet(chartInterval, from, to));
  }, [from, to, chartInterval]);

  useEffect(() => {
    if (membersStats.length > 0) {
      const ballSpeedZones = new Array<PlayerSpeedZoneData>();
      const motionSpeedZones = new Array<PlayerSpeedZoneData>();
      const dataset = getDefaultDataSet(chartInterval, from, to);
      membersStats.forEach((memberStat, memberStatIndex) => {
        if (!memberStat.isLoading && memberStat.data?.data) {
          const intervalItems = (memberStat.data as unknown as IntervalStats).data;

          const ballZones = {
            [BallSpeedZone.COLD]: 0,
            [BallSpeedZone.MEDIUM]: 0,
            [BallSpeedZone.HOT]: 0,
            [BallSpeedZone.FIRE]: 0,
          };
          intervalItems.forEach((element) => {
            element.kicks.zones.forEach((zone) => {
              ballZones[zone.zone] += zone.count;
            });
          });
          ballSpeedZones.push(
            { playerId: playerIds[memberStatIndex],
              zones: Object.keys(ballZones).map((zone) => ({ zone, value: ballZones[zone] } as SpeedZoneData))
          });

          const peakRunZones: Record<any, number> = {
            [RunSpeedZone.STAND]: 0,
            [RunSpeedZone.WALK]: 0,
            [RunSpeedZone.JOG]: 0,
            [RunSpeedZone.RUN]: 0,
            [RunSpeedZone.HIGH_SPEED_RUN]: 0,
            [RunSpeedZone.SPRINT]: 0
          };
          intervalItems.forEach((element) => {
            element.playerMotion.peakSpeedZones.forEach((zone) => {
              peakRunZones[zone.zone] += zone.zoneDist;
            });
          });
          motionSpeedZones.push({
            playerId: playerIds[memberStatIndex],
            zones: Object.keys(peakRunZones).map((zone) => ({ zone, value: peakRunZones[zone] } as SpeedZoneData))
          });

          intervalItems.forEach(item => {
            const index = dataset.findIndex(el => el.xTime >= item.intervalStart);
            const element = dataset.at(index > -1 ? index : dataset.length - 1);
            const suffix = memberStatIndex === 0 ? "" : memberStatIndex;

            if (element) {
              element[`motionDist${suffix}`] =
                item.playerMotion.totalDistance + (element[`motionDist${suffix}`] || 0);
              if (element[`motionDist${suffix}`] === 0) {
                element[`motionDist${suffix}`] = undefined;
              }

              element[`motionAvgSpeed${suffix}`] =
                Math.max(item.playerMotion.avgRunningSpeed, (element[`motionAvgSpeed${suffix}`] || 0));
              if (element[`motionAvgSpeed${suffix}`] === 0) {
                element[`motionAvgSpeed${suffix}`] = undefined;
              }

              element[`motionPeakSpeed${suffix}`] =
                Math.max(item.playerMotion.topRunningSpeed, (element[`motionPeakSpeed${suffix}`] || 0));
              if (element[`motionPeakSpeed${suffix}`] === 0) {
                element[`motionPeakSpeed${suffix}`] = undefined;
              }

              element.kickSpeed = Math.max(item.kicks.topBallSpeed, (element.kickSpeed || 0));
              if (element.kickSpeed === 0) {
                element.kickSpeed = undefined;
              }

              element[`kickCount${suffix}`] = item.kicks.count + (element[`kickCount${suffix}`] || 0);
              if (element[`kickCount${suffix}`] === 0) {
                element[`kickCount${suffix}`] = undefined;
              }

              element[`expl${suffix}`] = item.explosiveness.totalDistance + (element[`expl${suffix}`] || 0);
              if (element[`expl${suffix}`] === 0) {
                element[`expl${suffix}`] = undefined;
              }
              
              element[`sprint${suffix}`] = item.sprint.totalDistance + (element[`sprint${suffix}`] || 0);
              if (element[`sprint${suffix}`] === 0) {
                element[`sprint${suffix}`] = undefined;
              }
            }
          });
        }
      });
      setDataSet(dataset);
      setBallSpeedZones(ballSpeedZones);
      setPeakRunSpeedZones(motionSpeedZones);
    }
  }, [...membersStats, defDataSet]);

  useEffect(() => {
    if (!isLoading) {
      const disabledPlayers = playerIds.filter(
        (playerId, index) =>
          !membersStats[index].data?.data?.length,
      );
      setDisabledPlayerIds?.(disabledPlayers);
    }
  }, membersStats);

  const getPlayerNameById = (playerId: string) =>
    getPlayerName({ value: teamMembers?.teamMembers.find((info) => info.playerId === playerId) });

  const getDataKeys = (prefix: string) =>
    playerIds.map((playerid, ind) => ({
      label: getPlayerNameById(playerid) || playerid,
      dataKey: prefix + (ind === 0 ? "" : ind)
    }));
  
  const getAvatar = (plrId: string) => <PlayerAvatar
    style={{ width: 24, height: 24 }}
    key={plrId}
    disabled={false}
    teamId={teamId} playerId={plrId} showPosition={true} />;

  return isLoading ? (
    <>
      <Skeleton variant="rectangular" height="300px" />
      <Skeleton variant="rectangular" height="300px" />
      <Skeleton variant="rectangular" height="300px" />
      <Skeleton variant="rectangular" height="300px" />
      <Skeleton variant="rectangular" height="300px" />
    </>
  ) : (
    <>
      <Stack direction="column" spacing={2}>
        <HeadlineTitle title="PLAYER" />
        { dataSet.length > 0 &&
          <>
            <Stack id="distance" direction="column">
              <StatTitle statTitle="Distance"
                statIcon={<MetricsDistance width="16px" height="16px" style={{ fontSize: 32 }} />} />
              <MotionSpeedLines
                chartInterval={chartInterval}
                dataSet={dataSet}
                dataKeys={getDataKeys("motionDist")}
                measure="M" />
            </Stack>
            <Stack id="avgspeed" direction="column">
              <StatTitle statTitle="Avearge Speed"
                statIcon={<MetricsRunningSpeed width="16px" height="16px" style={{ fontSize: 32 }} />} />
              <MotionSpeedLines
                chartInterval={chartInterval}
                isLoading={isLoading}
                dataSet={dataSet}
                dataKeys={getDataKeys("motionAvgSpeed")} />
            </Stack>
            <Stack id="peakspeed" direction="column">
              <StatTitle statTitle="Peak Speed"
                statIcon={<MetricsRunningSpeed width="16px" height="16px" style={{ fontSize: 32 }} />} />
              <MotionSpeedLines
                chartInterval={chartInterval}
                dataSet={dataSet}
                dataKeys={getDataKeys("motionPeakSpeed")} />
            </Stack>
            <Stack id="runzones" direction="column" width="100%">
              <StatTitle statTitle="Run Speed Zones"
                statIcon={<MetricsRunningSpeed width="16px" height="16px" style={{ fontSize: 32 }} />} 
                infoTooltip={
                  <TooltipCustom 
                    title="The thresholds for the five run speed zones depend on age and gender of the players. 
                           There may be slight differences in the thresholds for the selected players."
                    arrow>
                    <span><InfoIcon/></span>
                  </TooltipCustom>}
                />
              <BarSpeedZoneChart
                data={peakRunSpeedZones}
                title="Total distance"
                units="meter"
                avatar={getAvatar}
                 />
            </Stack>
            <Stack id="sprint" direction="column">
              <StatTitle statTitle="Sprint"
                statIcon={<MetricsSprintDistance width="16px" height="16px" style={{ fontSize: 32 }} />}
                infoTooltip={
                  <TooltipCustom 
                    title="Sprint is the fastest of the five run speed zones. The threshold for when a run counts as a sprint varies by age and gender. 
                           There may be slight differences in the thresholds for the selected players."
                    arrow>
                    <span><InfoIcon/></span>
                  </TooltipCustom>}
                />
              <AtomicMetricsBars
                chartInterval={chartInterval}
                dataSet={dataSet}
                dataKeys={getDataKeys("sprint")}
                measure="M" />
            </Stack>
            <Stack id="explosiveness" direction="column">
              <StatTitle statTitle="Explosiveness"
                statIcon={<MetricsExplosivenessDistance width="16px" height="16px" style={{ fontSize: 32 }} />}
                infoTooltip={
                  <TooltipCustom 
                    title="A run is classified as an explosive event, when the player accelerates so rapidly within 5 seconds, that they skip a run speed zone, such as transitioning directly from Jog to Sprint."
                    arrow>
                    <span><InfoIcon/></span>
                  </TooltipCustom>}
                />
              <AtomicMetricsBars
                chartInterval={chartInterval}
                dataSet={dataSet}
                dataKeys={getDataKeys("expl")}
                measure="M" />
            </Stack>
          </>
        }
        <HeadlineTitle title="BALL" />
        <>
          <Stack id="distance" direction="column">
            <StatTitle statTitle="Kick count"
              statIcon={<MetricsKickCount width="16px" height="16px" style={{ fontSize: 32 }} />} />
            <AtomicMetricsBars
              chartInterval={chartInterval}
              dataSet={dataSet}
              dataKeys={getDataKeys("kickCount")}
              measure="NO." />
          </Stack>
          <Stack id="distance" direction="column">
              <StatTitle statTitle="Ball Speed Zones"
                statIcon={<MetricsBallSpeed width="16px" height="16px" style={{ fontSize: 32 }}/>}
                infoTooltip={
                  <TooltipCustom 
                    title="The thresholds for the four ball speed zones depend on age and gender of the players. 
                           There may be slight differences in the thresholds for the selected players."
                    arrow>
                    <span><InfoIcon/></span>
                  </TooltipCustom>}
                />
              <BarSpeedZoneChart
                data={ballSpeedZones}
                title="Total count"
                units={undefined}
                ballZones={true}
                avatar={getAvatar}
                 />
            </Stack>
        </>
      </Stack>
    </>
  );
}
