import React from "react";
import { Avatar } from "@mui/material";
import { useAuthQuery } from "providers/auth";
import { getUserProfile } from "store/user/user.query";
import theme from "theme";

const UserBadge = ({ active }: { active?: boolean }) => {
  const userQuery = useAuthQuery("userProfile", getUserProfile);

  return (
    <React.Fragment>
      <Avatar
        style={{
          width: 24,
          height: 24,
          border: active ? `2px solid ${theme.actions.primary}` : "",
        }}
        src={userQuery.data?.avatar || ""}
      ></Avatar>
    </React.Fragment>
  );
};

export default UserBadge;
