import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  Container,
  Stack,
} from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState, useRef } from "react";
import { LandingLink } from "components/landing/link.component";
import { SmallButton } from "components/landing/buttons.component";
import { landingTheme } from "landing-theme";
import { ReactComponent as TeamFXIcon } from "images/icons/team-fx-logo-big.svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Trans } from "@lingui/macro";
import { useHistory } from "react-router-dom";
import { useStyles, LandingTabs, FAQ_URL } from "../constants";
import "./PageHeader.style.css";
import { font } from "theme";
import { CountrySelector } from "containers/layout/country-selector.container";
import { currentLocale } from "utils/i18n";

export default function PageHeader({
  setTab,
}: {
  setTab: Dispatch<SetStateAction<LandingTabs>>;
}) {
  const classes = useStyles(landingTheme);
  const bigSize = useMediaQuery("(min-width:720px)");
  const [isScrollDown, setScrollDown] = useState(false);
  const history = useHistory();
  const previousPosition = useRef(0);
  const [locale, setLocale] = useState(currentLocale());

  const scrollToCoachPlayer = (tab: LandingTabs) => {
    setTab(tab);
    const element = document.getElementById("coachplayer");
    if (!element) return;
    const headerOffset = 100;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.scrollY - headerOffset;
    if (elementPosition !== headerOffset) {
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  const handleScrollUpdate = () => {
    if (window.scrollY > previousPosition.current) {
      setScrollDown(true);
    } else {
      setScrollDown(false);
    }
    previousPosition.current = window.scrollY;
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScrollUpdate);

    return () => {
      window.removeEventListener("scroll", handleScrollUpdate);
    };
  }, []);

  return (
    <AppBar
      position="sticky"
      className={`${classes.appBar} ${isScrollDown ? classes.hideHeader : ""}`}
    >
      <Container className={classes.mainContainer}>
        <Toolbar>
          <IconButton edge="start" className={classes.homeIcon}>
            <TeamFXIcon />
          </IconButton>
          <Typography className={classes.topLinksContainer} align="center">
            {bigSize && (
              <>
                <LandingLink className="pageHeader-link" href="#solution">
                  <Trans>SOLUTION</Trans>
                </LandingLink>
                <LandingLink
                  className="pageHeader-link"
                  onClick={() => {
                    scrollToCoachPlayer(LandingTabs.COACH);
                  }}
                >
                  <Trans>COACHES</Trans>
                </LandingLink>
                <LandingLink
                  className="pageHeader-link"
                  onClick={() => {
                    scrollToCoachPlayer(LandingTabs.PLAYER);
                  }}
                >
                  <Trans>PLAYER</Trans>
                </LandingLink>
                <LandingLink className="pageHeader-link" href="#features">
                  <Trans>FEATURES</Trans>
                </LandingLink>
                <LandingLink
                  className="pageHeader-link"
                  href={FAQ_URL}
                  target="_blank"
                >
                  <Trans>FAQ`S</Trans>
                </LandingLink>
              </>
            )}
          </Typography>
          <Stack direction={"row"}>
            <SmallButton
              className={classes.loginButton}
              style={{
                color: "#51F69D",
                padding: "8px 12px",
                fontFamily: font.adineue.cond,
                letterSpacing: "4.50px",
              }}
              variant="outlined"
              onClick={() => {
                history.push("./login");
              }}
            >
              <Trans>Login</Trans>
            </SmallButton>
            <CountrySelector locale={locale} setSelectedLocale={setLocale} />
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
