import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { font, muiTheme } from "../theme";
import { withStyles } from "@mui/styles";

export const StyledDataGrid = withStyles({
  root: {
    fontFamily: font.adihaus.regular,
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0.5px",
    [`& .${gridClasses.columnHeader}`]: {
      backgroundColor: "#0D0D0D",
      height: 80,
      padding: "13px, 8px, 13px, 8px",
      fontFamily: font.adineue.cond,
      fontSize: "17px",
      fontWeight: 400,
      lineHeight: "24px",
      letterSpacing: "1.2000000476837158px",
      textTransform: "uppercase",
    },
    [`& .${gridClasses.virtualScrollerRenderZone}`]: {
      backgroundColor: "#1A1A1A",
    },
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: "#1F1F1F",
    },
    [`& .${gridClasses.row}.odd`]: {
      backgroundColor: "#1A1A1A",
    },
    [`& .${gridClasses.row}.Mui-selected`]: {
      backgroundColor: "#333333",
      borderLeft: "3px solid #51F69D",
    },
  },
})(DataGrid) as unknown as typeof DataGrid;

export const StyledTransparentDataGrid = withStyles({
  root: {
    [`& .${gridClasses.columnHeader}`]: {
      backgroundColor: "rgba(34, 37, 40, 0.8)"
    },
    "& .MuiDataGrid-window, & .MuiDataGrid-virtualScrollerRenderZone": {
      background: "rgba(34, 37, 40, 0.5)",
    },
    [`& .${gridClasses.row}.Mui-selected`]: {
      backgroundColor: "rgba(34, 37, 40, 0.6)",
      borderLeft: "3px solid #51F69D",
    },
  },
})(StyledDataGrid) as unknown as typeof DataGrid;

export const getStripedClassNames = (params) =>
  params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd";
