import {
  ZonesResponse,
  ZoneTypes,
} from "./speed-zones.model";
import {
  getHeaders,
  isError,
  send,
} from "services/commons.api";
import config from "../../config";

export const getZones = (zoneType: ZoneTypes) => (token: any) => () =>
  send<{ zones: ZonesResponse }>({
    apiEndpoint: config.API.GENERIC_WORKOUTS.GET,
    headers: getHeaders(token),
    urlParams: { file: zoneType === ZoneTypes.KICKS ? "kicks" : "motions" },
  }).then((v) => {
    if (isError(v)) {
      throw v;
    }

    return v.data;
  });