import { SvgIcon, SvgIconProps } from "@mui/material";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";

function MetricsSprintDistance(props: SvgIconProps) {
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      fontSize="large"
      {...props}
      style={{ fontSize: 48, ...props.style }}
    >

      <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_7177_19161)">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M34.1507 6.75798C34.2552 5.74485 33.5155 4.91665 32.5023 4.91665C31.4892 4.91665 30.5806 5.74485 30.468 6.75798C30.3555 7.77112 31.1033 8.59932 32.1164 8.59932C33.1295 8.59932 34.0381 7.77112 34.1507 6.75798ZM35.0674 6.75798C34.9065 8.27769 33.5396 9.51597 32.0199 9.51597C30.5002 9.51597 29.3906 8.27769 29.5514 6.75798C29.7122 5.23828 31.0791 4 32.5988 4C34.1185 4 35.2282 5.23828 35.0674 6.75798ZM28.3855 34.9811L34.7859 24.3351L27.71 21.1912L32.3174 12.3624L27.9754 10.4326L21.2292 11.293L17.8279 17.412L18.8652 17.8542L22.17 12.6519L27.3804 12.3061L22.1137 22.2928L31.1917 26.4177L27.3563 34.5308L28.3774 34.9811H28.3855ZM34.3276 15.9727L32.9124 13.1343L28.9564 20.7007L36.0885 23.8687L28.6991 36.1711L26.1421 35.0374L29.9775 26.9162L25.7401 24.9945L21.6634 30.3577L11.5642 33.5579L10.7762 31.0572L18.3828 27.4951L20.2241 24.0135L21.0362 24.3753L19.0341 28.1706L11.926 31.5075L12.2155 32.4322L21.1649 29.5938L24.936 24.6326L20.8754 22.7913L25.8526 13.3434L22.6524 13.5524L19.1707 19.0443L16.5575 17.9266L20.6985 10.4728L28.2086 9.50793L33.3064 11.7754L34.9226 15.0159L40.5029 14.3806L40.905 17.219L32.8159 19.0041L31.835 17.5728L32.6149 17.0019L33.2823 17.9829L39.8597 16.5356L39.6988 15.3938L34.3437 16.0049L34.3276 15.9727Z" fill="white" />
        </g>
        <circle cx="12.1075" cy="40.1567" r="2.5" stroke="white" />
        <circle cx="24.1075" cy="40.1567" r="2.5" stroke="white" />
        <circle cx="36.1075" cy="40.1567" r="2.5" stroke="white" />
        <line x1="14.6075" y1="40.1567" x2="21.6075" y2="40.1567" stroke="white" />
        <line x1="26.6075" y1="40.1567" x2="33.6075" y2="40.1567" stroke="white" />
        <defs>
          <clipPath id="clip0_7177_19161">
            <rect width="30.1127" height="32.1631" fill="white" transform="translate(10.7762 4)" />
          </clipPath>
        </defs>
      </svg>

    </SvgIcon>
  );
}

export default MetricsSprintDistance;
