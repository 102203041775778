import React from "react";
import * as core from "@mui/material";
import { withStyles } from "@mui/styles";
import { font } from "../theme";

export const Tab = withStyles((theme) => ({
  root: {
    "font-size": "20px",
    "font-family": font.adineue.cond,
    width: "fit-content",
  },
}))(core.Tab);

export const Tabs = withStyles((theme) => ({}))(core.Tabs);

interface TabPanelProps {
  children: React.ReactNode;
  value: number;
  index: number;
}

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index } = props;

  return (
    <React.Fragment>
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
      >
        {value === index && children}
      </div>
    </React.Fragment>
  );
};
