import * as React from "react";
import { Typography, Stack } from "@mui/material";
import { IconByEventType } from "./event-type.icon";
import { DateTime } from "luxon";
import { TeamEventUserSpecific } from "store/events/events.model";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { Trans } from "@lingui/macro";
import "../modals/event-timeline.css";
import { getTranslatedEventType } from "components/toggleButton/toggle-button-group.component";

type EventTimelineRecordProps = { active: boolean } & Pick<
  TeamEventUserSpecific,
  "startTime" | "endTime" | "type" | "title" | "seriesId"
>;

export const EventRecord = (props: EventTimelineRecordProps) => {
  const start = DateTime.fromMillis(props.startTime);
  const end = DateTime.fromMillis(props.endTime);

  return (
    <Stack
      direction="row"
      sx={{
        width: "368px",
        borderRadius: "4px",
        overflow: "hidden",
      }}
    >
      <Stack
        sx={{
          bgcolor: "#404040",
          color: "#CCCCCC",
          p: "18px 10px",
          minWidth: "45px",
        }}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography
          variant="subtitle1"
          fontSize="24px"
          lineHeight="28px"
          fontWeight="500"
        >
          {start.day}
        </Typography>
        <Typography
          variant="h6"
          fontSize="10px"
          lineHeight="16px"
          fontWeight="400"
          letterSpacing="1.2px"
        >
          {start.monthShort}
        </Typography>
      </Stack>
      <Stack
        direction="column"
        textAlign="left"
        sx={{
          p: "18px 10px",
          width: "100%",
          bgcolor: props.active ? "#333333" : "#1A1A1A",
        }}
      >
        <Typography color="white" variant="subtitle1" lineHeight="29px">
          {props.title}
        </Typography>
        <Stack direction="row" color="#999999">
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "16px",
            }}
          >
            <IconByEventType type={props.type} size={10} />
            {getTranslatedEventType(props.type)}
            <DateRangeIcon style={{ height: 10 }} />
            {start.weekdayShort} {start.toFormat("HH:mm")} -{" "}
            {end.toFormat("HH:mm")}
            {props.seriesId && " - "}
            {props.seriesId && (
              <span className="label__repeating-event">
                <Trans id="repeating_event_text">Repeating Event</Trans>
              </span>
            )}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
