import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Typography, Button, Grid, LinearProgress } from "@mui/material";
import { toast } from "react-toastify";
import { copyInviteLink } from "utils/team";
import { useAuthQuery } from "providers/auth";
import { getTeams } from "store/user-teams/teams.query";
import { TabsTypes } from "../settings.page";
import AdidasLogoIcon from "components/icons/AdidasLogoIcon";
import { Trans } from "@lingui/macro";
import { OnboardingLeftContent } from "./components/OnboardingLeftContent";

const OnboardingMembers = () => {
  const { teamId }: { teamId: string } = useParams();
  const teamsQuery = useAuthQuery("teams", getTeams);
  const history = useHistory();

  const onInviteLink = () => {
    const teamName = teamsQuery.data?.userTeams.find(
      ({ teamId: itemTeamId }) => itemTeamId === teamId,
    )?.teamName;
    copyInviteLink(teamId, teamName);

    return toast.success("Link copied to the clipboard!");
  };

  const onAddByTag = () => {
    return history.push(`/teams/${teamId}/settings/${TabsTypes.MEMBERS}`);
  };

  const onNext = () => {
    history.push(`/teams/${teamId}/dashboard`);
  };

  return (
    <div className={"login"}>
      <div className="login-leftSide login-part">
        <OnboardingLeftContent />
      </div>
      <div className="login-rightSide login-part">
        <div className={"login-content"}>
          <div className="topHeader">
            <div className={"login-logo__container"}>
              <AdidasLogoIcon
                onClick={() =>
                  (window.location.href = "https://www.adidas.com/us")
                }
                height={60}
              />
            </div>
            <Typography variant="h1">TEAM FX</Typography>
          </div>
          <Grid container direction="column" spacing={4}>
            <Grid item>
              <LinearProgress variant="determinate" value={95} />
            </Grid>
            <Grid item width="fit-content">
              <Typography variant="h3">
                <Trans>Invite team members</Trans>
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                <Trans>
                  Share the link to your team members to invite them into team
                </Trans>
              </Typography>
            </Grid>
            <Grid item>
              <Button
                color="secondary"
                variant="contained"
                onClick={onInviteLink}
                style={{ height: 60 }}
                fullWidth
              >
                <Trans>Copy Invite Link</Trans>
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="secondary"
                style={{ height: 60 }}
                onClick={onAddByTag}
                fullWidth
              >
                <Trans>Add by Tag ID</Trans>
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={onNext}
                style={{ height: 60 }}
                fullWidth
              >
                <Trans>Let's get started</Trans>
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default OnboardingMembers;
