export enum BallSpeedZone {
  COLD = "COLD",
  MEDIUM = "MEDIUM",
  HOT = "HOT",
  FIRE = "FIRE",
}

export enum RunSpeedZone {
  SPRINT = "SPRINT",
  HIGH_SPEED_RUN = "HIGH_SPEED_RUN",
  RUN = "RUN",
  JOG = "JOG",
  WALK = "WALK",
  STAND = "STAND",
}

export type IntervalMetric = {
  intervalStart: number;
  interval: number;
  count: number;
  playerMotion: {
    avgRunningSpeed: number;
    topRunningSpeed: number;
    totalDistance: number;
    count: number;
    avgSpeedZones: Array<{ zone: RunSpeedZone; zoneDist: number }>;
    peakSpeedZones: Array<{ zone: RunSpeedZone; zoneDist: number }>;
  };
  kicks: {
    topBallSpeed: number;
    avgBallSpeed: number;
    bottomBallSpeed: number;
    count: number;
    zones: Array<{ zone: BallSpeedZone; count: number }>;
  };
  explosiveness: {
    totalDistance: number;
    count: number;
  };
  sprint: {
    totalDistance: number;
    count: number;
  };
};

export type IntervalStats = {
  data: Array<IntervalMetric>;
};
