import { Stack, Typography } from "@mui/material";

export type HeadlineTitleProps = {
  title: string;
}

export const HeadlineTitle = (props: HeadlineTitleProps) => {
  return <Stack direction="row"
    id="playerSection"
    alignItems="flex-end"
    justifyContent="flex-start"
    spacing={1}
    style={{padding: "48px, 0px, 16px, 0px"}} >
    <hr style={{width: "24px", height: "1px"}}></hr>
    <Typography variant="h4" style={{ lineHeight: 0.6 }}>{props.title}</Typography>
    <div style={{height: "1px", width: "100%", background: "linear-gradient(to right, #FFFFFF, #0D0D0D)" }} />
  </Stack>;
};
