import { useEffect, useState } from "react";
import { Trans } from "@lingui/macro";
import {
  Checkbox,
  FormControlLabel,
  Paper,
  Stack,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { MobileTimePicker } from "components/date-picker.component";
import { DateTime } from "luxon";

export enum WeekDays {
  MO = "Monday",
  TU = "Tuesday",
  WE = "Wednesday",
  TH = "Thursday",
  FR = "Friday",
  SA = "Saturday",
  SU = "Sunday",
}

export const getTranslatedWeekDay = (day: WeekDays) => {
  switch (day) {
    case WeekDays.MO:
      return <Trans id="event_repeating_monday_label">Monday</Trans>;
    case WeekDays.TU:
      return <Trans id="event_repeating_tuesday_label">Tuesday</Trans>;
    case WeekDays.WE:
      return <Trans id="event_repeating_wednesday_label">Wednesday</Trans>;
    case WeekDays.TH:
      return <Trans id="event_repeating_thursday_label">Thursday</Trans>;
    case WeekDays.FR:
      return <Trans id="event_repeating_friday_label">Friday</Trans>;
    case WeekDays.SA:
      return <Trans id="event_repeating_saturday_label">Saturday</Trans>;
    case WeekDays.SU:
      return <Trans id="event_repeating_sunday_label">Sunday</Trans>;
  }
};

export interface ReoccuringDaysProps {
  isChecked: boolean;
  startTime?: DateTime;
  endTime?: DateTime;
  day: WeekDays;
}
export interface EventRepeatingCheckBoxProps {
  data: ReoccuringDaysProps;
  handleChange: (props: ReoccuringDaysProps) => void;
}

export const EventRepeatingCheckBox = ({
  data,
  handleChange,
}: EventRepeatingCheckBoxProps) => {
  const isSmallScreen = useMediaQuery("(max-width: 480px)");

  const { isChecked, startTime, endTime, day } = data;

  const [isDayChecked, setIsDayChecked] = useState(isChecked);
  const [startTimestamp, setStartTimestamp] = useState<DateTime | undefined>(
    startTime,
  );
  const [endTimestamp, setEndTimestamp] = useState<DateTime | undefined>(
    endTime,
  );

  useEffect(() => {
    setIsDayChecked(isChecked);
    setStartTimestamp(startTime);
    setEndTimestamp(endTime);
  }, [data]);

  useEffect(() => {
    handleChange({
      isChecked: isDayChecked,
      startTime: startTimestamp,
      endTime: endTimestamp,
      day,
    });
  }, [isDayChecked, startTimestamp, endTimestamp]);

  return (
    <Stack direction="column" sx={{ width: "100%" }} spacing={2}>
      {isDayChecked ? (
        <Paper style={{ borderRadius: 4, padding: 10 }}>
          <Stack direction="column" sx={{ width: "100%" }}>
            <FormControlLabel
              componentsProps={{
                typography: {
                  variant: "body1",
                  color: "white",
                  fontSize: "14px",
                },
              }}
              label={getTranslatedWeekDay(day)}
              labelPlacement="end"
              value={day}
              control={
                <Checkbox
                  
                  checked={isDayChecked}
                  onClick={() => setIsDayChecked(!isDayChecked)}
                />
              }
            />

            <Stack
              direction={isSmallScreen ? "column" : "row"}
              spacing={2}
              justifyContent="space-around"
              alignItems="center"
            >
              <MobileTimePicker
                value={startTimestamp}
                onChange={(v) => {
                  setStartTimestamp(v || DateTime.now());
                  setEndTimestamp(v?.plus({ minutes: 15 }));
                }}
                maxTime={DateTime.now().endOf("day").minus({ minutes: 15 })}
                ampm={false}
                label={<Trans id="event_time_begins_at_label">Begins At</Trans>}
                format={"HH:mm"}
                slots={{
                  textField: (params) => (
                    <TextField variant="standard" {...params} />
                  ),
                }}
                slotProps={{
                  toolbar: {
                    className: "leaderBoard-datepicker",
                  },
                  textField: {
                    style: {
                      width: "100%",
                      transform: "scale(0.8)",
                    },
                  },
                }}
              />
              <MobileTimePicker
                value={endTimestamp}
                onChange={(v) => setEndTimestamp(v || DateTime.now())}
                minTime={(startTimestamp || DateTime.now().startOf("day")).plus(
                  { minutes: 15 },
                )}
                maxTime={DateTime.now().endOf("day")}
                ampm={false}
                label={<Trans id="event_time_ends_at_label">Ends At</Trans>}
                format={"HH:mm"}
                slots={{
                  textField: (params) => (
                    <TextField variant="standard" {...params} />
                  ),
                }}
                slotProps={{
                  toolbar: {
                    className: "leaderBoard-datepicker",
                  },
                  textField: {
                    style: {
                      width: "100%",
                      transform: "scale(0.8)",
                    },
                  },
                }}
              />
            </Stack>
          </Stack>
        </Paper>
      ) : (
        <FormControlLabel
          componentsProps={{
            typography: {
              variant: "body1",
              color: "white",
              fontSize: "14px",
            },
          }}
          label={getTranslatedWeekDay(day)}
          labelPlacement="end"
          value={day}
          control={
            <Checkbox
              
              checked={isDayChecked}
              onClick={() => setIsDayChecked(!isDayChecked)}
            />
          }
        />
      )}
    </Stack>
  );
};
