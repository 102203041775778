import { Box, Grid, Tabs, Typography } from "@mui/material";
import { FeatureTab } from "components/landing/tabs.component";
import { TabPanel } from "components/tabs.component";
import playerImage from "images/landing/img-player.png";
import playerMobileImage from "images/landing/img-player-mobile.png";
import coachImage from "images/landing/img-coach.png";
import coachMobileImage from "images/landing/img-coach-mobile.png";
import { SolutionText } from "components/landing/typography.component";
import { LargeButton } from "components/landing/buttons.component";
import useMediaQuery from "@mui/material/useMediaQuery";
import { landingTheme } from "landing-theme";
import { Dispatch, SetStateAction } from "react";
import { font } from "theme";
import { Trans } from "@lingui/macro";
import { useStyles, LandingTabs, LEFT_MARGIN, ORDER_NOW, getLandingTabLabels } from "../constants";
import FeaturesCarousel from "./FeaturesCarousel";

export default function BottomSection({
  setTab,
  tab,
}: {
  tab: LandingTabs;
  setTab: Dispatch<SetStateAction<LandingTabs>>;
}) {
  const bigSize = useMediaQuery("(min-width:720px)");
  const classes = useStyles(landingTheme);
  const landingTabLabels = getLandingTabLabels();

  return (
    <Grid item id="bottomSection" className={classes.coachPlayerFeatureSection}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid id="coachplayer" item style={{ padding: "0 5%" }}>
          <Tabs
            value={tab}
            onChange={(_, v) => setTab(v)}
            indicatorColor="primary"
            textColor="inherit"
            style={{ borderBottom: "#666666 solid 1px" }}
            centered
            variant={bigSize ? "standard" : "fullWidth"}
          >
            <FeatureTab
              label={landingTabLabels[LandingTabs.COACH]}
              style={{ height: 72 }}
            />
            <FeatureTab label={landingTabLabels[LandingTabs.PLAYER]} />
          </Tabs>
          <TabPanel index={LandingTabs.PLAYER} value={tab}>
            <Box
              paddingLeft={bigSize ? LEFT_MARGIN : 0}
              paddingRight={bigSize ? LEFT_MARGIN : 0}
            >
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <Box marginTop={"40px"} paddingBottom={2}>
                    <Typography
                      align="left"
                      variant="h2"
                      sx={{
                        fontFamily: font.adihaus.condItalic,
                        fontWeight: 500,
                      }}
                    >
                      <Trans>Elevate your performance with Team FX</Trans>
                    </Typography>
                  </Box>
                </Grid>
                <Grid container justifyContent="center">
                  <Box maxWidth={"930px"} paddingBottom={4}>
                    <Typography
                      sx={{
                        fontFamily: font.adihaus.regular,
                        fontWeight: 400,
                      }}
                      align="center"
                      variant={bigSize ? "h5" : "h6"}
                    >
                      <Trans>Players embrace the gamified experience of reviewing and
                      comparing their football metric data to track their
                      progress and compete with teammates on the digital
                      leaderboard. Additionally, they can provide training
                      feedback to the coach and receive personalized guidance to
                      master key skills that impact their game.</Trans>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item style={{ margin: "0 -5%" }}>
                  <img
                    src={bigSize ? playerImage : playerMobileImage}
                    className={classes.playerCoachImage}
                    alt="Coach"
                  />
                </Grid>
              </Grid>
            </Box>
          </TabPanel>
          <TabPanel index={LandingTabs.COACH} value={tab}>
            <Box
              paddingLeft={bigSize ? LEFT_MARGIN : 0}
              paddingRight={bigSize ? LEFT_MARGIN : 0}
            >
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <Box marginTop={"40px"} paddingBottom={2}>
                    <Typography
                      align="left"
                      variant="h2"
                      sx={{
                        fontFamily: font.adihaus.condItalic,
                        fontWeight: 500,
                      }}
                    >
                      <Trans>Empower your coaching with TEAM FX</Trans>
                    </Typography>
                  </Box>
                </Grid>
                <Grid container justifyContent="center">
                  <Box maxWidth={"930px"} paddingBottom={4}>
                    <Typography
                      align="center"
                      variant={bigSize ? "h5" : "h6"}
                      sx={{
                        fontFamily: font.adihaus.regular,
                        fontWeight: 400,
                      }}
                    >
                      <Trans>TEAM FX gives coaches access to key player metrics and a
                      comparison feature that offers valuable insights for team
                      performance analysis. From planning events such as
                      training sessions and matches to receiving performance
                      feedback from players, TEAM FX helps coaches create
                      effective training plans and prepare for success.</Trans>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item style={{ margin: "0 -5%" }}>
                  <img
                    src={bigSize ? coachImage : coachMobileImage}
                    className={classes.playerCoachImage}
                    alt="Coach"
                  />
                </Grid>
              </Grid>
            </Box>
          </TabPanel>
        </Grid>
        <Grid item id="features" style={{ width: "100%" }}>
          <Box
            paddingLeft={bigSize ? LEFT_MARGIN : 0}
            paddingTop={10}
            paddingRight={bigSize ? LEFT_MARGIN : 0}
          >
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
            >
              <Grid item style={{ width: "100%" }}>
                <Box
                  paddingLeft={!bigSize ? LEFT_MARGIN : 0}
                  paddingTop={bigSize ? 5 : 0}
                >
                  <SolutionText variant="h1"><Trans>Features</Trans></SolutionText>
                </Box>
              </Grid>
            </Grid>
            <FeaturesCarousel />
          </Box>
        </Grid>

        {!bigSize && (
          <Grid
            container
            id="orderNowMobile"
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <LargeButton
              style={{
                width: "90%",
                borderRadius: "5px",
                fontSize: "18px",
                marginLeft: LEFT_MARGIN,
                marginRight: LEFT_MARGIN,
                marginBottom: "1rem",
                fontWeight: "700",
                padding: "8px 12px",
                fontFamily: font.adineue.cond,
                letterSpacing: "5.50px",
              }}
              variant="contained"
              onClick={() => {
                window.open(ORDER_NOW, "_blank");
              }}
            >
              <Trans>Order Now</Trans>
            </LargeButton>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
