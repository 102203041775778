import React, { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Divider, Typography, Button } from "@mui/material";
import styled from "styled-components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

import { Tab, TabPanel, Tabs } from "components/tabs.component";
import { UserProfileView } from "../containers/user/user-profile.tab";
import { useHistory } from "react-router-dom";
import {
  getNavigationUrl,
  NavigationContext,
  NavigationTabs,
} from "providers/navigation.provider";
import { Trans } from "@lingui/macro";
import { useAnalytics } from "use-analytics";
import { UserTrophie } from "containers/user/user-trophies.tab";

const LeftTopPadding = styled.div`
  padding-top: 25px;
  padding-left: 25px;
`;

export enum TabsTypes {
  PROFILE,
  TROPHIES
}

export const Profile = () => {
  const analytics = useAnalytics();
  const { currentTeam } = useContext(NavigationContext);
  const { teamId } = currentTeam;
  const history = useHistory();
  const [tab, setTab] = useState(TabsTypes.PROFILE);

  const handleTabChange = (_event: any, value: any) => {
    setTab(value);
  };

  useEffect(() => {
    analytics.page("profile");
  }, []);

  return (
    <Grid
      container
      direction="column"
      alignItems="stretch"
      justifyContent="flex-start"
    >
      <Grid item>
        <Button
          disableRipple
          sx={{ color: "white" }}
          startIcon={<ArrowBackIcon color={"inherit"} />}
          style={{ borderRadius: 0, padding: "1rem" }}
          onClick={() =>
            history.push(getNavigationUrl(teamId, NavigationTabs.DASHBOARD))
          }
        >
          <Typography
            variant="body2"
            style={{
              textTransform: "none",
              paddingTop: "0.5rem",
              color: "white",
            }}
          >
            <Trans>Back to Dashboard</Trans>
          </Typography>
        </Button>
      </Grid>
      <Divider variant="fullWidth" />
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
        >
          <Grid item xs={2}>
            <Grid
              container
              direction="column"
              justifyContent="space-evenly"
              alignItems="center"
              spacing={2}
            >
              <Grid item></Grid>
              <Grid item>
                <Grid
                  container
                  id="settingTeamName"
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  spacing={1}
                ></Grid>
              </Grid>
              <Grid item>
                <Tabs
                  value={tab}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  orientation="vertical"
                  textColor="primary"
                >
                  <Tab
                    label={<Trans>PROFILE</Trans>}
                    icon={<EditIcon />}
                  />
                  <Tab
                    label={<Trans>TROPHY</Trans>}
                    icon={<EmojiEventsIcon />}
                  />
                </Tabs>
              </Grid>
              <Grid item xs={6}></Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Divider orientation="vertical" flexItem />
          </Grid>
          <Grid item xs={9}>
            <LeftTopPadding>
              <TabPanel value={tab} index={TabsTypes.PROFILE}>
                <UserProfileView />
              </TabPanel>
              <TabPanel value={tab} index={TabsTypes.TROPHIES}>
                <UserTrophie />
              </TabPanel>
            </LeftTopPadding>
          </Grid>
        </Grid>
      </Grid>
      <Grid item style={{ height: "100%" }} xs={12}>
        &nbsp;
      </Grid>
    </Grid>
  );
};
