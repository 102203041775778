import { Box, Grid, Paper, Typography } from "@mui/material";
import TeamDefaultIcon from "components/icons/TeamDefaultIcon";
import NoAvatar from "../../images/PlayerCard-NoAvatar.png";
import { DateTime } from "luxon";
import {
  PlayerAttributes,
  PlayerCard,
} from "store/player-card/player-card.model";
import {
  ITeamMemberInfo,
  PlayerPosition,
  PlayerPositionFullTitle,
} from "store/team-members/team-members.model";
import { IUserTeamInfo } from "store/user-teams/user-teams.model";
import { font } from "theme";
import { GenericTrophy, TrophyId, TrophyLevel, TrophyLevelToWeight } from "store/trophies/trophies.model";
import { TrophyIcon } from "components/icons/Trophy";
import { getGenericTrophies, getTrophies } from "store/trophies/trophies.query";
import { useAuthQuery } from "providers/auth";
import { useEffect, useState } from "react";

export type PlayerCardComponentProps = {
  avatarUrl?: string;
  teamLogoUrl: string;
  firstName: string;
  lastName: string;
  postion: PlayerPosition;
  total: number;
  date?: number;
  old?: boolean;
  trophies?: { trophyId: TrophyId; level: TrophyLevel }[];
} & PlayerAttributes & { width?: number; height?: number };

export const getAttributeBageColor = (value: number) => {
  if (value < 60) {
    return "#333333";
  }
  if (value < 70) {
    return "#C19F7A";
  }
  if (value < 80) {
    return "#E3E3E3";
  }
  if (value < 90) {
    return "#E6C247";
  }
  if (value <= 100) {
    return "#51F69D";
  }

  return "#333333";
};

export const getAttributeTextColor = (value: number) => {
  if (value < 60) {
    return "#F2F2F2";
  }
  if (value < 70) {
    return "#C19F7A";
  }
  if (value < 80) {
    return "#E3E3E3";
  }
  if (value < 90) {
    return "#E6C247";
  }
  if (value <= 100) {
    return "#51F69D";
  }

  return "#F2F2F2";
};

export const CardAttribute = (props: { label: string; value: number }) => (
  <Grid
    container
    direction="column"
    justifyContent="space-between"
    alignItems="center"
  >
    <Grid item>
      <svg
        width="34"
        height="38"
        viewBox="0 0 34 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.95 0.721688C17.1047 0.632372 17.2953 0.632372 17.45 0.721688L32.9045 9.64434C33.0592 9.73365 33.1545 9.89872 33.1545 10.0774V27.9226C33.1545 28.1013 33.0592 28.2663 32.9045 28.3557L17.45 37.2783C17.2953 37.3676 17.1047 37.3676 16.95 37.2783L1.49553 28.3557C1.34083 28.2663 1.24553 28.1013 1.24553 27.9226V10.0774C1.24553 9.89872 1.34083 9.73365 1.49553 9.64434L16.95 0.721688Z"
          stroke={getAttributeBageColor(props.value)}
          strokeWidth={1.3}
        />
        <text
          x="50%"
          y="55%"
          textAnchor="middle"
          dominantBaseline="middle"
          fill={getAttributeTextColor(props.value)}
          fontSize="19px"
          fontWeight={700}
          letterSpacing="1px"
          fontFamily={font.adihaus.regular}
        >
          {props.value}
        </text>
      </svg>
    </Grid>
    <Grid item>
      <Typography variant="subtitle2">{props.label}</Typography>
    </Grid>
  </Grid>
);

const baseWidht = 328;
const baseHeight = 516;
export const PlayerCardComponent = (props: PlayerCardComponentProps) => (
  <Paper>
    <Grid
      container
      direction="column"
      width={props.width || baseWidht}
      height={props.height || baseHeight}
      border="thin"
      borderColor={"#333333"}
      borderRadius={"2px"}
      justifyContent={"space-between"}
      style={{
        background: `
        ${
          props.avatarUrl
            ? `linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%), url(${props.avatarUrl}) no-repeat`
            : `url(${NoAvatar})  no-repeat`
        },  #000000`,
        backgroundSize: `${baseWidht}px ${baseWidht}px`,
        backgroundBlendMode: props.old ? "luminosity" : "normal",
      }}
    >
      <Grid item id="top">
        <Grid container>
          {props.date ? (
            <Grid
              item
              width={30}
              height={87}
              style={{
                background: "#000000",
                zIndex: 1,
                alignItems: "center",
                borderRadius: "0px 0px 4px 0px",
                borderBottom: "1px solid #333333",
                borderRight: "1px solid #333333",
              }}
            >
              <Typography
                color="primary"
                style={{
                  fontFamily: font.adihaus.regular,
                  fontSize: "12px",
                  fontWeight: "700",
                  lineHeight: "15.46px",
                  letterSpacing: "1px",
                  textAlign: "left",
                  writingMode: "vertical-lr",
                  textOrientation: "sideways",
                  textTransform: "uppercase",
                  transform: "scale(-1)",
                  padding: "20%",
                  paddingBottom: "70%",
                }}
              >
                {DateTime.fromMillis(props.date || 0).toFormat("LLL") +
                  " '" +
                  DateTime.fromMillis(props.date || 0).toFormat("dd")}
              </Typography>
            </Grid>
          ) : (
            ""
          )}
          <Grid
            item
            padding="20px"
            paddingLeft={props.date ? "10px" : "20px"}
            sm
          >
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item height={52}>
                <Typography
                  variant="h5"
                  style={{
                    fontFamily: font.adihaus.condItalic,
                    fontSize: "72px",
                    fontWeight: "700",
                    letterSpacing: "1px",
                    lineHeight: "40px",
                    textAlign: "left",
                  }}
                >
                  {props.total}
                </Typography>
              </Grid>
              <Grid item width={52} height={52}>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    {props.teamLogoUrl ? (
                      <img
                        src={props.teamLogoUrl}
                        alt="Team Logo"
                        width={52}
                        height={52}
                      />
                    ) : (
                      <Box
                        width={52}
                        height={52}
                        style={{
                          borderRadius: "26px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <TeamDefaultIcon fill={"white"} fontSize={45} />
                      </Box>
                    )}
                  </Grid>
                  {props.trophies?.slice(0, 5).map((trophy) => (
                    <Grid
                      item
                      key={trophy.trophyId}
                      style={{
                        zoom: 0.45,
                        background: "#000000B2",
                        marginTop: "10px",
                      }}
                    >
                      <TrophyIcon
                        trophyId={trophy.trophyId}
                        level={trophy.level}
                      />
                    </Grid>
                  ))}
                  {props.trophies && props.trophies.length > 5 ? (
                    <Grid
                      item
                      style={{
                        background: "#000000B2",
                        marginTop: "5px",
                        width: 30,
                        height: 30,
                        fontFamily: "AdihausDIN",
                        fontSize: "12px",
                        fontStyle: "italic",
                        fontWeight: 700,
                        lineHeight: "15.46px",
                        letterSpacing: "1px",
                        textAlign: "center",
                        textUnderlinePosition: "from-font",
                        textDecorationSkipInk: "none",
                        padding: "6px",
                      }}
                    >
                      +{props.trophies.length - 5}
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item id="bottom" margin="20px">
        <Grid container direction="column">
          <Grid item>
            <Grid id="name" container direction="column" spacing="2px">
              <Grid item width="fit-content">
                <Typography
                  variant="h3"
                  style={{
                    fontSize: "32px",
                    fontWeight: "400",
                    lineHeight: "28px",
                    letterSpacing: "1px",
                    textAlign: "left",
                  }}
                >
                  {props.firstName}
                </Typography>
              </Grid>
              <Grid item width="fit-content">
                <Typography
                  variant="h3"
                  style={{
                    fontSize: "32px",
                    fontWeight: "400",
                    lineHeight: "28px",
                    letterSpacing: "1px",
                    textAlign: "left",
                  }}
                >
                  {props.lastName}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="subtitle2"
                  style={{
                    //styleName: Mobile/Copy/Mid;
                    fontFamily: font.adihaus.regular,
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "20.61px",
                    textAlign: "left",
                  }}
                >
                  {PlayerPositionFullTitle[props.postion]}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item paddingBottom={"11px"} paddingTop={"36px"}>
            <Grid container justifyContent="space-around">
              {["DRI", "DEF", "PHY", "PAC", "PAS", "SHO"].map((key) => (
                <Grid item>
                  <CardAttribute
                    label={key}
                    value={props[key as keyof PlayerAttributes]}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Paper>
);
export const PlayerCardTeams = (props: {
  card: Pick<PlayerCard, "playerId" | "position" | "total"> & PlayerAttributes;
  members: Pick<ITeamMemberInfo, "memberInfo" | "position" | "playerId">[];
  team: Pick<IUserTeamInfo, "avatar">;
  width?: number;
  height?: number;
  date?: number;
  old?: boolean;
  trophy?: boolean;
}) => {
  const player = props.members.find(
    (member) => member.playerId === props.card.playerId,
  );
  const [trophies, setTrophies] = useState<
    { trophyId: TrophyId; level: TrophyLevel }[]
  >([]);

  const trophyQuery = useAuthQuery(
    ["trophies", player?.playerId],
    getTrophies({ playerId: player?.playerId }),
    { enabled: props.trophy },
  );
  const genericTrophy = useAuthQuery("trophies", getGenericTrophies, {
    enabled: props.trophy,
  });

  useEffect(() => {
    if (trophyQuery.data && genericTrophy.data && trophies.length === 0) {
      const result: { trophyId: TrophyId; level: TrophyLevel }[] = [];
      for (let i = 0; i < trophyQuery.data.data.length; i++) {
        const t = trophyQuery.data.data[i];
        const gen: GenericTrophy["levels"] =
          genericTrophy.data.trophies.find((g) => g.trophyId === t.trophyId)?.levels ||
          {};

        Object.keys(gen).forEach((level) => {
          if (gen[level] <= Number(t.currentValue)) {
            result.push({
              trophyId: t.trophyId as TrophyId,
              level: level as TrophyLevel,
            });
          }
        });
      }

      result.sort((a, b) => TrophyLevelToWeight[b.level] - TrophyLevelToWeight[a.level]);
      setTrophies(result);
    }
  }, [trophyQuery.data, genericTrophy.data]);

  return (
    <PlayerCardComponent
      {...props.card}
      avatarUrl={player?.memberInfo?.avatar || ""}
      teamLogoUrl={props.team.avatar || ""}
      firstName={player?.memberInfo?.firstName || ""}
      lastName={player?.memberInfo?.lastName || ""}
      postion={props.card.position || player?.position || PlayerPosition.GK}
      width={props.width}
      height={props.height}
      date={props.date}
      old={props.old}
      trophies={trophies}
    />
  );
};
