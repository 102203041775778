import {
  IntervalMetric
} from "store/member-stats/member-stats.model";
import { DateTime, Duration } from "luxon";
import PlayerStatsMins from "./player-stats-mins";
import PlayerStatsDaysWeeks from "./player-stats-days-weeks";
import { ChartInterval } from "components/charts/chart-types";

type PlayerStatsNewProps = {
  memberId: string;
  teamId: string;
  from: number;
  to: number;
  queryInterval: string;
  setDisabledPlayerIds?: (playerIds: string[]) => void;
  onExport?: (data: IntervalMetric[][]) => void;
};

type DataSetRecord = {
  xTime: number;
  xLabel: string;
  xDate: DateTime;
  expl?: number;
  sprint?: number;
  kick?: number;
  motionDist?: number;
  motionAvgSpeed?: number;
  motionPeakSpeed?: number;
}

export default function PlayerStatsNew({
  memberId,
  from,
  to,
  teamId,
  onExport,
  setDisabledPlayerIds
}: PlayerStatsNewProps) {

  const fromtoDuration = to - from;
  const chartInterval =
    fromtoDuration > Duration.fromObject({ days: 14 }).as("milliseconds") ? ChartInterval.WEEK :
    fromtoDuration > Duration.fromObject({ days: 1 }).as("milliseconds") ? ChartInterval.DAY :
    fromtoDuration > Duration.fromObject({ hour: 4 }).as("milliseconds") ? ChartInterval.HOUR : ChartInterval.MINUTE;

  const minsDuration = chartInterval === ChartInterval.MINUTE || chartInterval === ChartInterval.HOUR;

  return (
    <> 
      { minsDuration
      ? <PlayerStatsMins
        memberId={memberId}
        from={from}
        to={to}
        teamId={teamId}
        onExport={onExport}
        setDisabledPlayerIds={setDisabledPlayerIds}
         />
      : <PlayerStatsDaysWeeks
        memberId={memberId}
        from={from}
        to={to}
        teamId={teamId}
        chartInterval={chartInterval}
        onExport={onExport}
        setDisabledPlayerIds={setDisabledPlayerIds}
        />
      }
    </>
  );
}
