import CONFIG from "../../config";
import { send, getFormHeaders, sendWithAuth } from "services/commons.api";
import { ILoginUserData, User } from "./user.model";

export const loginUser = (username: string, password: string) => {
  const loginFormData = new URLSearchParams();
  loginFormData.append("grant_type", "password");
  loginFormData.append("username", username);
  loginFormData.append("password", password);
  loginFormData.append("validator_id", "adidas");
  loginFormData.append("client_id", CONFIG.API.USER.CLIENT_ID);
  loginFormData.append("sourceId", CONFIG.API.USER.SOURCE_ID);

  return send<ILoginUserData>({
    apiEndpoint: CONFIG.API.USER.LOGIN,
    body: loginFormData,
    headers: getFormHeaders(),
  });
};

export const loginWithCode = (code: string) => {
  const loginFormData = new URLSearchParams();
  loginFormData.append("code", code);
  loginFormData.append("redirect_uri", `https://${window.location.host}`);
  loginFormData.append("grant_type", "authorization_code");
  loginFormData.append("client_id", CONFIG.API.USER.CLIENT_ID);

  return send<ILoginUserData>({
    apiEndpoint: CONFIG.API.USER.LOGIN,
    headers: getFormHeaders(),
    body: loginFormData,
  });
};

export const loginWithRefresh = (token: string) => {
  const loginFormData = new URLSearchParams();
  loginFormData.append("grant_type", "refresh_token");
  loginFormData.append("refresh_token", token);
  loginFormData.append("validator_id", "adidas");
  loginFormData.append("client_id", CONFIG.API.USER.CLIENT_ID);

  return send<ILoginUserData>({
    apiEndpoint: CONFIG.API.USER.LOGIN,
    body: loginFormData,
    headers: getFormHeaders(),
  });
};