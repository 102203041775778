import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Typography,
  Button,
  TextField,
  LinearProgress,
  Grid,
  Radio,
  Avatar,
} from "@mui/material";
import ProgressBar from "components/progress-bar.component";
import { validateTeamName } from "store/user-teams/user-teams.model";
import { queryClient, useAuthMutation, useAuthQuery } from "providers/auth";
import { createTeam, joinTeam } from "store/user-teams/teams.query";
import { CircularProgress } from "@mui/material";
import AdidasLogoIcon from "components/icons/AdidasLogoIcon";
import { Trans } from "@lingui/macro";
import { OnboardingLeftContent } from "./components/OnboardingLeftContent";
import { getGenericTeams } from "store/team/team.query";

const OnboardingTeam = () => {
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [teamNameError, setTeamNameError] = useState("");
  const [teamName, setTeamName] = useState("");
  const mutation = useAuthMutation("createTeams", createTeam);
  const joinTeamMutation = useAuthMutation("joinTeam", joinTeam);
  const history = useHistory();
  const root = "/onboarding/landing";
  const query = new URLSearchParams(window.location.search);
  const redirectTo = query.get("redirect") || root;
  const [selectedTeam, setSelectedTeam] = useState("");
  const teamsQuery = useAuthQuery(
    ["genericTeams", teamName],
    getGenericTeams(teamName),
    {
      enabled: !!teamName && !teamNameError,
    },
  );

  useEffect(() => {
    if (teamName && !teamNameError) {
      setSubmitEnabled(true);
    } else {
      setSubmitEnabled(false);
    }
  }, [teamName, teamNameError]);

  useEffect(() => {
    if (mutation.isSuccess) {
      queryClient
        .invalidateQueries("teams")
        .then(() => history.push(redirectTo));
    } else if (mutation.isError) {
      setTeamNameError("Failed to save data. Try again later.");
    }
  }, [mutation.isSuccess, mutation.isError, history, redirectTo]);


  useEffect(() => {
    if (joinTeamMutation.isSuccess) {
      history.push("/logout");
    } else if (mutation.isError) {
      setTeamNameError("Failed to save data. Try again later.");
    }
  }, [joinTeamMutation.isSuccess, mutation.isError, history, redirectTo]);

  const onTeamNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const v8n = validateTeamName(value);
    if (v8n) {
      setTeamNameError(v8n);
    } else {
      setTeamNameError("");
      setTeamName(value);
    }
  };

  const handleSignOut = () => {
    history.push("/logout");
  };

  const submit = async () => {
    mutation.mutate({ teamName });
  };
  return (
    <div className={"login"}>
      <div className="login-leftSide login-part">
        <OnboardingLeftContent />
      </div>
      <div className="login-rightSide login-part">
        <div className={"login-content"}>
          <div className="topHeader">
            <div className={"login-logo__container"}>
              <AdidasLogoIcon
                onClick={() =>
                  (window.location.href = "https://www.adidas.com/us")
                }
                height={60}
              />
            </div>
            <Typography variant="h1">TEAM FX</Typography>
          </div>
          <Grid container direction="column" spacing={4}>
            <Grid item>
              <ProgressBar total={3} current={2} />
            </Grid>
            <Grid item width="fit-content">
              <Typography variant="h3">
                <Trans>CREATE OR JOIN TEAM</Trans>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                <Trans>Your team name.</Trans>
              </Typography>
            </Grid>
            <Grid item width="fit-content">
              <Grid container direction="column" spacing={3}>
                <Grid item>
                  <TextField
                    variant="filled"
                    label={"Team Name"}
                    onChange={onTeamNameChange}
                    helperText={teamNameError}
                    error={Boolean(teamNameError)}
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  {teamsQuery.isLoading && <LinearProgress/>}
                  {teamsQuery.data && (
                    <Grid container direction="column" spacing={2}>
                      {teamsQuery.data.teams
                        .sort(
                          (a, b) => b.membersCount || 0 - (a.membersCount || 0),
                        )
                        .slice(0, 3)
                        .map((team) => (
                          <Grid item key={team.teamId} >
                            <Grid container direction="row" alignItems="center" spacing={1}>
                              <Grid item>
                                  <Radio onClick={() => setSelectedTeam(team.teamId)} checked={selectedTeam === team.teamId} />
                              </Grid>
                              <Grid item>
                                <Avatar src={team.avatar || ""}/>
                              </Grid>
                              <Grid item>
                                <Grid container direction="column">
                                  <Grid item>
                                <Typography variant="body1">
                                  {team.teamName}
                                </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography variant="body2" color="GrayText">
                                    {team.membersCount} Members Owner {team.ownerInitials}
                                  </Typography>
                                </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}
                    </Grid>
                  )}
                </Grid>

                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={!submitEnabled || mutation.isLoading || teamsQuery.isLoading}
                    onClick={() => joinTeamMutation.mutate(selectedTeam)}
                    style={{ height: 60 }}
                    fullWidth
                  >
                    {!mutation.isLoading ? (
                      <Trans>Join team</Trans>
                    ) : (
                      <CircularProgress size={20} />
                    )}
                  </Button>
                </Grid>

                <Grid item alignItems="center">
                  <Typography variant="body1" paddingLeft="45%">OR</Typography>
                </Grid>
                <Grid item>
                  <Button
                    color="secondary"
                    variant="contained"
                    disabled={!submitEnabled || mutation.isLoading || teamsQuery.isLoading}
                    onClick={submit}
                    style={{ height: 60 }}
                    fullWidth
                  >
                    {!mutation.isLoading ? (
                      <Trans>Create team</Trans>
                    ) : (
                      <CircularProgress size={20} />
                    )}
                  </Button>
                </Grid>
                <Grid item>
                  <Typography variant="body2">
                    <Trans>
                      If you were trying to join the team, but not able to find it,
                      please press the logout button below and ask coach for
                      team join link.
                    </Trans>
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    style={{
                      height: 60,
                    }}
                    onClick={handleSignOut}
                    fullWidth
                  >
                    <Trans>Logout</Trans>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default OnboardingTeam;
