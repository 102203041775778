import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Select, Typography, Button, MenuItem, Grid } from "@mui/material";
import { FormControl, LinearProgress } from "@mui/material";
import { useAuthQuery } from "providers/auth";
import { getTeams } from "store/user-teams/teams.query";
import { TeamMemberStatus } from "store/team-members/team-members.model";
import AdidasLogoIcon from "components/icons/AdidasLogoIcon";
import { Trans } from "@lingui/macro";
import ProgressBar from "components/progress-bar.component";
import { OnboardingLeftContent } from "./components/OnboardingLeftContent";

const OnboardingSelectTeam = () => {
  const [teamId, setTeamId] = useState("");
  const teamsQuery = useAuthQuery("teams", getTeams);
  const { isSuccess, data } = teamsQuery;
  const history = useHistory();
  const query = new URLSearchParams(window.location.search);
  const redirectTo = query.get("redirect");

  useEffect(() => {
    const approvedTeams =
      data?.userTeams.filter(
        (team) => team.status === TeamMemberStatus.APPROVED,
      ) || [];
    setTeamId(approvedTeams.length > 0 ? approvedTeams[0].teamId : "");
  }, [data, isSuccess]);

  const onSelectChange = (_event: unknown, option: any) => {
    setTeamId(option.props.value);
  };

  const submit = () => {
    history.push(redirectTo || `/onboarding/landing/${teamId}`);
  };

  return (
    <div className={"login"}>
      <div className="login-leftSide login-part">
        <OnboardingLeftContent />
      </div>
      <div className="login-rightSide login-part">
        <div className={"login-content"}>
          <div className="topHeader">
            <div className={"login-logo__container"}>
              <AdidasLogoIcon
                onClick={() =>
                  (window.location.href = "https://www.adidas.com/us")
                }
                height={60}
              />
            </div>
            <Typography variant="h1">TEAM FX</Typography>
          </div>
          {teamsQuery.isSuccess ? (
            <>
              <Grid container direction="column" spacing={4}>
                <Grid item>
                  <ProgressBar total={10} current={6} />
                </Grid>
                <Grid item style={{ width: "fit-content" }}>
                  <Typography variant="h3" >
                    <Trans>Choose a team</Trans>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1">
                    <Trans>Select a team you want to work with</Trans>
                  </Typography>
                </Grid>
                <Grid item>
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      value={teamId}
                      onChange={onSelectChange}
                      IconComponent={ExpandMoreIcon}
                    >
                      {teamsQuery.data.userTeams
                        .filter(({ status }) => status === "APPROVED")
                        .map((team) => (
                          <MenuItem
                            key={`team-select-menu-item-${team.teamId}`}
                            value={team.teamId}
                            selected={team.teamId === teamId}
                          >
                            <Typography variant="body2">
                              {team.teamName}
                            </Typography>
                          </MenuItem>
                        ))}
                      ,
                      <MenuItem>
                        <Button
                          variant="outlined"
                          onClick={() => history.push("/onboarding/team")}
                          fullWidth
                        >
                          <Trans>CREATE TEAM</Trans>
                        </Button>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={submit}
                    style={{ height: 60 }}
                    fullWidth
                  >
                    <Trans>Continue</Trans>
                  </Button>
                </Grid>
              </Grid>
            </>
          ) : (
            <LinearProgress />
          )}
        </div>
      </div>
    </div>
  );
};

export default OnboardingSelectTeam;
