import * as core from "@mui/material";
import { withStyles } from "@mui/styles";
import { font } from "theme";

export const FeatureTab = withStyles((theme) => ({
  root: {
    "font-size": "18px",
    lineHeight: "28px",
    "font-family": font.adihaus.regular,
    color: "#666666",
    fontWeight: 900,
  },
  selected: {
    "font-size": "18px",
    lineHeight: "28px",
    "font-family": font.adihaus.regular,
    width: "fit-content",
    fontWeight: 900,
    color: "#51F69D",
  },
}))(core.Tab);
