import { Stack, Typography } from "@mui/material";

export type StatsVSStatsTitleProps = {
  stat1Title: string;
  stat1Icon: JSX.Element;
  stat2Title: string;
  stat2Icon: JSX.Element;
}

export const StatsVSStatsTitle = (props: StatsVSStatsTitleProps) => {
  return <Stack direction="row"
    spacing={1}
    justifyContent="flex-start">
      {props.stat1Icon}
      <Typography variant="h6" style={{ fontStyle: "italic"}}>{props.stat1Title} vs.</Typography>
      {props.stat2Icon}
      <Typography variant="h6" style={{ fontStyle: "italic"}}>{props.stat2Title}</Typography>
  </Stack>;
};
