import { useEffect, useState } from "react";
import { Stack, Switch, Typography } from "@mui/material";
import { DateTime } from "luxon";
import Cookies from "js-cookie";

import { IGetTeamMembersData } from "store/team-members/team-members.model";
import { useQueries } from "react-query";
import { getMemberStats } from "store/member-stats/member-stats.query";
import { IntervalMetric } from "store/member-stats/member-stats.model";
import ComparisonNew from "./comparison-new";
import ComparisonOld from "./comparison-old";

const chartRunZonesLabels = ["WALK", "JOG", "RUN", "HIGH_SPEED_RUN", "SPRINT"];
const chartBallZonesLabels = ["COLD", "MEDIUM", "HOT", "FIRE"];

type ComparisonProps = {
  teamMembers?: IGetTeamMembersData;
  to: DateTime;
  from: DateTime;
  queryInterval: string;
  teamId: string;
  playerIds: string[];
  setDisabledPlayerIds?: (playerIds: string[]) => void;
  onExport?: (data: IntervalMetric[][]) => void;
};
export default function Comparison({
  to,
  from,
  queryInterval,
  teamId,
  playerIds,
  teamMembers,
  setDisabledPlayerIds,
  onExport
}: ComparisonProps) {
  const [showNewCharts, setShowNewCharts] = useState(true);
  const token = Cookies.get("jwtToken") || "";
  const membersStats = useQueries(
    playerIds
      .filter((memberId) => !!memberId)
      .map((memberId) => {
        return {
          queryKey: ["memberStats", teamId, memberId, from, to, queryInterval],
          queryFn: getMemberStats({
            teamId,
            memberId,
            from: from.valueOf(),
            to: to.valueOf(),
            interval: queryInterval,
          })(token),
        };
      }),
  );
  const isLoading = membersStats.some((predicate) => predicate.isLoading);

  useEffect(() => {
    if (membersStats.every((q) => q.isSuccess)) {
      onExport?.(membersStats.map((q) => q.data?.data || []));
    }
  }, [...membersStats]);

  useEffect(() => {
    if (!isLoading) {
      const disabledPlayers = playerIds.filter(
        (playerId, index) =>
          !membersStats[index].data?.data?.length,
      );
      setDisabledPlayerIds?.(disabledPlayers);
    }
  }, membersStats);

  return (
    <>
      <Stack direction="row">
        <Switch defaultChecked={true} onClick={() => setShowNewCharts(!showNewCharts)} checked={showNewCharts} title="Use new graphs" />
        <Typography style={{ paddingBottom: 20 }}>Use new graphs</Typography>
      </Stack>
          { showNewCharts && <ComparisonNew 
              teamId={teamId}
              from={from.toMillis()}
              to={to.toMillis()}
              queryInterval={queryInterval}
              teamMembers={teamMembers}
              playerIds={playerIds}
              setDisabledPlayerIds={setDisabledPlayerIds}
              onExport={onExport}
              /> }
          { !showNewCharts && <ComparisonOld 
              teamId={teamId}
              from={from}
              to={to}
              queryInterval={queryInterval}
              teamMembers={teamMembers}
              playerIds={playerIds}
              setDisabledPlayerIds={setDisabledPlayerIds}
              onExport={onExport}
              /> }
        </>
  );
}
