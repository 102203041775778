import { getHeaders, isError, send } from "services/commons.api";
import { GenericTrophy, Trophy } from "./trophies.model";
import config from "config";

export const getGenericTrophies = (token: string) => (_context) =>
  send<{ trophies: GenericTrophy[] }>({
    apiEndpoint: config.API.GENERIC_TROPHIES.GET,
    headers: getHeaders(token),
  }).then((v) => {
    if (isError(v)) {
      throw v;
    }

    return v.data;
  });

export const getTrophies =
  (params: { teamId?: string; playerId?: string }) =>
  (token: string) =>
  (_context) =>
    send<{ data: Trophy[] }>({
      apiEndpoint: config.API.TROPHIES.GET,
      urlParams: {
        teamId: params.teamId || "",
        playerId: params.playerId || "",
      },
      headers: getHeaders(token),
    }).then((v) => {
      if (isError(v)) {
        throw v;
      }

      return v.data;
    });
