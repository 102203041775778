import { AxisConfig, BarPlot, BarSeriesType, ChartsAxisHighlight, ChartsGrid, ChartsLegend,
  ChartsReferenceLine, ChartsTooltip, ChartsXAxis, ChartsXAxisProps, ChartsYAxis, ResponsiveChartContainer } from "@mui/x-charts";

import { ActiveChartColors, axisXTickFormat, showMinuteTick } from "./style-constants";
import { ChartInterval, StatDef } from "./chart-types";
import { GenderZones } from "store/speed-zones/speed-zones.model";
import { BALL_BARS_COLORS } from "./line-speedzone-line";
import { BackgroundBars } from "./background-bars";


export interface AtomicMetricsBarsProps {
  dataSet: Array<any>;
  dataKeys: Array<StatDef>;
  chartInterval?: ChartInterval; // minute by default
  barColor?: string;
  measure: string;
  zones?: GenderZones;
}

export const KickMetricsBars = (props: AtomicMetricsBarsProps) => {
  
  const series = new Array<BarSeriesType>();
  props.dataKeys.forEach( stat => {
    series.push({
      type: "bar",
      dataKey: stat.dataKey,
      label: stat.label,
      color: props.barColor ?? ActiveChartColors[props.dataKeys.indexOf(stat)]
    });
  });

  return <ResponsiveChartContainer
    //margin={{ top: 5, left: 50, right: 5, bottom: 20 }}
    height = {300}
    className="MuiResponsiveChart-container"
    series = {series}
    xAxis = {[
      { 
        scaleType: "band", 
        id: "x-axis-id", disableTicks: true, dataKey: "xLabel",
        categoryGapRatio: 0.75,
        barGapRatio: 0.1,
        tickLabelInterval: (value) => showMinuteTick(value as string, props.chartInterval),
        valueFormatter: axisXTickFormat, tickLabelPlacement: "middle"
      } as AxisConfig<"band", unknown, ChartsXAxisProps>
    ]}
    dataset={props.dataSet}
    yAxis={[
      { id: "meters", position: "left", disableLine: true, disableTicks: true, label: props.measure }
    ]}
  >
    <BackgroundBars
      x={props.dataSet.map((el) => el["xLabel"])} />
    <ChartsXAxis position="bottom" axisId="x-axis-id" tickSize={0} />
    <ChartsYAxis label="M" position="left" axisId="meters" />
    <ChartsReferenceLine
      y={props.zones?.COLD[0] || 0 } label={props.zones?.COLD[0].toString(10)}
      labelAlign="end" lineStyle={{ stroke: BALL_BARS_COLORS[0] }} labelStyle={{ fontSize:  10}} />
    <ChartsReferenceLine
      y={props.zones?.MEDIUM[0] || 0 } label={props.zones?.MEDIUM[0].toString(10)}
      labelAlign="end" lineStyle={{ stroke: BALL_BARS_COLORS[1] }} labelStyle={{ fontSize:  10}} />
    <ChartsReferenceLine
      y={props.zones?.HOT[0] || 0 } label={props.zones?.HOT[0].toString(10)}
      labelAlign="end" lineStyle={{ stroke: BALL_BARS_COLORS[2] }} labelStyle={{ fontSize:  10}} />
    <ChartsReferenceLine
      y={props.zones?.FIRE[0] || 0 } label={props.zones?.FIRE[0].toString(10)}
      labelAlign="end" lineStyle={{ stroke: BALL_BARS_COLORS[3] }} labelStyle={{ fontSize:  10}} />
    <ChartsGrid horizontal />
    <ChartsAxisHighlight x="band" />
    <ChartsTooltip trigger="axis"  />
    <BarPlot slotProps={{ bar: { width: "8px" }}} />
    <ChartsLegend
      position={{vertical:"bottom", horizontal:"middle" }}
      slotProps={{legend: { direction: "row", hidden: series.length < 2 }}}
    />
  </ResponsiveChartContainer>;
};
