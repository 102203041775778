import React from "react";
import { Redirect } from "react-router-dom";
import { useAnalytics } from "use-analytics";
import { queryClient, resetAuthorization } from "providers/auth";

const Logout = () => {
  const analytics = useAnalytics();

  analytics.track("logout");
  analytics.reset();
  queryClient.clear();
  resetAuthorization();

  return <Redirect to="/login" />;
};

export default Logout;
