import { AxisValueFormatterContext } from "@mui/x-charts/models/axis";
import { ChartInterval } from "./chart-types";

export const ActiveChartColors = {
  [0]: "#5D8EFB",
  [1]: "#F1C638",
  [2]: "#78D857",
  [3]: "#D75B8D"
};

export const InactiveChartColors = {
  [0]: "#2E3D5E",
  [1]: "#5B4E23",
  [2]: "#36532C",
  [3]: "#532E3D"
};

export const TICK_INTERVAL = 5;

export const axisXTickFormat = (time: any, context: AxisValueFormatterContext) => {
  if (context.location === "tick" || context.location === "tooltip") {
    return time.replace(" ","\n");
  }
};

export const showMinuteTick = (value: string, chartInterval?: ChartInterval) =>
  chartInterval === ChartInterval.MINUTE ? ( value ? value.endsWith("0") || value.endsWith("5") : false ) :
  chartInterval === ChartInterval.HOUR ? ( value ? value.endsWith("00") || value.endsWith("30") : false ) : true;
