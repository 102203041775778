import * as mui from "@mui/material";
import { Grid, MenuItemProps } from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import CheckIcon from "@mui/icons-material/Check";

export const MenuItemSmallCustom = withStyles((theme) => ({
  root: {
    padding: "14px 16px 14px 16px",
    boxShadow: `0px 1px 0px 0px ${theme.palette.text.hint}`,
    width: 120,
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "12px",
    letterSpacing: "0px",
    textAlign: "left",
    textTransform: "capitalize",
  },
}))(mui.MenuItem);

export const MenuItemSmallSelectCustom = React.forwardRef(
  ({ selected, ...props }: MenuItemProps) => {
    if (selected) {
      return (
        <MenuItemSmallCustom {...props}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>{props.children}</Grid>
            <Grid item>
              <CheckIcon fontSize="small" color="primary" />
            </Grid>
          </Grid>
        </MenuItemSmallCustom>
      );
    }

    return <MenuItemSmallCustom {...props} />;
  },
);
