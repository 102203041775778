import { createTheme } from "@mui/material/styles";

import theme, { font } from "theme";

export const landingTheme = createTheme({
  typography: {
    fontFamily: [font.adineue.cond, font.adihaus.medium].join(","),
    fontSize: 16,
    h1: {
      fontFamily: font.adihaus.regular,
      textAlign: "left",
      textTransform: "uppercase",
    },
    h2: {
      fontFamily: font.adihaus.regular,
      fontSize: "35px",
      fontWeight: 500,
      fontStyle: "italic",
      lineHeight: "45px",
      align: "center",
      color: "#FFFFFF",
    },
    h3: {
      fontFamily: font.adineue.bold,
      fontSize: 56,
      textTransform: "uppercase",
    },
    h4: {
      fontFamily: font.adineue.cond,
      fontSize: 44,
      textTransform: "uppercase",
    },
    h5: {
      fontFamily: font.adihaus.medium,
      fontSize: "18px",
      lineHeight: "23.18px",
      color: "#FFFFFF",
    },
    h6: {
      fontFamily: font.adihaus.medium,
      fontSize: "14px",
      lineHeight: "23.18px",
      color: "#FFFFFF",
    },
    subtitle1: {
      fontSize: 20,
      fontFamily: font.adihaus.medium,
    },
    subtitle2: {
      fontSize: "16px",
      fontFamily: font.adihaus.medium,
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "22px",
    },
    body1: {
      fontSize: 20,
      fontFamily: font.adihaus.regular,
    },
    body2: {
      fontSize: 16,
      fontFamily: font.adihaus.regular,
    },
    button: {
      fontSize: 18,
      fontFamily: font.adihaus.regular,
    },
    caption: {
      fontSize: 20,
      fontFamily: font.adihaus.regular,
    },
    overline: {
      fontSize: 16,
      fontFamily: font.adihaus.regular,
    },
  },
  shape: {
    borderRadius: 0,
  },
  palette: {
    mode: "dark",
    primary: {
      dark: "#39AC6E",
      light: "#B8FED8",
      main: "#51F69D",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#5189F6",
      dark: "#3960AC",
      light: "#91FFFF",
      contrastText: "#0B0C0D",
    },
    background: {
      default: "#0B0C0D",
      paper: "#25272a",
    },
    text: {
      primary: theme.text.primary,
      secondary: theme.text.secondary,
    },
    divider: "#222528",
  },
});
