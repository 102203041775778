import AdidasLogoIcon from "../../../components/icons/AdidasLogoIcon";
import { Trans } from "@lingui/macro";

export const OnboardingLeftContent = () => (
  <div className={"login-content"}>
    <div className={"login-logo__container"}>
      <AdidasLogoIcon
        onClick={() => (window.location.href = "https://www.adidas.com/us")}
        height={60}
      />
    </div>
    <p>
      <span className="onboardingTitle">
        <Trans>SUPERCHARGE</Trans>
      </span>
      <br />
      <span className="onboardingTitle">
        <Trans>YOUR TEAM</Trans>
      </span>
      <br />
      <span className="onboardingTitle">
        <Trans>PERFORMANCE</Trans>
      </span>
    </p>
  </div>
);
