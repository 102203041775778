import React from "react";
import styled from "styled-components";
import theme from "../theme";

const BarContainer = styled.div`
  text-align: center;
  display: table;
  max-width: 450px;
  width: 100%;
  height: 4px;
`;

const BarFulfilled = styled.div`
  background: ${theme.actions.primary};
  display: table-cell;
  height: 4px;
  width: auto;
`;

const BarPending = styled.div`
  background: ${theme.background.secondary};
  display: table-cell;
  height: 4px;
  width: auto;
`;

interface IProps {
  total: number;
  current: number;
}

const ProgressBar: React.FunctionComponent<IProps> = ({ total, current }) => (
  <React.Fragment>
    <BarContainer>
      {[...Array(current)].map((v, index) => (
        <BarFulfilled key={`${index}`} />
      ))}
      {[...Array(total - current)].map((v, index) => (
        <BarPending key={`${current + index}`} />
      ))}
    </BarContainer>
  </React.Fragment>
);

export default ProgressBar;
