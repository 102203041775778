import { useEffect } from "react";
import { Button, Grid, LinearProgress, Typography } from "@mui/material";
import {
  AppleButton,
  FacebookButton,
  GoogleButton,
} from "components/social-button.component";
import { useHistory } from "react-router-dom";
import CONFIG from "config";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Trans } from "@lingui/macro";
import theme from "theme";
import "./login.style.css";
import { OnboardingLeftContent } from "../onboarding/components/OnboardingLeftContent";
import { useAnalytics } from "use-analytics";
import { authorizeByCode, getToken } from "providers/auth";

enum SocialAdapters {
  FACEBOOK = "facebookAdiComposite",
  GOOGLE = "googleAdiComposite",
  APPLE = "appleAdiComposite",
}

export default function LoginSelect() {
  const analytics = useAnalytics();
  const authToken = getToken().jwtToken || "";
  const history = useHistory();
  const query = new URLSearchParams(window.location.search);
  const root = "/onboarding/landing";
  const redirectTo = query.get("redirect") || root;
  const { host } = window.location;

  const createSocialLoginLink = (adapter: SocialAdapters) => {
    const params = {
      client_id: CONFIG.API.USER.CLIENT_ID,
      response_type: "code",
      redirect_uri: host.includes("adidasteamfx")
        ? "https://www.adidasteamfx.com"
        : `https://${host}`,
      source: CONFIG.API.USER.SOURCE_ID,
      country: "DE",
      language: navigator.language.slice(0, 2),
      pfidpadapterid: adapter,
    };

    const query = Object.keys(params)
      .map((key: string) => `${key}=${(params as any)[key]}`)
      .join("&");

    return `${CONFIG.AUTHORIZER.SOCIAL_LOGIN.baseUrl}/${CONFIG.AUTHORIZER.SOCIAL_LOGIN.url}?${query}`;
  };

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const code = query.get("code");

    if (code) {
      const redirectLocal = localStorage.getItem("redirect");
      localStorage.removeItem("redirect");
      authorizeByCode(code).then(() => {
        history.push(redirectLocal || redirectTo);
      });
    }

    if (authToken && authToken.length > 0) {
      history.push(redirectTo);
    }
  }, [authToken, history, redirectTo]);

  const toManualLogin = () => {
    analytics.track("login-manual");
    history.push(`/login/manual?redirect=${redirectTo}`);
  };

  const loginSocial = (adapter: SocialAdapters) => () => {
    const link = createSocialLoginLink(adapter);

    localStorage.setItem("redirect", redirectTo);

    analytics.track(`login-${adapter.toLowerCase()}`);

    return window.open(link, "_blank");
  };

  return (
    <div className={"login"}>
      <div className="login-leftSide login-part firstScreen">
        <OnboardingLeftContent />
      </div>
      <div className="login-rightSide login-part">
        <div className={"login-content"}>
          <Grid container direction="column" spacing={4} maxWidth={450}>
            <Grid item>
              <LinearProgress variant="determinate" value={25} />
            </Grid>
            <Grid item width="fit-content">
              <Typography variant="h3">
                <Trans>Get set up on teams FX</Trans>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                <Trans>
                  Track, compare, analyze your performance and push yourself to
                  the top of the leaderboard. Login first to get set up on
                  Adidas TEAM FX Team
                </Trans>
              </Typography>
            </Grid>
            <Grid item>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                spacing={2}
              >
                <Grid item>
                  <FacebookButton
                    onClick={loginSocial(SocialAdapters.FACEBOOK)}
                  />
                </Grid>
                <Grid item>
                  <GoogleButton onClick={loginSocial(SocialAdapters.GOOGLE)} />
                </Grid>
                <Grid item >
                  <AppleButton onClick={loginSocial(SocialAdapters.APPLE)} />
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: 350,
                    color: theme.text.secondary,
                  }}
                >
                  <hr
                    style={{
                      flex: 1,
                      backgroundColor: theme.text.secondary,
                      border: 0,
                      height: 1,
                    }}
                  />
                  <span style={{ margin: "0 8px" }}>
                    <Typography variant="subtitle2">OR</Typography>
                  </span>
                  <hr
                    style={{
                      flex: 1,
                      backgroundColor: theme.text.secondary,
                      border: 0,
                      height: 1,
                    }}
                  />
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="outlined"
                    style={{
                      height: 60,
                      width: 400,
                    }}
                    onClick={toManualLogin}
                  >
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="center"
                      spacing={1}
                    >
                      <Grid item style={{ paddingTop: 18 }}>
                        <MailOutlineIcon fontSize="large" />
                      </Grid>
                      <Grid
                        item
                        style={{ fontFamily: theme.font.regular, fontSize: 20 }}
                      >
                        <Trans>Login with Email</Trans>
                      </Grid>
                    </Grid>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
