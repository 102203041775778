import React from "react";
import { Avatar, Button, ButtonProps, Grid, Typography } from "@mui/material";
import theme from "../../theme";

export type EventTypeButtonProps = {
  selected: boolean;
  icon: React.ReactNode;
  text: string;
} & ButtonProps;

export const EventTypeButton = ({
  selected,
  icon,
  text,
  ...props
}: EventTypeButtonProps) => (
  <Button
    {...props}
    style={{
      ...props.style,
      border: selected ? `1px solid ${theme.actions.primary}` : "",
    }}
  >
    <Grid container alignItems="center" spacing={1}>
      <Grid item>
        <Avatar
          style={{
            color: selected ? theme.background.primary : theme.text.primary,
            fill: selected ? theme.text.primary : theme.background.primary,
            backgroundColor: selected
              ? theme.actions.primary
              : theme.background.secondary,
          }}
        >
          <div
            style={{
              filter: selected
                ? ""
                : "invert(0%) sepia(0%) saturate(0%) hue-rotate(165deg) brightness(1000%) contrast(0%)",
            }}
          >
            {icon}
          </div>
        </Avatar>
      </Grid>
      <Grid item>
        <Typography variant="body2" style={{ textTransform: "none" }}>
          {text}
        </Typography>
      </Grid>
    </Grid>
  </Button>
);
