import { DateTime } from "luxon";

export enum TrophyLevel {
  "basic" = "basic",
  "bronze" = "bronze",
  "silver" = "silver",
  "gold" = "gold",
  "legendary" = "legendary",
}

export const TrophyLevelToWeight = {
  [TrophyLevel.basic]: 1,
  [TrophyLevel.bronze]: 2,
  [TrophyLevel.silver]: 3,
  [TrophyLevel.gold]: 4,
  [TrophyLevel.legendary]: 5,
};

export enum TrophyId {
  SPEEDSTER = "SPEEDSTER",
  KICK_CHAMPION = "KICK_CHAMPION",
  MARATHONER = "MARATHONER",
  FLEET_FEET = "FLEET_FEET",
  SPRINT_SUPERSTAR = "SPRINT_SUPERSTAR",
  STAMINA_STAR = "STAMINA_STAR",
  BURST_RUNNER = "BURST_RUNNER",
  POWER_HORSE = "POWER_HORSE",
  KICK_LEGEND = "KICK_LEGEND",
  DISTANCE_HERO = "DISTANCE_HERO",
  RAPID_RUNNER = "RAPID_RUNNER",
  GOLDEN_GLOVE = "GOLDEN_GLOVE",
  FEEDBACK_PRO = "FEEDBACK_PRO",
  TAG_MASTER = "TAG_MASTER",
  TRAINING_CHAMP = "TRAINING_CHAMP",
  MATCH_VETERAN = "MATCH_VETERAN",
  BUSY_BEE = "BUSY_BEE",
  TEAM_LEGEND = "TEAM_LEGEND",
  EARLY_BIRD = "EARLY_BIRD",
  NIGHT_OWL = "NIGHT_OWL",
  DETERMINED_DYNAMO = "DETERMINED_DYNAMO",
  ROCKET_FOOT = "ROCKET_FOOT",
  SPEED_DEMON = "SPEED_DEMON",
  ENDURANCE_CHAMP = "ENDURANCE_CHAMP",
  KICK_COLLECTIVE = "KICK_COLLECTIVE",
  CHEETAH_CREW = "CHEETAH_CREW",
  BOOM_BRIGADE = "BOOM_BRIGADE",
  FEEDBACK_FORCE = "FEEDBACK_FORCE",
  DATA_CHAMPS = "DATA_CHAMPS",
}

export const getSeason = (timestamp: number) =>
  `${DateTime.fromMillis(timestamp).toFormat("yyyy")}-${DateTime.fromMillis(
    timestamp,
  )
    .plus({ year: 1 })
    .toFormat("yyyy")}`;

export const NONE_SEASON = "NONE_SEASON";

export type TrophyBase = {
  season: string;
  trophyId: string;
  currentValue: string;
  measure_name?: string;
  time?: number;
  eventId?: string;
};

export type PlayerTrophy = {
  playerId: string;
} & TrophyBase;

export type TeamTrophy = {
  teamId: string;
} & TrophyBase;

export type Trophy = PlayerTrophy | TeamTrophy;

export const isPlayerTrophy = (trophy: Trophy): trophy is PlayerTrophy => {
  return (trophy as PlayerTrophy).playerId !== undefined;
};

export type GenericTrophy = {
  trophyId: TrophyId;
  category: string;
  trophyTitle: string;
  levels: Partial<Record<TrophyLevel, number>>;
};
