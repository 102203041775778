import React from "react";
import { Typography, Box, Grid } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { isAndroid, isIOS } from "react-device-detect";

import AdidasLogoIcon from "components/icons/AdidasLogoIcon";
import Link from "components/link.component";
import backImageWeb from "images/Shoe-Web.png";
import backImageMobile from "images/Shoe-Mobile.png";
import appStoreIcon from "images/AppStore-Badge.png";
import playMarketIcon from "images/PlayMarket-Badge.png";
import useWindowDimensions from "hooks/useWindowDimensions";
import { Trans } from "@lingui/macro";

const MobileAppDownload = () => {
  const { height, width } = useWindowDimensions();

  const AN_URL = "https://play.google.com/store/apps/details?id=com.adidas.gmr";
  const iOS_URL = "https://apps.apple.com/de/app/adidas-gmr/id1481945303";
  if (isAndroid) {
    window.location.assign(AN_URL);
  } else if (isIOS) {
    window.location.assign(iOS_URL);
  }

  const bigSize = useMediaQuery("(min-width:1080px)");
  const screenRatio = width / height;
  const imageSize = screenRatio > 1.5 ? 9 : screenRatio > 1.2 ? 11 : 12;
  const useBigImage = Boolean(bigSize || screenRatio > 0.8);

  return (
    <Box
      style={{
        background: "#000000",
        position: "relative",
        width: "100%",
        height: "100%",
      }}
    >
      {useBigImage ? (
        <Box style={{ right: "0px", bottom: "0px", position: "absolute" }}>
          <Grid
            container
            direction="column"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Grid item xs={imageSize}>
                  <img alt="" width="100%" src={backImageWeb}></img>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box style={{ bottom: "0px", position: "absolute" }}>
          <Grid
            container
            direction="column"
            justifyContent="flex-end"
            alignItems="stretch"
          >
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-end"
              >
                <Grid item xs={imageSize}>
                  <img alt="" width="100%" src={backImageMobile}></img>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
      <Box
        style={{
          position: "absolute",
          top: useBigImage ? "5%" : "20px",
          left: "5%",
          width: "100%",
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          spacing={1}
          style={{ width: bigSize ? "35%" : "80%" }}
        >
          <Grid item>
            <AdidasLogoIcon
              onClick={() =>
                (window.location.href = "https://www.adidas.com/us")
              }
            />
          </Grid>
          <Grid item>
            <Typography variant="h4" style={{ color: "#FFFFFF" }}>
              <Trans>WELCOME TO</Trans>
            </Typography>
            <Typography variant="h4" style={{ color: "#E10098" }}>
              <Trans>
                <span style={{ textTransform: "lowercase" }}>adidas</span> TEAM
                FX
              </Trans>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" style={{ color: "#FFFFFF" }}>
              <Trans>
                Download the app to enforce ultimate football team experience
              </Trans>
            </Typography>
          </Grid>
          <Grid item>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <Link href={iOS_URL}>
                  <img
                    height={"56px"}
                    alt="Get it on App Store"
                    src={appStoreIcon}
                  />
                </Link>
              </Grid>
              <Grid item>
                <Link href={AN_URL}>
                  <img
                    height={"56px"}
                    alt="Get it on Google Play"
                    src={playMarketIcon}
                  />
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default MobileAppDownload;
