import { PlayerPosition } from "@gamer/common/lib/models/user";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import theme from "../../theme";
import { PositionChip } from "./position.chip";
import { Trans } from "@lingui/macro";
import { DEFENCE_LIST, MIDFIELD_LIST, OFFENCE_LIST } from "./position-models";

export type PositionProps = {
  position?: PlayerPosition;
  preferredPosition?: PlayerPosition;
  playerName?: string;
  open: boolean;
  handleClose: (position?: PlayerPosition) => void;
};

export const PositionSelectModal = (props: PositionProps) => {
  const { open, handleClose } = props;
  const [value, setValue] = useState(props.position);

  const handleChange = (value: ChangeEvent<HTMLInputElement>) => {
    setValue(value.target.value as PlayerPosition);
  };

  useEffect(() => {
    setValue(props.position);
  }, [props.open, props.position]);

  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      fullWidth={true}
      maxWidth="sm"
      scroll="paper"
      style={{ height: 720 }}
    >
      <DialogTitle id="form-dialog-title">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignContent="center"
        >
          <Grid item>
            <Typography variant="h5">
              <Trans context="Football position on the field">
                CHOOSE {props.playerName} POSITION.
              </Trans>
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={() => handleClose()}>
              <CloseIcon style={{ fontSize: 16 }} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers={true} style={{ width: "fit-content" }}>
        <DialogContentText>
          <FormControl component="fieldset">
            <RadioGroup value={value} onChange={handleChange}>
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  <Grid container direction="column" spacing={1}>
                    <Grid item>
                      <Typography
                        variant="body2"
                        style={{ color: theme.text.primary }}
                      >
                        Offence
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Grid container direction="row">
                        {OFFENCE_LIST.map((position) => (
                          <PositionChip
                            control={<Radio />}
                            key={position}
                            preferred={props.preferredPosition === position}
                            position={position}
                            value={value}
                            playerName={props.playerName}
                          />
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography
                        variant="body2"
                        style={{ color: theme.text.primary }}
                      >
                        Midfield
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Grid container direction="row">
                        {MIDFIELD_LIST.map((position) => (
                          <PositionChip
                            control={<Radio />}
                            key={position}
                            preferred={props.preferredPosition === position}
                            position={position}
                            value={value}
                            playerName={props.playerName}
                          />
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography
                        variant="body2"
                        style={{ color: theme.text.primary }}
                      >
                        Defence
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Grid container direction="row">
                        {DEFENCE_LIST.map((position) => (
                          <PositionChip
                            control={<Radio />}
                            key={position}
                            preferred={props.preferredPosition === position}
                            position={position}
                            value={value}
                            playerName={props.playerName}
                          />
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </RadioGroup>
          </FormControl>
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ padding: 16 }}>
        <Button variant="outlined"
          onClick={() => handleClose()}
        >
          Cancel
        </Button>
        <Button
          
          variant="contained"
          disabled={value === props.position}
          style={{
            color:
              value === props.position
                ? theme.text.secondary
                : theme.background.primary,
          }}
          onClick={() => handleClose(value)}
        >
          <Trans>Save</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
};
