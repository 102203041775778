import { TeamMemberRole } from "store/team-members/team-members.model";

export interface IUserTeamInfo {
  teamId: string;
  playerId: string;
  teamName?: string;
  avatar?: string;
  banner?: string;
  role?: TeamMemberRole;
  status?: string;
}

export type IGetUserTeamsData = {
  userTeams: IUserTeamInfo[];
  totalCount: number;
};

export const validateTeamName = (input: string) => {
  const MAX_NAME_LENGTH = 20;
  const MIN_NAME_LENGTH = 2;
  const reg = /^([a-zA-Z0-9 _-]+)$/;
  if (input.length < MIN_NAME_LENGTH) {
    return `Should be at least length of ${MIN_NAME_LENGTH}`;
  }

  if (input.length > MAX_NAME_LENGTH) {
    return `Should not be more then ${MAX_NAME_LENGTH}`;
  }

  if (!input.match(reg)) {
    return "Should include only letters of alphabet";
  }

  return "";
};
