import * as core from "@mui/material";
import { withStyles } from "@mui/styles";
import { font } from "theme";

export const TopTextWithWhiteBG = withStyles(() => ({
  root: {
    color: "#000000",
    backgroundColor: "#FFFFFF",
    fontSize: "28px",
    fontWeight: 400,
    lineHeight: "48px",
    display: "inline",
    paddingLeft: "8px",
    paddingRight: "8px",
    paddingTop: "8px",
    paddingBottom: "8px",
    "box-decoration-break": "clone",
    maxWidth: "650px",
  },
}))(core.Typography);

export const SolutionText = withStyles(() => ({
  root: {
    fontFamily: font.adihaus.condItalic,
    color: "#FFFFFF",
    fontSize: "80px",
    fontStyle: "italic",
    fontWeight: 300,
  },
}))(core.Typography);

export const BottomFAQText = withStyles(() => ({
  root: {
    color: "#CCCCCC",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "22px",
    wordWrap: "normal",
    textTransform: "uppercase",
    borderBottom: "solid 1px #333333",
  },
}))(core.Typography);

export const TopTextWithBox = (props: core.TypographyProps) => {
  return (
    <core.Box alignContent="center">
      <TopTextWithWhiteBG variant="h1">{props.children}</TopTextWithWhiteBG>
    </core.Box>
  );
};

export const FeatureTitle = withStyles(() => ({
  root: {
    color: "#FFFFFF",
    fontSize: "32px",
    fontWeight: 400,
    lineHeight: "41px",
  },
}))(core.Typography);

export const FeatureDesc = withStyles(() => ({
  root: {
    color: "white",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "23px",
    whiteSpace: "break-spaces",
    maxWidth: "310px",
  },
}))(core.Typography);

export const MobileFeatureDesc = withStyles(() => ({
  root: {
    color: "#FFFFFF",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "20px",
    whiteSpace: "break-spaces",
    maxWidth: "330px",
  },
}))(core.Typography);
