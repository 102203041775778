import { styled } from "@mui/material/styles";
import { useDrawingArea, useXScale } from "@mui/x-charts/hooks";
import { ScaleLinear } from "d3-scale";
import React from "react";

export const StyledPath = styled("path")(
  ({ width }) => ({
    fill: "none",
    stroke: "#1A1A1A",
    shapeRendering: "crispEdges",
    strokeWidth: width,
    pointerEvents: "none",
  }),
);

export const BackgroundBars = ({ x }) => {
  const { top, height, width } = useDrawingArea();
  const xAxisScale = useXScale() as ScaleLinear<any, any>;
  const xTicks = x;

  const bWidth = (width - x.length * 4) / x.length;  // 4px between bars
  const shift = bWidth / 8; // 12% SHIFT
  return (
    <React.Fragment>
      {xTicks.map((value) => (
        <StyledPath
          key={value}
          d={`M ${xAxisScale(value) + shift} ${top + 1} l 0 ${height - 2}`}
          color="secondary"
          width={bWidth}
        />
      ))}

    </React.Fragment>
  );
};