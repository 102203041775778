import React from "react";
import { Bar } from "react-chartjs-2";
import { muiTheme } from "../../theme";

export type ActivityEntries = {
  value: number;
  timestamp: number;
};

export type ActivityBarChartProps = {
  data: ActivityEntries[];
};

export const timestampToHourMinute = (timestamp: number) =>
  String(new Date(timestamp).getHours()).padStart(2, "0") +
  ":" +
  String(new Date(timestamp).getMinutes()).padStart(2, "0");

export const ActivityBarChart = (props: ActivityBarChartProps) => {
  const scales = {
    x: {
      display: false,
    },
    statOneAxis: {
      display: false,
      position: "left" as const,
      title: {
        text: "",
      },
      grid: {
        color: muiTheme.palette.text.disabled,
      },
      ticks: {
        color: muiTheme.palette.text.primary,
        stepSize: 1,
      },
    },
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales,
    elements: {
      bar: {
        borderRadius: 10,
      },
    },
  };
  const labels = props.data.map(({ timestamp }) =>
    timestampToHourMinute(timestamp),
  );

  const data = {
    labels,
    datasets: [
      {
        yAxisID: "statOneAxis",
        data: props.data.map(({ value }) => value),
        backgroundColor: muiTheme.palette.primary.main,
        barThickness: 10,
      },
    ],
  };

  return <Bar options={options} data={data} />;
};
