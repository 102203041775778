import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import {
  MobileDateTimePicker as MuiMobileDateTimePicker,
  MobileDatePicker as MuiMobileDatePicker,
  DatePicker as MuiDatePicker,
  MobileTimePicker as MuiMobileTimePicker
} from "@mui/x-date-pickers";
import { font, muiTheme } from "theme";

const localTheme = createTheme({
  ...muiTheme,
  typography: {
    ...muiTheme.typography,
    h3: {
      fontFamily: font.adihaus.regular,
      fontSize: 32,
      padding: 8,
      color: "#000000",
      textShadow: "none",
      lineHeight: "32px",
      fontWeight: 400,
      textTransform: "uppercase",
    },
  },
});

export const MobileDateTimePicker = (props) => {
  return (
    <ThemeProvider theme={localTheme}>
      <MuiMobileDateTimePicker
        slotProps={{
          shortcuts: {
            sx: {
              backgroundColor: "#1A1A1A",
            },
          },
          toolbar: {
            sx: {
              backgroundColor: "#1A1A1A",
            },
          },
          layout: {
            sx: {
              backgroundColor: "#1A1A1A",
            },
          },
          day: {
            sx: {
              fontFamily: font.adihaus.condItalic,
              fontSize: "16px",
              fontWeight: 700,
              lineHeight: "20.61px",
              letterSpacing: "1px",
              textAlign: "center",
            },
          },
        }}
        {...props}
      />
    </ThemeProvider>
  );
};

export const MobileDatePicker = (props) => {
  return (
    <ThemeProvider theme={localTheme}>
      <MuiMobileDatePicker
        slotProps={{
          shortcuts: {
            sx: {
              backgroundColor: "#1A1A1A",
            },
          },
          toolbar: {
            sx: {
              backgroundColor: "#1A1A1A",
            },
          },
          layout: {
            sx: {
              backgroundColor: "#1A1A1A",
            },
          },
          day: {
            sx: {
              fontFamily: font.adihaus.condItalic,
              fontSize: "16px",
              fontWeight: 700,
              lineHeight: "20.61px",
              letterSpacing: "1px",
              textAlign: "center",
            },
          },
        }}
        {...props}
      />
    </ThemeProvider>
  );
};

export const DatePicker = (props) => {
  return (
    <ThemeProvider theme={localTheme}>
      <MuiDatePicker
        slotProps={{
          shortcuts: {
            sx: {
              backgroundColor: "#1A1A1A",
            },
          },
          toolbar: {
            sx: {
              backgroundColor: "#1A1A1A",
            },
          },
          layout: {
            sx: {
              backgroundColor: "#1A1A1A",
            },
          },
          day: {
            sx: {
              fontFamily: font.adihaus.condItalic,
              fontSize: "16px",
              fontWeight: 700,
              lineHeight: "20.61px",
              letterSpacing: "1px",
              textAlign: "center",
            },
          },
        }}
        {...props}
      />
    </ThemeProvider>
  );
};

export const MobileTimePicker = (props) => {
  return (
    <ThemeProvider theme={localTheme}>
      <MuiMobileTimePicker
        slotProps={{
          shortcuts: {
            sx: {
              backgroundColor: "#1A1A1A",
            },
          },
          toolbar: {
            sx: {
              backgroundColor: "#1A1A1A",
            },
          },
          layout: {
            sx: {
              backgroundColor: "#1A1A1A",
            },
          },
          day: {
            sx: {
              fontFamily: font.adihaus.condItalic,
              fontSize: "16px",
              fontWeight: 700,
              lineHeight: "20.61px",
              letterSpacing: "1px",
              textAlign: "center",
            },
          },
        }}
        {...props}
      />
    </ThemeProvider>
  );
};