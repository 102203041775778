export enum MediaType {
  IMAGE = "image",
}

export type MediaResponse = {
  url: string;
  key: string;
  type: MediaType;
};

export type CreateMediaRequest = {
  type: MediaType;
};

export type UploadMediaRequest = {
  url: string;
  body: File;
};
