import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

function TagSignalIcon(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="large" {...props}>
      <rect
        x="0.990212"
        y="4.08128"
        width="7.5"
        height="14.5"
        rx="1.25"
        fillRule="evenodd"
        fill="none"
        transform="rotate(-24 0.990212 4.08128)"
        stroke={props.fill || "#212F3C"}
      />
      <g>
        <path
          d="M5.1182 6.89557L4.80073 7.32725L5.5445 8.99778L6.3935 7.82651L5.1182 6.89557ZM6.76363 11.736L7.50865 13.4093L10.2893 9.57888L9.01127 8.64924L6.76363 11.736Z"
          fill={props.fill || "#212F3C"}
        />
        <path
          d="M6.52656 11.2035L5.78154 9.53017L7.05379 7.78568L8.33025 8.71947L6.52656 11.2035Z"
          fill={props.fill || "#212F3C"}
        />
      </g>
      <path
        d="M18.9118 1.30333L18.4921 2.86971C22.3656 3.90759 24.6722 7.90286 23.6344 11.7763L25.2007 12.196C26.4729 7.44831 23.6551 2.56778 18.9118 1.30333Z"
        fill={props.fill || "#212F3C"}
      />
      <path
        d="M16.6116 9.88804L18.9551 10.516C19.2955 9.22118 18.5311 7.89713 17.2395 7.54455L16.6116 9.88804Z"
        fill={props.fill || "#212F3C"}
      />
      <path
        d="M17.656 5.99032C19.8052 6.5662 21.0852 8.78322 20.5137 10.9401L22.0757 11.3521C22.8826 8.34082 21.087 5.23083 18.0757 4.42394L17.656 5.99032Z"
        fill={props.fill || "#212F3C"}
      />
    </SvgIcon>
  );
}

export default TagSignalIcon;
