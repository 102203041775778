import { BallSpeedZone, MoveSpeedZone } from "@gamer/common/lib/models/atomic-metrics";

export enum ZoneTypes {
  KICKS = "kicks",
  MOTIONS = "motions"
};

export enum KickAgesVals {
  U11 = 11,
  U13 = 13,
  U15 = 15,
  U17 = 17,
  U19 = 19,
  U35 = 35,
  U45 = 45,
  A45 = 100
};

export enum KickAges {
  U11 = "U11",
  U13 = "U13",
  U15 = "U15",
  U17 = "U17",
  U19 = "U19",
  U35 = "U35",
  U45 = "U45",
  A45 = "A45"
};

export enum MoveAgesVals {
  U5 = 5,
  U8 = 8,
  U11 = 11,
  U14 = 14,
  U16 = 16,
  U18 = 18,
  U20 = 20,
  U25 = 25,
  U30 = 30,
  U40 = 40,
  U50 = 50,
  U55 = 55,
  A55 = 100
};

export enum MoveAges {
  U5 = "U5",
  U8 = "U8",
  U11 = "U11",
  U14 = "U14",
  U16 = "U16",
  U18 = "U18",
  U20 = "U20",
  U25 = "U25",
  U30 = "U30",
  U40 = "U40",
  U50 = "U50",
  U55 = "U55",
  A55 = "A55"
};

export const mapAgeToKickZone = (ageInYears: number) =>
  Object.keys(KickAges).sort((a,b) => KickAgesVals[a] - KickAgesVals[b])
    .find((key) => ageInYears < KickAgesVals[key]) || KickAges.A45;

export const mapAgeToMoveZone = (ageInYears: number) =>
    Object.keys(MoveAges).sort((a,b) => MoveAgesVals[a] - MoveAgesVals[b])
      .find((key) => ageInYears < MoveAgesVals[key]) || MoveAges.A55;

export type GenderZones = {
  [key in BallSpeedZone | MoveSpeedZone]: Array<number>;
};

export type AgeGroupZones = {
  MALE: GenderZones;
  FEMALE: GenderZones;
  OTHER: GenderZones;
};

export type ZonesResponse = {
  [key in KickAgesVals | MoveAgesVals]: AgeGroupZones
};
