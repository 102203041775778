import { ActiveChartColors, axisXTickFormat, showMinuteTick } from "./style-constants";
import { AllSeriesType, AxisConfig, BarPlot, ChartsAxisHighlight, ChartsGrid, ChartsLegend, ChartsTooltip,
  ChartsXAxis, ChartsXAxisProps, ChartsYAxis, LinePlot, MarkPlot, ResponsiveChartContainer } from "@mui/x-charts";
import { ChartInterval, StatDef } from "./chart-types";
import { BackgroundBars } from "./background-bars";

export interface BarLineMixedStatsProps {
  dataSet: Array<any>;
  dataKeys: Array<StatDef>;
  chartInterval?: ChartInterval; // minute by default 
  colorLines?: string;
  colorBars?: string;
}

export const BarLineMixedStats = (props: BarLineMixedStatsProps) => {
  
  const series = new Array<AllSeriesType>();
  props.dataKeys.forEach( stat => {
    series.push({
      type: stat.chartType,
      dataKey: stat.dataKey,
      label: stat.label === undefined ? "0" : stat.label,
      valueFormatter: (val) => val ? val.toString() : "0",
      color: stat.chartType === "line" ? ActiveChartColors[0] : ActiveChartColors[1],
      yAxisId: stat.axisPos
    });
  });

  return <ResponsiveChartContainer
    height = {300}
    className="MuiResponsiveChart-container"
    series = {series}
    xAxis = {[
      { dataKey: "xLabel", scaleType: "band", id: "x-axis-id",
        categoryGapRatio: 0.75,
        valueFormatter: axisXTickFormat, tickLabelPlacement: "middle",
        tickLabelInterval: (value) => showMinuteTick(value as string, props.chartInterval)
      } as AxisConfig<"band", unknown, ChartsXAxisProps>
    ]}
    dataset={props.dataSet}
    yAxis={[
      { id: "left", position: "left", disableLine: true, disableTicks: true },
      { id: "right", position: "right", disableLine: true, disableTicks: true }
    ]}
    >
    <BackgroundBars
      x={props.dataSet.map((el) => el["xLabel"])} />
    <ChartsGrid horizontal />
    <BarPlot />
    <LinePlot />
    <MarkPlot />
    <ChartsTooltip trigger="axis"  />
    <ChartsAxisHighlight x="band" />
    <ChartsXAxis position="bottom" axisId="x-axis-id" tickSize={0} />
    <ChartsYAxis label="CNT" position="right" axisId="right" />
    <ChartsYAxis label="M" position="left" axisId="left" />
    <ChartsLegend
      position={{vertical:"bottom", horizontal:"middle" }}
      slotProps={{legend: { direction: "row" }}}
    />
  </ResponsiveChartContainer>;
};
