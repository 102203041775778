import { getHeaders, isError, send } from "services/commons.api";
import { IntervalStats } from "./member-stats.model";
import api from "../../config";

export type MemberStatsParams = {
  teamId: string;
  memberId: string;
  from: number;
  to: number;
  interval: string;
};

export const getMemberStats =
  (params: MemberStatsParams) => (token: string) => (_context) =>
    send<IntervalStats>({
      apiEndpoint: api.API.MEMBER_STATS.GET,
      urlParams: params,
      headers: getHeaders(token),
    }).then((v) => {
      if (isError(v)) {
        throw v;
      }

      return v.data;
    });
