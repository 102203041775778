import { ActiveChartColors, axisXTickFormat, showMinuteTick } from "./style-constants";
import { AxisConfig, BarPlot, BarSeriesType, ChartsAxisHighlight, ChartsGrid,
  ChartsLegend, ChartsTooltip, ChartsXAxis, ChartsXAxisProps, ChartsYAxis, ResponsiveChartContainer } from "@mui/x-charts";
import { ChartInterval, StatDef } from "./chart-types";
import { BackgroundBars } from "./background-bars";

export interface AtomicMetricsBarsProps {
  dataSet: Array<any>;
  dataKeys: Array<StatDef>;
  chartInterval?: ChartInterval; // minute by default
  barColor?: string;
  measure: string;
}

export const AtomicMetricsBars = (props: AtomicMetricsBarsProps) => {
  
  const series = new Array<BarSeriesType>();
  props.dataKeys.forEach( stat => {
    series.push({
      type: "bar",
      dataKey: stat.dataKey,
      label: stat.label,
      color: props.barColor ?? ActiveChartColors[props.dataKeys.indexOf(stat)]
    });
  });

  return <ResponsiveChartContainer
    height = {300}
    className="MuiResponsiveChart-container"
    series = {series}
    xAxis = {[
      { scaleType: "band", id: "x-axis-id", disableTicks: true, dataKey: "xLabel",
        categoryGapRatio: 0.75,
        barGapRatio: 0.1,
        tickLabelInterval: (value) => showMinuteTick(value as string, props.chartInterval),
        valueFormatter: axisXTickFormat, tickLabelPlacement: "middle"
      } as AxisConfig<"band", unknown, ChartsXAxisProps>
    ]}
    dataset={props.dataSet}
    yAxis={[
      { id: "meters", position: "left", disableLine: true, disableTicks: true, label: props.measure }
    ]}
  >
    <BackgroundBars
      x={props.dataSet.map((el) => el["xLabel"])} />
    <ChartsGrid horizontal />
    <ChartsAxisHighlight x="band" />
    <ChartsTooltip trigger="axis" />
    <BarPlot slotProps={{ bar: { width: "8px" }}} />
    <ChartsXAxis position="bottom" axisId="x-axis-id" tickSize={0} />
    <ChartsYAxis label="M" position="left" axisId="meters" />
    <ChartsLegend
      position={{vertical:"bottom", horizontal:"middle" }}
      slotProps={{legend: { direction: "row", hidden: series.length < 2 }}}
    />
  </ResponsiveChartContainer>;
};
