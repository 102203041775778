import * as core from "@mui/material";
import { withStyles } from "@mui/styles";

export const LandingLink = withStyles((theme) => ({
  root: {
    color: "#FFFFFF",
  },
}))(core.Link);

export const DownloadLink = withStyles((theme) => ({
  root: {
    color: "#51F69D",
    textTransform: "uppercase",
    fontSize: "14px",
    lineHeight: "16px",
    textDecoration: "none",
  },
}))(core.Link);
