const imports = () => {
  require("react-toastify/dist/ReactToastify.min.css");
  require("react-virtualized/styles.css");

  require("branding-resources/fonts/adihaus.css");
  require("branding-resources/fonts/adihaus-din.css");
  require("branding-resources/fonts/adineue.css");

  require("common-user-interface/dist/ds.css");
  require("common-user-interface/dist/ds-icon.css");
  require("common-user-interface/dist/ds-logo.css");

  require("./index.css");
};

export default imports;
