import {
  CreateMediaRequest,
  MediaResponse,
  MediaType,
  UploadMediaRequest,
} from "./media.model";
import {
  getHeaders,
  getImageHeaders,
  isError,
  send,
} from "services/commons.api";
import config from "../../config";

export const createMedia =
  (token: string) =>
    (mediaCreateData: CreateMediaRequest = { type: MediaType.IMAGE }) =>
      send<MediaResponse>({
        apiEndpoint: config.API.MEDIA.POST,
        headers: getHeaders(token),
        body: mediaCreateData,
      }).then((v) => {
        if (isError(v)) {
          throw v;
        }

        return v.data;
      });

export const uploadMedia = (uploadMediaData: UploadMediaRequest) =>
  send<MediaResponse>({
    apiEndpoint: {
      method: "PUT",
      finalUrl: uploadMediaData.url,
    },
    headers: getImageHeaders(),
    body: uploadMediaData.body,
  }).then((v) => {
    if (isError(v)) {
      throw v;
    }

    return v.data;
  });
