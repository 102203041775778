/// <reference types="ineum" />
import { AnalyticsPlugin } from "analytics";
type PluginConfig = {
  instanaKey: string;
  instanaReportingURL: string;
};
type AnalyticsExecute = { config: PluginConfig, payload: any  };
export default function InstanaPlugin(userConfig: PluginConfig): AnalyticsPlugin {
  // Can use only ineum instance from window. Cause it has it's own lifecycle, so we can't bind it once into config. 
  // Doing that the metrics are collected into queue, which never was sent.
  return {
    name: "analytics-plugin-instana",
    config: Object.assign({}, userConfig),
    initialize: ({ config }: AnalyticsExecute) => {
      window.ineum("reportingUrl", config.instanaReportingURL);
      window.ineum("key", config.instanaKey);
    },
    page: ({ payload }: AnalyticsExecute) => {
      window.ineum("page", payload.properties.url, payload);
    },
    track: ({ payload }: AnalyticsExecute) => {
      const { event, properties } = payload;
      window.ineum("reportEvent", event, properties);
    },
    identify: ({ payload }: AnalyticsExecute ) => {
      const { userId } = payload;
      window.ineum("user", userId);
    },
    loaded: () => {
      return !!window.ineum;
    }
  };
}

export const logError = (error: Error, info: { componentStack: string }) => {
  if (window.ineum) {
    window.ineum("reportError", error, {
      componentStack: info.componentStack,
    });
  }
};