import { getHeaders, isError, send } from "services/commons.api";
import { AtomicMetricsResponse } from "./member-metrics.model";
import { AtomicMetricTypes } from "@gamer/common/lib/models/atomic-metrics";
import api from "../../config";
import { templater } from "services/utils";

export type MemberMetricsParams = {
  teamId: string;
  memberId: string;
  from: number;
  to: number;
  limit?: number;
  afterOffset?: string;
  metricTypes?: Array<AtomicMetricTypes>;
};

const composeGetMetricURL = (params: MemberMetricsParams) => {
  const endpoint = api.API.MEMBER_METRICS.GET;
  const domainURL = templater(`${endpoint["baseUrl"]}/${endpoint.url}`, params);
  const metricTypes = params.metricTypes && params.metricTypes.length > 0
    ? `&${params.metricTypes.map((type) => `metricType=${type}`).join("&")}` : "";
  const afterOffset = params.afterOffset ? `&afterOffset=${params.afterOffset}`  : "";
  const limit = params.limit ? `&limit=${params.limit}`  : "";

  return `${domainURL}?from=${params.from}&to=${params.to}${limit}${metricTypes}${afterOffset}`;
};

export const getMemberMetrics =
  (params: MemberMetricsParams) => (token: string) => (_context) =>
    send<AtomicMetricsResponse>({
      apiEndpoint: {
        method: "GET",
        finalUrl: composeGetMetricURL(params)
      },
      headers: getHeaders(token),
    }).then((v) => {
      if (isError(v)) {
        throw v;
      }

      return v.data;
    });
