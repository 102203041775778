import { AxisConfig, BarPlot, BarSeriesType, ChartsAxisHighlight, ChartsGrid,
  ChartsLegend, ChartsTooltip, ChartsXAxis, ChartsXAxisProps, ChartsYAxis, ResponsiveChartContainer } from "@mui/x-charts";

import { ActiveChartColors, axisXTickFormat, showMinuteTick } from "./style-constants";
import { ChartInterval, StatDef } from "./chart-types";
import { BackgroundBars } from "./background-bars";
import { ExtMetricsBarsProps, ExtMetricsBars } from "./metrics-bars-ext";

export interface ExtAtomicMetricsBarsProps {
  dataSet: Array<any>;
  dataKeys: Array<StatDef>;
  chartInterval?: ChartInterval; // minute by default
  barColor?: string;
  measure: string;
  expBarProps: ExtMetricsBarsProps;
}

export const ExtAtomicMetricsBars = (props: ExtAtomicMetricsBarsProps) => {

  const formatMetric = (val, context) => {
    const xLbl = props.dataSet[context.dataIndex] ? props.dataSet[context.dataIndex].xLabel : "";
    const yVals = props.expBarProps.extBarsData.find((el) => el.xLabel === xLbl);
    return yVals?.vals.join(", ") || "0";
  };
  
  const series = new Array<BarSeriesType>();
  props.dataKeys.forEach( stat => {
    series.push({
      type: "bar",
      dataKey: stat.dataKey,
      label: stat.label,
      color: props.barColor ?? ActiveChartColors[props.dataKeys.indexOf(stat)],
      valueFormatter: formatMetric
    });
  });
  const allVals = props.expBarProps.extBarsData.map((el) => el.vals).flat(1);
  const yMax = Math.ceil(Math.max(...allVals) / 5) * 5;

  return <ResponsiveChartContainer
    height = {300}
    className="MuiResponsiveChart-container"
    series = {series}
    xAxis = {[
      { scaleType: "band", id: "x-axis-id", disableTicks: true, dataKey: "xLabel",
        categoryGapRatio: 0.75,
        barGapRatio: 0.1,
        tickLabelInterval: (value) => showMinuteTick(value as string, props.chartInterval),
        valueFormatter: axisXTickFormat, tickLabelPlacement: "middle"
      } as AxisConfig<"band", unknown, ChartsXAxisProps>
    ]}
    dataset={props.dataSet}
    yAxis={[
      { id: "meters", position: "left", disableLine: true, disableTicks: true, label: props.measure,
        max: yMax 
      }
    ]}
  >
    <BackgroundBars
      x={props.dataSet.map((el) => el["xLabel"])} />
    <ChartsGrid horizontal />
    <ChartsAxisHighlight x="band" />
    <ChartsTooltip trigger="axis" />
    {props.expBarProps.extBarsData.length > 0 && <ExtMetricsBars 
      extBarsData={props.expBarProps.extBarsData}
      xLabelsCount={props.dataSet.length}
    /> }
    {/* <BarPlot slotProps={{ bar: { width: "8px" }}} /> */}
    <ChartsXAxis position="bottom" axisId="x-axis-id" tickSize={0} />
    <ChartsYAxis label="M" position="left" axisId="meters" />
    <ChartsLegend
      position={{vertical:"bottom", horizontal:"middle" }}
      slotProps={{legend: { direction: "row", hidden: series.length < 2 }}}
    />
  </ResponsiveChartContainer>;
};
