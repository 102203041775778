/* tslint:disable:no-invalid-template-strings */
/* eslint-disable */
import { templater } from "./services/utils";

export const mergeDeepLeft = (a: any, b: any) => {
  const result = { ...a };
  for (const key in b) {
    if (b[key] && b[key].constructor === Object) {
      result[key] = result[key] || {};
      result[key] = mergeDeepLeft(result[key], b[key]);
    } else {
      result[key] = result[key] || b[key];
    }
  }
  return result;
};

const prodUrls = ["clubs-portal.gmr.api.3stripes.io", "adidasteamfx.com"];
export const STAGE = prodUrls.some((url) => window.location.href.includes(url))
  ? "prod"
  : "dev";

export const isProduction = STAGE === "prod";

const globals = {
  default: {
    FRAME_WIDTH: 5,
    COLORS: {
      LABEL_BAR: "#1F1F1F",
      LABEL: "#333333",
      HEADER: "#1A1A1A",
      BLUE: "#0D83FF",
      GREY: "#5e5e5e",
    },
    SIZES: {
      LABEL_BAR: 400,
    },
    API: {
      BASE_URL: "https://gamer.stg.gmr.api.3stripes.io",
      CLUBS: {
        BASE_URL: "${BASE_URL}",
        POST: {
          method: "POST",
          url: "clubs",
        },
        GET_ALL: {
          method: "GET",
          url: "clubs",
        },
        DELETE: {
          method: "DELETE",
          url: "clubs/${clubId}",
        },
      },
      CLUB_MEMBERS: {
        BASE_URL: "${BASE_URL}",
        GET_ALL: {
          method: "GET",
          url: "clubs/${clubId}/members",
        },
      },
      CLUB_TEAMS: {
        BASE_URL: "${BASE_URL}",
        GET_ALL: {
          method: "GET",
          url: "clubs/${clubId}/teams",
        },
        POST: {
          method: "POST",
          url: "clubs/${clubId}/teams",
        },
      },
      USER_TEAMS: {
        BASE_URL: "${BASE_URL}",
        GET_ALL: {
          method: "GET",
          url: "teams",
        },
        POST: {
          method: "POST",
          url: "teams",
        },
        JOIN_TEAM: {
          method: "POST",
          url: "teams/${teamId}/members",
        },
        UPDATE_TEAM: {
          method: "PATCH",
          url: "teams/${teamId}",
        },
      },
      USER_NOTIFICATIONS: {
        BASE_URL: "${BASE_URL}",
        GET_ALL: {
          method: "GET",
          url: "notifications",
        },
        UPDATE_NOTIFICATION: {
          method: "PATCH",
          url: "notifications/${notificationId}",
        },
      },
      TEAM_MEMBERS: {
        BASE_URL: "${BASE_URL}",
        GET_ALL: {
          method: "GET",
          url: "teams/${teamId}/members",
        },
        ADD: {
          method: "POST",
          url: "teams/${teamId}/members",
        },
        UPDATE: {
          method: "PATCH",
          url: "teams/${teamId}/members/${playerId}",
        },
        DELETE_TEAM_MEMBERSHIP: {
          method: "DELETE",
          url: "teams/${teamId}/members/${playerId}",
        },
      },
      CARDS: {
        BASE_URL: "${BASE_URL}",
        GET_LIST: {
          method: "GET",
          url: "cards?teamId=${teamId}&playerId=${playerId}&eventId=${eventId}&from=${from}&to=${to}",
        },
      },
      EVENTS: {
        BASE_URL: "${BASE_URL}",
        GET_LIST: {
          method: "GET",
          url: "events?teamId=${teamId}&from=${from}&to=${to}&includeParticipation=${includeParticipation}",
        },
        GET: {
          method: "GET",
          url: "events/${eventId}",
        },
        CREATE: {
          method: "POST",
          url: "events",
        },
        UPDATE: {
          method: "PATCH",
          url: "events/${eventId}",
        },
        DELETE: {
          method: "DELETE",
          url: "events/${eventId}",
        },
        UPDATE_USER_EVENT: {
          method: "PATCH",
          url: "events/${eventId}/participants/${playerId}",
        },
        FEEDBACK_REPLY: {
          method: "PUT",
          url: "events/${eventId}/participants/${playerId}/replies",
        },
      },
      AUTO_EVENTS: {
        BASE_URL: "${BASE_URL}",
        GET_LIST: {
          method: "GET",
          url: "auto-events?teamId=${teamId}&from=${from}&to=${to}",
        },
        GET: {
          method: "GET",
          url: "auto-events/${eventId}",
        },
      },
      MEMBER_STATS: {
        BASE_URL: "${BASE_URL}",
        GET: {
          method: "GET",
          url: "teams/${teamId}/members/${memberId}/stats?from=${from}&to=${to}&interval=${interval}",
        },
      },
      MEMBER_METRICS: {
        BASE_URL: "${BASE_URL}",
        GET: {
          method: "GET",
          url:
            "teams/${teamId}/members/${memberId}/atomic-metrics",
        },
      },
      LEADER_BOARD: {
        BASE_URL: "${BASE_URL}",
        GET: {
          method: "GET",
          url: "teams/${teamId}/leaderboard?from=${from}&to=${to}&leaderboardLimit=25",
        },
      },
      GENERIC_CLUBS: {
        BASE_URL: "${BASE_URL}/generic",
        GET_ALL: {
          method: "GET",
          url: "clubs",
        },
      },
      GENERIC_TEAMS: {
        BASE_URL: "${BASE_URL}/generic",
        GET: {
          method: "GET",
          url: 'teams?teamName=${teamName}&includeMembership=${includeMembership}',
        },
        GET_BY_ID: {
          method: "GET",
          url: "teams/{teamId}",
        },
      },
      GENERIC_WORKOUTS: {
        BASE_URL: "${BASE_URL}/generic",
        GET: {
          method: "GET",
          url: 'workouts/zones/${file}',
        }
      },
      USER: {
        BASE_URL: "${BASE_URL}",
        API_KEY: "mgLful6Z2079ONVvvkfJa5WixnmwBTrd4MWkY9BX",
        CLIENT_ID: "6BA34E93DBB6D4381794F41EA296F220",
        SOURCE_ID: "6000001135",
        LOGIN: {
          method: "POST",
          url: "login",
        },
        GET: {
          method: "GET",
          url: "user",
        },
        PATCH: {
          method: "PATCH",
          url: "user",
        },
        SEARCH: {
          method: "GET",
          url: "generic/tags?tagId=${tagId}",
        },
        TOKENS: {
          method: "GET",
          url: "user/me/social-token",
        },
      },
      MEDIA: {
        BASE_URL: "${BASE_URL}",
        API_KEY: "mgLful6Z2079ONVvvkfJa5WixnmwBTrd4MWkY9BX",
        POST: {
          method: "POST",
          url: "media",
        },
      },
      TROPHIES: {
        BASE_URL: "${BASE_URL}",
        GET: {
          method: "GET",
          url: "trophies?teamId=${teamId}&playerId=${playerId}",
        },
      },
      GENERIC_TROPHIES: {
        BASE_URL: "${BASE_URL}/generic",
        GET: {
          method: "GET",
          url: "trophies",
        },
      }
    },
    AUTHORIZER: {
      SOCIAL_LOGIN: {
        baseUrl: "https://eu.idp.stg.adidas.com",
        method: "GET",
        url: "as/authorization.oauth2",
      },
    },
    BUCKETS: {
      videos: "https://fx-st-videos-stg.s3.eu-west-1.amazonaws.com",
      matching: "https://fx-st-output-stg.s3.eu-west-1.amazonaws.com",
    },
    SERVICES: {
      INSTANA: {
        REPORTING_URL: "https://eum-blue-saas.instana.io",
        KEY: "hrXHCjuwTAqhKl3teMJTVg",
      },
    },
  },
  prod: {
    API: {
      BASE_URL: "https://gamer.gmr.api.3stripes.io",
      USER: {
        API_KEY: "IobomCm0Qr3jUhqL4Rroi7oMSg8e9l2M6FIuwwoX",
        CLIENT_ID: "384E58D5AAF3D0B79DF42EE674542482",
        SOURCE_ID: "6000001603",
      },
    },
    AUTHORIZER: {
      SOCIAL_LOGIN: {
        baseUrl: "https://eu.idp.adidas.com",
      },
    },
    SERVICES: {
      INSTANA: {
        REPORTING_URL: "https://eum-eu-west-1.instana.io",
        KEY: "2WT5RnJeQHufPDi3Oo6u_A",
      },
    },
    BUCKETS: {
      videos: "https://fx-st-videos.s3.eu-west-1.amazonaws.com",
      matching: "https://fx-st-output.s3.eu-west-1.amazonaws.com",
    },
  },
};

const mergeConfig = () => {
  //const envOverride = window.env && window.env.CURRENT_ENV && (globals as any)[window.env.CURRENT_ENV];
  const envOverride = (globals as any)[STAGE];
  const toMerge = envOverride || (globals as any)[process.env.NODE_ENV] || {};
  const config: (typeof globals)["default"] = mergeDeepLeft(
    toMerge,
    globals.default,
  );

  const { BASE_URL } = config.API;

  // iterate through SESSIONS, LABELS, USER, etc. lambda configs
  Object.values(config.API).forEach((lambdaConfig: any) => {
    if (lambdaConfig && typeof lambdaConfig === "object") {
      const baseUrl = templater(lambdaConfig.BASE_URL, { BASE_URL });
      const apiKey = lambdaConfig.API_KEY;

      // iterate through GET POST PUT, etc. method configs
      Object.values(lambdaConfig).forEach((methodConfig: any) => {
        if (methodConfig && typeof methodConfig === "object") {
          // add baseUrl to all apiEndpoint's
          methodConfig.baseUrl = baseUrl;
          methodConfig.apiKey = apiKey;
        }
      });
    }
  });

  return config;
};

export default mergeConfig();
