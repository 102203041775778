import React, { useState } from "react";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Typography,
  Button,
  TextField,
} from "@mui/material";
import theme from "../../theme";
import { validateTeamName } from "store/user-teams/user-teams.model";
import { queryClient, useAuthMutation } from "providers/auth";
import { createTeam } from "store/user-teams/teams.query";
import { Trans } from "@lingui/macro";

interface TeamModalProps {
  open: boolean;
  handleClose: () => void;
}

export const CreateTeamModal = (props: TeamModalProps) => {
  const mutation = useAuthMutation("createTeams", createTeam);
  const { open, handleClose } = props;
  const [error, setError] = useState("");
  const [teamName, setTeamName] = useState("");

  const onTeamNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const v8n = validateTeamName(value);
    if (v8n) {
      setError(v8n);
    } else {
      setError("");
      setTeamName(value);
    }
  };

  if (mutation.isSuccess) {
    queryClient.invalidateQueries("teams");
    handleClose();
  }

  const onSubmit = () => {
    mutation.mutate({ teamName });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="form-dialog-title">
          <Typography variant="h3">
            <Trans>CREATE A TEAM</Trans>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <TextField
              variant="filled"
              label="Team Name"
              onChange={onTeamNameChange}
              fullWidth
              helperText={error}
              error={Boolean(error)}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            disabled={Boolean(error || !teamName || mutation.isLoading)}
            fullWidth
            onClick={onSubmit}
          >
            {mutation.isLoading ? <CircularProgress size={20} /> : "Create Team"}
          </Button>
        </DialogActions>
    </Dialog>
  );
};
