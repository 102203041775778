import {
  Card,
  Grid,
  LinearProgress,
  Skeleton,
  Typography,
} from "@mui/material";
import { TrophyIcon } from "components/icons/Trophy";
import { useAuthQuery } from "providers/auth";
import { NavigationContext } from "providers/navigation.provider";
import { useContext } from "react";
import {
  GenericTrophy,
  Trophy,
  TrophyLevel,
} from "store/trophies/trophies.model";
import { getGenericTrophies, getTrophies } from "store/trophies/trophies.query";

export const TrophieComponent = (params: GenericTrophy & Trophy) => {
  const levelBands = Object.keys(params.levels)
    .map((level) => ({
      level,
      value: params.levels[level],
    }))
    .sort((a, b) => a.value - b.value)
    .map(({ level, value }, index, arr) => ({
      level: level as TrophyLevel,
      from: index === 0 ? 0 : arr[index - 1].value,
      to: value,
    }));

  const level = levelBands.find(
    (band) =>
      Number(params.currentValue) >= band.from &&
      Number(params.currentValue) < band.to,
  ) ||
    levelBands[levelBands.length - 1] || {
      level: TrophyLevel.legendary,
      from: 0,
      to: 1,
    };
  const progress = Math.min(
    ((Number(params.currentValue) - level.from) / (level.to - level.from)) *
      100,
    100,
  );

  return (
    <Card style={{ width: "fit-content", padding: 5 }}>
      <Grid container direction="column" alignItems="center" width={150} spacing={1}>
        <Grid item>
          <Typography variant="body2">{params.trophyTitle}</Typography>
        </Grid>
        <Grid item width={75}>
          <TrophyIcon trophyId={params.trophyId} level={level.level} />
        </Grid>
        <Grid item width={100} color={"#FFF"}>
          <LinearProgress
            variant="determinate"
            value={progress}
            color="inherit"
          />
        </Grid>
        <Grid item>
          <Typography variant="body2">
            {Number(params.currentValue).toFixed(1)} / {level.to.toFixed(1)}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

export const UserTrophie = () => {
  const {
    player: { playerId },
  } = useContext(NavigationContext);
  const genericTrophyQuery = useAuthQuery(
    "genericTrophies",
    getGenericTrophies,
  );
  const trophyQuery = useAuthQuery(
    ["trophies", playerId],
    getTrophies({ playerId }),
  );

  if (genericTrophyQuery.isLoading || trophyQuery.isLoading) {
    return <LinearProgress />;
  }
  const data = (trophyQuery.data?.data || []).map((trophy) => ({
    levels: {},
    ...trophy,
    ...genericTrophyQuery.data?.trophies.find(
      (genericTrophy) => genericTrophy.trophyId === trophy.trophyId,
    ),
  })) as (GenericTrophy & Trophy)[];

  const categories = data.reduce(
    (acc, t) => {
      const category = t.category || "UNCATEGORIZED";
      acc[category] = [...(acc[category] || []), t];
      return acc;
    },
    {} as Record<string, (GenericTrophy & Trophy)[]>,
  );

  return (
    <Grid container direction="column" spacing={1}>
      {Object.keys(categories).map((category) => (
        <Grid item>
          <Typography variant="body1">{category}</Typography>
          <Grid container direction="row" spacing={1}>
            {categories[category].map((trophy) => (
              <Grid item>
                <TrophieComponent {...trophy} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export const TeamTrophie = ({ limit = 4 }: { limit?: number }) => {
  const {
    currentTeam: { teamId },
  } = useContext(NavigationContext);
  const genericTrophyQuery = useAuthQuery(
    "genericTrophies",
    getGenericTrophies,
  );
  const trophyQuery = useAuthQuery(
    ["trophies", teamId],
    getTrophies({ teamId }),
  );

  if (genericTrophyQuery.isLoading || trophyQuery.isLoading) {
    return <></>;
  }
  const data = (trophyQuery.data?.data || [])
    .map((trophy) => ({
      levels: {},
      ...trophy,
      ...genericTrophyQuery.data?.trophies.find(
        (genericTrophy) => genericTrophy.trophyId === trophy.trophyId,
      ),
    }))
    .slice(0, limit) as (GenericTrophy & Trophy)[];

  if (data.length === 0) {
    return <></>;
  }

  return (
    <Grid container direction="column" alignItems="start" spacing={2}>
      <Grid item width="fit-content">
        <Typography variant="h3">Trophies</Typography>
      </Grid>
      <Grid item width="fit-content">
        <Grid container direction="row" spacing={1}>
          {data.map((trophy) => (
            <Grid item>
              <TrophieComponent {...trophy} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
