export enum ChartInterval {
  MINUTE,
  HOUR,
  DAY,
  WEEK
};

export type StatDef = {
  dataKey: string,
  label: string,
  chartType?: any,
  axisPos?: string;
};
