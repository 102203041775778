import { Stack, Switch, Typography } from "@mui/material";
import * as React from "react";

import {
  IntervalMetric
} from "store/member-stats/member-stats.model";
import { useAuthQuery } from "providers/auth";
import { getMemberStats } from "store/member-stats/member-stats.query";

import PlayerStatsNew from "./player-stats-new";
import PlayerStatsOld from "./player-stats-old";

type PlayerStatsProps = {
  memberId: string;
  teamId: string;
  from: number;
  to: number;
  queryInterval: string;
  setDisabledPlayerIds?: (playerIds: string[]) => void;
  onExport?: (data: IntervalMetric[][]) => void;
};

export default function PlayerStats({
  memberId,
  from,
  to,
  teamId,
  onExport,
  setDisabledPlayerIds,
  queryInterval
}: PlayerStatsProps) {
  const [showNewCharts, setShowNewCharts] = React.useState(true);

  const memberStatsQuery = useAuthQuery(
    ["memberStats", teamId, memberId, from, to, queryInterval],
    getMemberStats({
      teamId,
      memberId,
      from,
      to,
      interval: queryInterval,
    }),
  );

  React.useEffect(() => {
    if (memberStatsQuery.isSuccess) {
      onExport?.([memberStatsQuery.data.data]);
    }
  }, [memberStatsQuery.isSuccess]);

  return (
    <>
      <Stack direction="row">
        <Switch defaultChecked={true} onClick={() => setShowNewCharts(!showNewCharts)} checked={showNewCharts} title="Use new graphs" />
        <Typography style={{ paddingBottom: 20 }}>Use new graphs</Typography>
      </Stack>
          { showNewCharts && <PlayerStatsNew 
              memberId={memberId}
              teamId={teamId}
              from={from}
              to={to}
              setDisabledPlayerIds={setDisabledPlayerIds}
              onExport={onExport}
              queryInterval={queryInterval}/> }
          { !showNewCharts && <PlayerStatsOld 
              memberId={memberId}
              teamId={teamId}
              from={from}
              to={to}
              setDisabledPlayerIds={setDisabledPlayerIds}
              onExport={onExport}
              queryInterval={queryInterval}/> }
        </>
  );
}
