import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getHeaders, isOkResponse, send } from "services/commons.api";
import { Typography, Button, TextField } from "@mui/material";
import { onNameChange } from "components/utils/utils";
import { getToken, queryClient } from "providers/auth";
import { CircularProgress, LinearProgress, Grid } from "@mui/material";
import AdidasLogoIcon from "components/icons/AdidasLogoIcon";
import { Trans } from "@lingui/macro";
import { OnboardingLeftContent } from "./components/OnboardingLeftContent";
import config from "config";

const OnboardingProfile = () => {
  const [loading, setLoading] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [fnError, setFnError] = useState("");
  const [lnError, setLnError] = useState("");
  const [firstName, setFn] = useState("");
  const [lastName, setLn] = useState("");
  const history = useHistory();
  const root = "/onboarding/landing";
  const query = new URLSearchParams(window.location.search);
  const redirectTo = query.get("redirect") || root;

  useEffect(() => {
    if (firstName && lastName && !fnError && !lnError) {
      setSubmitEnabled(true);
    } else {
      setSubmitEnabled(false);
    }
  }, [firstName, lastName, fnError, lnError]);

  const submit = async () => {
    try {
      setLoading(true);
      const resp = await send({
        apiEndpoint: config.API.USER.PATCH,
        headers: getHeaders(getToken().jwtToken || ""),
        body: {
          firstName,
          lastName,
        },
      });

      setLoading(false);
      if (isOkResponse(resp)) {
        await queryClient.invalidateQueries("userProfile");
        history.push(redirectTo);
      } else {
        setFnError("Failed to save data. Try again later.");
        setLnError("Failed to save data. Try again later.");
      }
    } catch (err) {
      setFnError("Failed to save data. Try again later.");
      setLnError("Failed to save data. Try again later.");
    }
  };

  return (
    <div className={"login"}>
      <div className="login-leftSide login-part">
        <OnboardingLeftContent />
      </div>
      <div className="login-rightSide login-part">
        <div className={"login-content"}>
          <div className="topHeader">
            <div className={"login-logo__container"}>
              <AdidasLogoIcon
                onClick={() =>
                  (window.location.href = "https://www.adidas.com/us")
                }
                height={60}
              />
            </div>
            <Typography variant="h1">TEAM FX</Typography>
          </div>
          <Grid container direction="column" spacing={4}>
            <Grid item>
              <LinearProgress
                variant={loading ? "indeterminate" : "determinate"}
                value={60}
              />
            </Grid>
            <Grid item width="fit-content">
              <Typography variant="h3">
                <Trans>Enhance Coach profile</Trans>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                <Trans>Please enter your first and last name to proceed.</Trans>
              </Typography>
            </Grid>

            <Grid item>
              <TextField
                variant="filled"
                label={"First Name"}
                onChange={onNameChange(setFnError, setFn)}
                helperText={fnError}
                error={Boolean(fnError)}
                fullWidth
              />
            </Grid>
            <Grid item>
              <TextField
                variant="filled"
                label={"Last Name"}
                onChange={onNameChange(setLnError, setLn)}
                helperText={lnError}
                error={Boolean(lnError)}
                fullWidth
              />
            </Grid>

            <Grid item>
              <Button
                color="primary"
                variant="contained"
                disabled={!submitEnabled || loading}
                onClick={submit}
                style={{ height: 60 }}
                fullWidth
              >
                {!loading ? <Trans>Continue</Trans> : "Loading"}{" "}
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default OnboardingProfile;
