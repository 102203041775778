import {
  PlayerPosition,
  PlayerPositionFullTitle,
} from "@gamer/common/lib/models/user";
import { Avatar, Grid, Typography } from "@mui/material";
import theme from "../../theme";

export type Props = {
  position: PlayerPosition;
  selected: boolean;
  preferred?: boolean;
};

export const PositionFilterItem = (props: Props) => {
  const { selected } = props;
  const avatarBackground = selected
    ? theme.actions.primary
    : props.preferred
    ? theme.indicators.positionPreferred
    : theme.background.primary;
  const avatarTextColor = selected
    ? theme.background.primary
    : theme.text.primary;
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      wrap="nowrap"
      spacing={1}
    >
      <Grid item>
        <Avatar
          style={{
            height: 32,
            width: 32,
            color: avatarTextColor,
            backgroundColor: avatarBackground,
          }}
        >
          {props.position}
        </Avatar>
      </Grid>
      <Grid item xs>
        <Typography
          variant="body2"
          style={{ textTransform: "none", lineHeight: "20px", color: "white" }}
        >
          {PlayerPositionFullTitle[props.position]}
        </Typography>
      </Grid>
    </Grid>
  );
};
