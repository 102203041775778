import { ReactComponent as Speedster } from "images/icons/trophies/speedster.svg";
import { ReactComponent as KickChampion } from "images/icons/trophies/kick_champion.svg";
import { ReactComponent as Marathoner } from "images/icons/trophies/marathoner.svg";
import { ReactComponent as FleetFeet } from "images/icons/trophies/fleet_feet.svg";
import { ReactComponent as SprintSuperstar } from "images/icons/trophies/sprint_superstar.svg";
import { ReactComponent as StaminaStar } from "images/icons/trophies/stamina_star.svg";
import { ReactComponent as BurstRunner } from "images/icons/trophies/burst_runner.svg";
import { ReactComponent as PowerHorse } from "images/icons/trophies/power_horse.svg";
import { ReactComponent as KickLegend } from "images/icons/trophies/kick_legend.svg";
import { ReactComponent as DistanceHero } from "images/icons/trophies/distance_hero.svg";
import { ReactComponent as RapidRunner } from "images/icons/trophies/rapid_runner.svg";
import { ReactComponent as GoldenGlove } from "images/icons/trophies/golden_glove.svg";
import { ReactComponent as FeedbackPro } from "images/icons/trophies/feedback_pro.svg";
import { ReactComponent as TagMaster } from "images/icons/trophies/tag_master.svg";
import { ReactComponent as TrainingChamp } from "images/icons/trophies/training_champ.svg";
import { ReactComponent as MatchVeteran } from "images/icons/trophies/match_veteran.svg";
import { ReactComponent as BusyBee } from "images/icons/trophies/busy_bee.svg";
import { ReactComponent as TeamLegend } from "images/icons/trophies/team_legend.svg";
import { ReactComponent as EarlyBird } from "images/icons/trophies/early_bird.svg";
import { ReactComponent as NightOwl } from "images/icons/trophies/night_owl.svg";
import { ReactComponent as DeterminedDynamo } from "images/icons/trophies/determined_dynamo.svg";
import { ReactComponent as RocketFoot } from "images/icons/trophies/rocket_foot.svg";
import { ReactComponent as SpeedDemon } from "images/icons/trophies/speed_demon.svg";
import { ReactComponent as EnduranceChamp } from "images/icons/trophies/endurance_champ.svg";
import { ReactComponent as KickCollective } from "images/icons/trophies/kick_collective.svg";
import { ReactComponent as CheetahCrew } from "images/icons/trophies/cheetah_crew.svg";
import { ReactComponent as BoomBrigade } from "images/icons/trophies/boom_brigade.svg";
import { ReactComponent as FeedbackForce } from "images/icons/trophies/feedback_force.svg";
import { ReactComponent as DataChamps } from "images/icons/trophies/data_champs.svg";
import { TrophyId, TrophyLevel } from "../../store/trophies/trophies.model";


export const TrophyLevelToColor = {
  [TrophyLevel.basic]: [{ offset: "0%", color: "#FFFFFF" }],
  [TrophyLevel.bronze]: [
    { offset: "14.91%", color: "#BF845E" },
    { offset: "42.74%", color: "#F4C4A7" },
    { offset: "63.91%", color: "#C98559" },
    { offset: "93.31%", color: "#E8A880" },
  ],
  [TrophyLevel.silver]: [
    { offset: "15.87%", color: "#A8A8A6" },
    { offset: "39.11%", color: "#838383" },
    { offset: "61.26%", color: "#E3E1DD" },
    { offset: "73.97%", color: "#D4D4D4" },
    { offset: "88.5%", color: "#7F7F7F" },
  ],
  [TrophyLevel.gold]: [
    { offset: "15.43%", color: "#D89921" },
    { offset: "34.91%", color: "#ECDC6B" },
    { offset: "50.85%", color: "#E2BF7D" },
    { offset: "68.56%", color: "#965F2A" },
    { offset: "86.26%", color: "#F1EA82" },
  ],
  [TrophyLevel.legendary]: [
    { offset: "15.87%", color: "#8CF3BB" },
    { offset: "34.81%", color: "#16B15E" },
    { offset: "64.17%", color: "#86F0B7" },
    { offset: "75.79%", color: "#51F69D" },
    { offset: "88.5%", color: "#18DE73" },
  ],
};

export type TrophyIconProps = {
  trophyId: string;
  level: TrophyLevel;
};
export const TrophyIconMap = {
  [TrophyId.SPEEDSTER]: Speedster,
  [TrophyId.KICK_CHAMPION]: KickChampion,
  [TrophyId.MARATHONER]: Marathoner,
  [TrophyId.FLEET_FEET]: FleetFeet,
  [TrophyId.SPRINT_SUPERSTAR]: SprintSuperstar,
  [TrophyId.STAMINA_STAR]: StaminaStar,
  [TrophyId.BURST_RUNNER]: BurstRunner,
  [TrophyId.POWER_HORSE]: PowerHorse,
  [TrophyId.KICK_LEGEND]: KickLegend,
  [TrophyId.DISTANCE_HERO]: DistanceHero,
  [TrophyId.RAPID_RUNNER]: RapidRunner,
  [TrophyId.GOLDEN_GLOVE]: GoldenGlove,
  [TrophyId.FEEDBACK_PRO]: FeedbackPro,
  [TrophyId.TAG_MASTER]: TagMaster,
  [TrophyId.TRAINING_CHAMP]: TrainingChamp,
  [TrophyId.MATCH_VETERAN]: MatchVeteran,
  [TrophyId.BUSY_BEE]: BusyBee,
  [TrophyId.TEAM_LEGEND]: TeamLegend,
  [TrophyId.EARLY_BIRD]: EarlyBird,
  [TrophyId.NIGHT_OWL]: NightOwl,
  [TrophyId.DETERMINED_DYNAMO]: DeterminedDynamo,
  [TrophyId.ROCKET_FOOT]: RocketFoot,
  [TrophyId.SPEED_DEMON]: SpeedDemon,
  [TrophyId.ENDURANCE_CHAMP]: EnduranceChamp,
  [TrophyId.KICK_COLLECTIVE]: KickCollective,
  [TrophyId.CHEETAH_CREW]: CheetahCrew,
  [TrophyId.BOOM_BRIGADE]: BoomBrigade,
  [TrophyId.FEEDBACK_FORCE]: FeedbackForce,
  [TrophyId.DATA_CHAMPS]: DataChamps,
};

export const TrophyIcon = ({ trophyId, level }: TrophyIconProps) => {
  const IconComponent = TrophyIconMap[trophyId] || Speedster;
  const gradient = TrophyLevelToColor[level] || TrophyLevelToColor[TrophyLevel.basic];
  const gradientId = `grad-${level}`;

  return (
    <svg
      width="69"
      height="69"
      xmlns="http://www.w3.org/2000/svg"
      style={{ display: "block", margin: "auto" }}
    >
      <defs>
        <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="0%">
          {gradient.map((stop, index) => (
            <stop
              key={index}
              offset={stop.offset}
              style={{ stopColor: stop.color, stopOpacity: 1 }}
            />
          ))}
        </linearGradient>
      </defs>
      <rect
        x="1"
        y="1"
        width="68"
        height="68"
        stroke={`url(#${gradientId})`}
        strokeWidth="1.5"
        fill="none"
        rx="4"
        ry="4"
      />
      <IconComponent fill={`url(#${gradientId})`} />
    </svg>
  );
};
