import {
  Grid,
  CircularProgress,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Button,
  Tooltip,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import React, { ChangeEventHandler, useEffect, useState } from "react";
import { useAuthMutation, useAuthQuery } from "providers/auth";
import { addTeamMember } from "store/team-members/team-members.query";
import { searchByTagId } from "store/user/user.query";
import SearchIcon from "@mui/icons-material/Search";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { toast } from "react-toastify";
import { Trans } from "@lingui/macro";

interface IProps {
  teamId: string;
  onAdded: () => void;
}

const Input = withStyles(() => ({
  root: {
    width: 375,
    height: 50,
  },
  input: {
    textTransform: "uppercase",
  },
}))(OutlinedInput);

enum SearchStates {
  "input" = "input",
  "pending" = "pending",
  "inprogress" = "inprogress",
  "success" = "success",
  "failed" = "failed",
}

export const SearchByTagId = ({ teamId, onAdded }: IProps) => {
  const [playerId, setPlayerId] = useState("");
  const [tagId, setTagId] = useState("");
  const [queryEnabled, setQueryEnabled] = useState(false);
  const [searchState, setSearchState] = useState<SearchStates>(
    SearchStates.input,
  );
  const membersMutation = useAuthMutation("addTeamMember", addTeamMember);
  const playersQuery = useAuthQuery(
    ["players", tagId],
    searchByTagId(tagId.replace("-", "")),
    { enabled: queryEnabled },
  );

  useEffect(() => {
    if (playersQuery.isLoading) {
      setSearchState(SearchStates.inprogress);
      return;
    }

    if (playersQuery.isSuccess) {
      if (!playersQuery.data.tags.length) {
        setSearchState(SearchStates.failed);
        setQueryEnabled(false);
        toast.error("Could not find the specified Tag ID");
        return;
      }

      const value = playersQuery.data.tags[0].playerId;
      setPlayerId(value || "");
      setSearchState(SearchStates.success);
      setQueryEnabled(false);
    }

    if (playersQuery.isError) {
      setSearchState(SearchStates.failed);
      setQueryEnabled(false);
      toast.error("Could not find the specified Tag ID");
    }
  }, [
    playersQuery.isLoading,
    playersQuery.isSuccess,
    playersQuery.isError,
    playersQuery.data,
  ]);

  useEffect(() => {
    if (membersMutation.isSuccess) {
      onReset();
      toast.success("Player added to the Team");
    }

    if (membersMutation.isError) {
      toast.error("Failed to add Player to the team. Try again later");
    }
  }, [membersMutation.isSuccess, membersMutation.isError]);

  const onChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const id = event.target.value.replace("-", "");
    setTagId(event.target.value);

    if (id.length === 14) {
      setSearchState(SearchStates.pending);
    } else {
      setSearchState(SearchStates.input);
    }
  };

  const onSearch = () => {
    setQueryEnabled(true);
  };

  const onReset = () => {
    setTagId("");
    setSearchState(SearchStates.input);
  };

  const addPlayerToTheTeam = async () => {
    await membersMutation.mutateAsync({ playerId, teamId });
    onAdded();
  };

  return (
    <React.Fragment>
      <Grid container direction="row" alignItems="flex-start" spacing={2}>
        <Grid item>
          <Input
            placeholder="Add by tag id"
            type="text"
            onChange={onChange}
            value={tagId}
            endAdornment={
              <InputAdornment position="end">
                {
                  {
                    [SearchStates.input]: (
                      <Tooltip
                        title={
                          <h6 style={{ fontSize: 16 }}>
                            Input the full Tag ID to be able to search
                          </h6>
                        }
                      >
                        <IconButton onClick={onSearch} edge="end">
                          <HelpOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    ),
                    [SearchStates.pending]: (
                      <IconButton onClick={onSearch} edge="end">
                        <SearchIcon />
                      </IconButton>
                    ),
                    [SearchStates.inprogress]: (
                      <IconButton onClick={onReset} edge="end">
                        <CircularProgress size={20} />
                      </IconButton>
                    ),
                    [SearchStates.success]: <DoneIcon />,
                    [SearchStates.failed]: (
                      <IconButton onClick={onReset} edge="end">
                        <ClearIcon />
                      </IconButton>
                    ),
                  }[searchState]
                }
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item>
          <Button
            style={{ width: 95, height: 50 }}
            variant="contained"
            disabled={
              searchState !== SearchStates.success || membersMutation.isLoading
            }
            onClick={addPlayerToTheTeam}
            color="primary"
          >
            {membersMutation.isLoading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              <Trans context="new item">Add</Trans>
            )}
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
