export const isIOS = () =>
  navigator.userAgent.match(/iPhone|iPad|iPod/i) != null;
export const isAndroid = () => navigator.userAgent.match(/Android/i) != null;

export const redirectToAuth = (history) => {
  if (isIOS()) {
    // empty
  } else if (isAndroid()) {
    // empty
  } else {
    const { pathname, search } = window.location;

    history.push(`/login?redirect=${pathname}${search}`);
  }
};
