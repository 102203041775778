import { Box, Grid } from "@mui/material";
import { BottomFAQText } from "components/landing/typography.component";
import { DownloadLink } from "components/landing/link.component";
import iconDownload from "images/landing/icon-download.png";
import { SmallButton } from "components/landing/buttons.component";
import useMediaQuery from "@mui/material/useMediaQuery";
import { landingTheme } from "landing-theme";
import { Trans } from "@lingui/macro";
import { font } from "theme";
import {
  LEFT_MARGIN,
  STARTER_GUIDE,
  USER_MANUAL,
  useStyles,
  FAQ_URL,
  ORDER_NOW,
} from "../constants";
import { useAnalytics } from "use-analytics";

export default function DownloadSection() {
  const analytics = useAnalytics();
  const bigSize = useMediaQuery("(min-width:720px)");
  const classes = useStyles(landingTheme);

  return (
    <Grid item id="downloadSection">
      <Grid container>
        <Box
          paddingLeft={bigSize ? LEFT_MARGIN : 1}
          paddingRight={bigSize ? LEFT_MARGIN : 1}
          paddingTop={4}
          width="100%"
        >
          <Grid
            container
            direction={bigSize ? "row" : "column"}
            justifyContent="space-between"
          >
            <Grid item>
              <BottomFAQText
                style={{
                  maxWidth: "150px",
                  margin: bigSize ? "0" : "auto",
                  textAlign: bigSize ? "left" : "center",
                  paddingBottom: "4px",
                  borderBottom: "1px solid #333333",
                }}
              >
                <Trans>Downloads</Trans>
              </BottomFAQText>
              <Grid
                container
                sx={{ paddingTop: 3 }}
                direction={bigSize ? "row" : "column"}
                spacing={1}
              >
                <Grid
                  item
                  sx={{
                    marginTop: "12px",
                    justifyContent: "center",
                    display: "flex",
                    marginRight: bigSize ? "24px" : 0,
                  }}
                >
                  <DownloadLink href={STARTER_GUIDE} target="_blank">
                    <Trans>teamfx starter guide</Trans>{" "}
                    <img alt="teamfx starter guide" src={iconDownload} />
                  </DownloadLink>
                </Grid>
                <Grid
                  item
                  sx={{
                    marginTop: "12px",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <DownloadLink href={USER_MANUAL} target="_blank">
                    <Trans>teamfx User Manual</Trans>{" "}
                    <img alt="teamfx User Manual" src={iconDownload} />
                  </DownloadLink>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={{ display: "flex" }}>
              {bigSize && (
                <Grid container spacing={1}>
                  <Grid item style={{ marginRight: "3rem" }}>
                    <SmallButton
                      sx={{
                        width: "100%",
                        padding: "8px 12px",
                        fontWeight: "700",
                        fontFamily: font.adineue.cond,
                        letterSpacing: "5.50px",
                        color: "black",
                        "&:hover": {
                          background: "#B8FED8",
                        },
                        "& .MuiTouchRipple-child": {
                          background: "rgba(81, 246, 156.90, 1)",
                        },
                      }}
                      variant="contained"
                      onClick={() => {
                        analytics.track("landing-order-now-button-clicked");
                        window.open(ORDER_NOW, "_blank");
                      }}
                    >
                      <Trans>Order Now</Trans>
                    </SmallButton>
                  </Grid>
                </Grid>
              )}
              <Grid item sx={!bigSize ? { width: "100%" } : {}}>
                <SmallButton
                  className={classes.loginButton}
                  style={
                    bigSize
                      ? {
                          color: "#51F69D",
                          padding: "8px 12px",
                          fontFamily: font.adineue.cond,
                          letterSpacing: "4.50px",
                        }
                      : {
                          width: "90%",
                          margin: "3rem auto",
                          display: "block",
                          padding: "8px 12px",
                          fontFamily: font.adineue.cond,
                          letterSpacing: "4.50px",
                          color: "#51F69D",
                        }
                  }
                  variant="outlined"
                  onClick={() => {
                    analytics.track("landing-faq-button-clicked");
                    window.open(FAQ_URL, "_blank");
                  }}
                >
                  <Trans>Go to FAQ</Trans>
                </SmallButton>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
