import { Button, ButtonProps } from "@mui/material";
import image from "../../images/TeamFX_Logo_Big.png";

interface ILogoIconProps extends ButtonProps {
  height?: number;
}

function AdidasLogoIcon(props: ILogoIconProps) {
  return (
    <Button style={{ padding: 5 }} {...props}>
      <img src={image} alt="Adidas Logo" />
    </Button>
  );
}

export default AdidasLogoIcon;
