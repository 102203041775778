import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { SingleStatProps } from "../utils/utils";
import { font } from "theme";

ChartJS.register(ArcElement, Tooltip, Legend);

const labels = ["COLD", "MEDIUM", "HOT", "FIRE"];

export const PlayerStatPie = (props: SingleStatProps) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom" as const,
        labels: {
          color: "rgba(255, 255, 255, 1)",
          usePointStyle: true,
        },
      },
      title: {
        display: true,
        text: props.statName || "Two Stats comparision",
        color: "rgba(255, 255, 255, 1)",
        font: { size: 16, family: font.adihaus.regular },
      },
    },
  };

  const data = {
    labels: props.labels || labels,
    datasets: [
      {
        label: props.statName || "Kick Zones",
        data: props.items || [],
        backgroundColor: props.chartEntryColors || [
          "rgba(0, 0, 255, 1)",
          "rgba(0, 255, 0, 1)",
          "rgba(255, 255, 0, 1)",
          "rgba(255, 0, 255, 1)",
        ],
        borderWidth: 0,
      },
    ],
  };
  return <Pie options={options} data={data} />;
};
