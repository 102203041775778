import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import ArrowRightAlt from "@mui/icons-material/East";
import ArrowLeftAlt from "@mui/icons-material/West";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { Trans } from "@lingui/macro";
import { getFeatures } from "../constants";
import {
  FeatureGridItem,
  MobileFeatureGridItem,
} from "components/landing/features.component";
import "./FeaturesCarousel.style.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import { LargeButton } from "components/landing/buttons.component";

function ButtonDesktop({
  clickNext,
  clickPrev,
  disabled,
}: {
  disabled: boolean;
  clickNext?(): void;
  clickPrev?(): void;
}) {
  return (
    <LargeButton
      disabled={disabled}
      variant="outlined"
      style={{
        color: disabled ? "rgba(255, 255, 255, 0.12)" : "#51F69D",
        padding: "1rem",
        position: "absolute",
        top: "50%",
        left: clickPrev ? 0 : "unset",
        right: clickNext ? 0 : "unset",
      }}
      onClick={() => {
        if (clickNext) {
          clickNext();

          return;
        }
        if (clickPrev) clickPrev();
      }}
    >
      {clickNext ? (
        <ArrowRightAlt width={"40px"} height={"30px"} />
      ) : (
        <ArrowLeftAlt width={"40px"} height={"30px"} />
      )}
    </LargeButton>
  );
}

export default function FeaturesCarousel() {
  const theme = useTheme();
  const bigSize = useMediaQuery("(min-width:720px)");
  const [activeStep, setActiveStep] = useState(0);
  const features = getFeatures();
  const maxSteps = features.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        margin: "auto",
        position: "relative",
      }}
    >
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        slideStyle={{ overflow: "hidden" }}
      >
        {features.map((feat) =>
          bigSize ? (
            <FeatureGridItem
              key={feat.key}
              title={feat.title}
              desc={feat.desc}
              img={feat.img}
              val={feat.val}
              mobileImg={feat.mobileImg}
            />
          ) : (
            <MobileFeatureGridItem
              key={feat.key}
              title={feat.title}
              desc={feat.desc}
              img={feat.img}
              val={feat.val}
              mobileImg={feat.mobileImg}
            />
          ),
        )}
      </SwipeableViews>
      <MobileStepper
        className={"featuresCarousel-stepper"}
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          bigSize ? (
            <ButtonDesktop
              clickNext={handleNext}
              disabled={activeStep === maxSteps - 1}
            />
          ) : (
            <Button
              sx={{ color: "#51F69D" }}
              size="medium"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              <Trans>Next</Trans>
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          )
        }
        backButton={
          bigSize ? (
            <ButtonDesktop clickPrev={handleBack} disabled={activeStep === 0} />
          ) : (
            <Button
              size="medium"
              sx={{ color: "#51F69D" }}
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              <Trans>Back</Trans>
            </Button>
          )
        }
      />
    </Box>
  );
}
