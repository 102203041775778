import { ReactElement, useRef, useState } from "react";
import {
  Box,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
  Stack,
  Icon,
} from "@mui/material";
import { Trans } from "@lingui/macro";
import Theme from "theme";
import { dynamicActivate } from "utils/i18n";

export type CountryData = {
  flag: string;
  language: ReactElement;
};

export const countryList: Record<string, CountryData> = {
  en: {
    flag: "🇬🇧",
    language: <Trans id="english_language_text">English</Trans>,
  },
  fr: {
    flag: "🇫🇷",
    language: <Trans id="french_language_text">French</Trans>,
  },
  de: {
    flag: "🇩🇪",
    language: <Trans id="german_language_text">German</Trans>,
  },
  es: {
    flag: "🇪🇸",
    language: <Trans id="spanish_language_text">Spanish</Trans>,
  },
  it: {
    flag: "🇮🇹",
    language: <Trans id="italian_language_text">Italian</Trans>,
  },
  nl: {
    flag: "🇳🇱",
    language: <Trans id="dutch_language_text">Dutch</Trans>,
  },
};

export type CountrySelectorProps = {
  locale: string;
  setSelectedLocale?: (locale: string) => void;
};

export const CountrySelector = ({
  locale,
  setSelectedLocale,
}: CountrySelectorProps) => {
  const [countrySelectorOpen, setCountrySelectorOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const onLanguageSelect = (locale: string) => {
    dynamicActivate(locale, true);
  };

  return (
    <Box>
      <IconButton
        ref={anchorRef}
        sx={{ ml: "12px", fontSize: 24 }}
        onClick={() => setCountrySelectorOpen(true)}
      >
        {countryList[locale]?.flag}
      </IconButton>
      <Popper
        open={countrySelectorOpen}
        role={undefined}
        anchorEl={anchorRef.current}
        transition
        placement="bottom-end"
        sx={{
          zIndex: 1100,
          pt: "5px",
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper style={{ backgroundColor: Theme.background.dialog }}>
              <ClickAwayListener
                onClickAway={() => setCountrySelectorOpen(false)}
              >
                <MenuList
                  autoFocusItem={countrySelectorOpen}
                  id="menu-list-grow"
                  sx={{
                    backgroundColor: Theme.background.header,
                    maxWidth: "360px",
                    maxHeight: "665px",
                    overflow: "overlay",
                  }}
                >
                  {Object.keys(countryList).map((country) => (
                    <MenuItem
                      key={`locale-${country}`}
                      onClick={() => {
                        if (setSelectedLocale) {
                          setSelectedLocale(country);
                        }
                        setCountrySelectorOpen(false);
                        onLanguageSelect(country);
                      }}
                      sx={{
                        p: "12px 16px",
                        borderBottom: "1px solid #333333",
                      }}
                    >
                      <Stack direction="row">
                        <Icon sx={{ mr: "12px", fontSize: 20 }}>
                          {countryList[country].flag}
                        </Icon>
                        <Stack>
                          <Typography
                            variant="body2"
                            fontSize="14px"
                            whiteSpace="initial"
                            justifyContent="center"
                          >
                            {countryList[country].language}
                          </Typography>
                        </Stack>
                      </Stack>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};
